import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { FormEvent, RefCallback } from "react";

import * as Api from '../link/ServerApi';
import * as ApiTypes from '../link/ApiTypes';

const NO_LOGIN_MESSAGE = 'Utilisateur/mot de passe inconnu';

type Props = {
    onLoggedIn: RefCallback<ApiTypes.User>
};

function LoginForm({ onLoggedIn } : Props) {
    const [userName, setUserName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [working, setWorking] = React.useState(false);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        setWorking(true);

        Api.login(userName, password)
        .then(appResp => {
            setPassword('');
            if (!appResp.ok) {
                if (appResp.httpStatus === 200) {
                    window.alert(NO_LOGIN_MESSAGE);
                } else {
                    window.alert(appResp.errorMessage);
                }
                return;
            }
            if (!appResp.value?.user) {
                window.alert(NO_LOGIN_MESSAGE);
                return;
            }
            setUserName('');

            if (onLoggedIn) {
                onLoggedIn(appResp.value.user);
            }
        })
        .catch(reason => {
            console.error(reason);
            window.alert(reason);
        })
        .finally(() => setWorking(false));
    };

    return (
        <Dialog open={true} className='LoginForm-dialog'>
            <form onSubmit={handleSubmit} className="LoginForm">
                <DialogTitle textAlign='center'>Connexion</DialogTitle>
                <DialogContent>
                    <DialogContentText mt={3} fontWeight='bold' textAlign='center'>
                        Entrez vos informations d'indentification
                    </DialogContentText>
                    <TextField
                        fullWidth
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Nom d'utilisateur&nbsp;:"
                        variant="standard"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        required={true}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        id="password"
                        label="Mot de passe&nbsp;:"
                        variant="standard"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required={true}

                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="secondary" variant="contained" disabled={working}>Connexion</Button>
                </DialogActions>
            </form>
        </Dialog>
      );

}

export default LoginForm;