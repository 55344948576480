import { Outlet } from "react-router-dom";

type Props = { };

const StatisticsPageLayout = (props: Props) => {
  return (
    <>
    <Outlet />
    </>
  );
};

export default StatisticsPageLayout;