import { Typography } from '@mui/material';

type Props = { };

const HomePage = (props : Props) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>Bienvenue sur le back-office du Configurateur de maison 3D</Typography>
      <Typography variant="body1" >Vous pouvez naviguer dans le menu de gauche pour accéder aux différentes fonctionnalités.</Typography>
    </>
  );
};

export default HomePage;