import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, BoxProps, Button, ButtonProps, IconButton, LinearProgress, List, ListItem, ListItemButton, ListProps, TextField, TextFieldProps, Typography, styled } from '@mui/material';
import { SnackbarProvider, SnackbarProviderProps, enqueueSnackbar } from 'notistack';
import { theme } from '../../../configs/themeOptions';
import { StoredFile } from '../../../link/ApiTypes';
import { ClientImagePreview, FilePreview, ImagePreview } from '../Files';
import { DateFormat } from '../Localization';

/**
 * A div rendered a horizontal flex with predefined gap
 * @param props 
 * @returns 
 */
export function FlexRow(props: BoxProps) {
    props = {
        className:'flex-row',
        mt:2,
        ...props,
        sx: Object.assign({ display:'flex', gap: 2, flexWrap: 'wrap' }, props.sx) };
    return (
        <Box {...props }>{props.children}</Box>
    );
}

/**
 * The version of {@link TextField} we use in most forms, with fixed variant
 * @param props 
 * @returns 
 */
export const FormTextField: React.FunctionComponent<TextFieldProps & {maxLength?: number}> = (props) => { //Omit<...> copied from TextField definition
    let mProps = {...props};
    mProps.children = undefined;
    if (props.maxLength) {
      mProps.inputProps = Object.assign({maxLength: props.maxLength}, props.inputProps);
    }
    mProps = Object.assign({ variant: 'standard' }, mProps); //This is to force a property
    return (
        <TextField {...mProps}>{props.children}</TextField>
    );
};

/**
 * {@link SnackbarProvider} styled for most forms
 * @param props 
 * @returns 
 */
export function FormSnackbarProvider(props: SnackbarProviderProps) {
    props = Object.assign( { anchorOrigin: { vertical: 'bottom', horizontal:'right' }, autoHideDuration: 3000 }, props);
    return (
        <SnackbarProvider {...props} 
        />
    );
}

export function enqueueSnacks(success?: string, warnings?: string[], error?: string) {
  if (success) {
    enqueueSnackbar(success, { variant: 'success' });
  }
  for (const warning of (warnings || [])) {
    enqueueSnackbar(warning, { variant: 'warning' });
  }
  if (error) {
    enqueueSnackbar(error, { variant: 'error' });
  }
}

export const UploadButton : React.FunctionComponent<React.InputHTMLAttributes<HTMLInputElement> & Omit<ButtonProps, 'onChange'>> = (props) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  //Adjust props for button
  const bProps = { ...props,
    component : props.component || "label",
    name: undefined,
    onChange: undefined
  }

  return (
    <Button {...bProps}>
      {props.children || 'Envoyer fichier'}
      <VisuallyHiddenInput  type="file" name={props.name} accept={props.accept} multiple={props.multiple}
                            onChange={props.onChange} />
    </Button>
  );
}


export type StoredFileWithState = StoredFile & {
  unsaved?: boolean
};

export type UploadingFile = {
  filename: string,
  file: File,
  uploadError?: string,
  mimeType?: string,
};

type UploadedFileProps = {
  value: StoredFile|StoredFileWithState|UploadingFile|undefined,
  onDeleteFile?: (file: StoredFile) => void,
  disabled?: boolean
}

export function UploadedFile(props: UploadedFileProps) : React.JSX.Element {
  if (!props.value)
    return <Box flexGrow={1}><Typography fontStyle='italic' color={theme.palette.text.disabled}>(Aucun fichier)</Typography></Box>;

  const uploadingFile = (props.value && props.value.hasOwnProperty('file')) ? props.value as UploadingFile : undefined;
  const storedFile = (props.value && props.value.hasOwnProperty('id')) ? props.value as StoredFileWithState : undefined;
  const isImage = ((uploadingFile || storedFile)?.mimeType)?.startsWith('image/');
  const mimeType = (uploadingFile || storedFile)?.mimeType;
  
  return (
    <Box flexGrow={1} display="flex" gap={1} sx={{ ':hover, :focus-within': { backgroundColor: theme.palette.grey[100] } }}>
      {
        (isImage) 
          ? (uploadingFile) 
            ? <ClientImagePreview file={uploadingFile.file} />
            : <ImagePreview fileName={storedFile?.filename} id={storedFile?.id} />
          : <FilePreview mimetype={mimeType} filename={uploadingFile?.filename || storedFile?.filename} /> 
      }
      <Box flexGrow={1} display='flex' flexDirection='column'>
        <Typography fontWeight='bold'>{props.value.filename}</Typography>
        <Box display='flex' gap={1} flexGrow={1}>
          <Box flexGrow={1} fontSize='.8rem' display='flex' alignItems='center'>
            { uploadingFile
              ? (
                uploadingFile.uploadError
                ? <Typography fontStyle='italic' color='error'>{uploadingFile.uploadError}</Typography>
                : <LinearProgress sx={{minWidth: '10em', flexGrow: 1}} />
              )
              : (
                storedFile?.unsaved
                ? <Typography fontStyle='italic' color={theme.palette.text.disabled}>* Enregistrement en attente</Typography>
                : <DateFormat formatOptions={{dateStyle: 'short', timeStyle: 'medium'}}>{storedFile?.created}</DateFormat>
              )
            }
          </Box>
        </Box>
      </Box>
      {
        (storedFile) &&
        <IconButton color='secondary' size='small' sx={{alignSelf: 'flex-start', m: .5}}
                    onClick={_e => {if (props.onDeleteFile) props.onDeleteFile(props.value as StoredFileWithState);}}
                    disabled={props.disabled}>
          <DeleteForeverIcon />
        </IconButton>
      }
    </Box>
  );
}

type SelectListProps<T> = ListProps & {
  items: T[];
  value?: T;
  onSelectChange?: (value: T) => void;
  itemdisplay?: (item: T) => string | React.JSX.Element;
  valueequals?: (choice: T) => boolean;
  disabled?: boolean;
};

export function SelectList<T>(props: SelectListProps<T>) : React.JSX.Element {
  let itemDisplay = props.itemdisplay;
  if (!itemDisplay) {
    itemDisplay = (item: T) => item || item === false || item === 0 ? '' + item : '';
  }

  let valueEquals = props.valueequals;
  if (!valueEquals) {
    valueEquals = (choice: T) => choice === props.value;
  }

  return (
    <List {...props}>
      {
        props.items.map((item, index) => (
          <ListItem key={index} sx={{p:0, m:0}}>
            <ListItemButton selected={valueEquals && valueEquals(item)} 
                            onClick={_e => props.onSelectChange && props.onSelectChange(item)}
                            disabled={props.disabled}
                            sx={{padding:'0 0 0 1rem', m:0}}>
              { itemDisplay && itemDisplay(item) }
            </ListItemButton>
          </ListItem>
        ))
      }
    </List>
  );
  
}