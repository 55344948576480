import moment from "moment";
import * as Api from "../util/ApiCall";
import { AppServerResponse, Client, ClientCustomization, ContactFormParams, DeleteManyResult, SimpleModel, StoredFile, User } from './ApiTypes';
import { readServerResponse } from './ServerApi';


export async function getAllClients(): Promise<AppServerResponse<Client[]>> {
    const resp = await Api.get("/api/client/all");
    return readServerResponse<Client[]>("/api/client/all", resp, clientValuesConverter);
}

export async function getClient(clientId: any): Promise<AppServerResponse<Client>> {
    const cid = !clientId ? NaN : parseInt(clientId);
    if (Number.isNaN(cid))
        throw new Error(`Could not parse client id from "${clientId}"`);
    const resp = await Api.get("/api/client/id/" + cid);
    return readServerResponse<Client>("/api/client/id/" + cid, resp, clientValueConverter);
}

export async function saveClient(client: Client): Promise<AppServerResponse<Client>> {
    const resp = await Api.post("/api/client/save/", client);
    return readServerResponse<Client>("/api/client/save/", resp, clientValueConverter);
}

export async function deleteClient(clientId: number): Promise<AppServerResponse<boolean>> {
    const resp = await Api.del("/api/client/id/" + clientId);
    return readServerResponse<boolean>("/api/client/id/" + clientId, resp);
}

export async function deleteClients(clientIds: number[]): Promise<AppServerResponse<DeleteManyResult>> {
    const resp = await Api.del("/api/client", clientIds);
    return readServerResponse<DeleteManyResult>("/api/client", resp);
}

export async function getClientUsers(clientId: number): Promise<AppServerResponse<User[]>> {
    const resp = await Api.get("/api/user/client/" + clientId);
    return readServerResponse<User[]>("/api/user/client/" + clientId, resp);
}

export async function getClientUsersCount(clientId: number): Promise<AppServerResponse<number>> {
    const resp = await Api.get("/api/user/client/count/" + clientId);
    return readServerResponse<number>("/api/user/client/count/" + clientId, resp);
}

export async function getClientCustomization(clientId: number): Promise<AppServerResponse<ClientCustomization>> {
    const resp = await Api.get("/api/client/customization/" + clientId);
    return readServerResponse<ClientCustomization>("/api/client/customization/" + clientId, resp);
}

export async function saveClientCustomization(client: Client, customization: ClientCustomization, uploadedLogo: StoredFile | undefined,
                                              uploadedOverlayLogo: StoredFile | undefined, uploadedFavicon: StoredFile | undefined,
                                              uploadedFiles: StoredFile[]): Promise<AppServerResponse<ClientCustomization>> {
    const resp = await Api.post("/api/client/customization/save/", { client: client, customization: { ...customization, logo: uploadedLogo, overlaylogo: uploadedOverlayLogo, favicon: uploadedFavicon, files: uploadedFiles } });
    return readServerResponse<ClientCustomization>("/api/client/customization/save/", resp);
}

export async function getClientIconLogo(clientId: number): Promise<AppServerResponse<File>> {
    const resp = await Api.get("/api/client/iconlogo/" + clientId);
    return readServerResponse<File>("/api/client/iconlogo" + clientId, resp);
}

export async function getContactFormParams(clientId: number): Promise<AppServerResponse<ContactFormParams>> {
    const resp = await Api.get("/api/client/contactformparams/" + clientId);
    return readServerResponse<ContactFormParams>("/api/client/contactformparams/" + clientId, resp);
}

export async function saveContactFormParams(client: Client, contactFormParams: ContactFormParams): Promise<AppServerResponse<ContactFormParams>> {
    const resp = await Api.post("/api/client/contactformparams/save/", { client, contactFormParams });
    return readServerResponse<ClientCustomization>("/api/client/contactformparams/save", resp);
}

export async function getClientModels(clientId: number): Promise<AppServerResponse<SimpleModel[]>> {
    const resp = await Api.get("/api/client/models/" + clientId);
    return readServerResponse<SimpleModel[]>("/api/client/models/" + clientId, resp);
}









export function clientValueConverter(value: any) {
    if (!value)
        return value;
    if (value.apiKeyExpires)
        value.apiKeyExpires = moment(value.apiKeyExpires);
    return value as Client;
}

export function clientValuesConverter(values: any[]) {
    for (const value of values) {
        if (value.apiKeyExpires)
            value.apiKeyExpires = moment(value.apiKeyExpires);
    }
    return values as Client[];
}