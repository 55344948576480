import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Sidebar from "../common/menu/Sidebar";
import Topbar from "./Topbar";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import * as appInfo from '../../configs/appInfo';
import { User } from '../../link/ApiTypes';

type Props = { user: User }

export default function MainLayout({ user }:  Props) {
  //States
  const { menuState } = useSelector((state: RootState) => state.menuPage);

  //Compute page/browser window title
  const title = menuState && menuState.pageProps
                          && menuState.pageProps.title 
                  ? menuState.pageProps.title 
                  : "";

  document.title = `${title} - ${appInfo.shortName}`;

  //Layout
  return (
    <Box sx={{ display: "flex" }}>
      <Topbar title={title} user={user} />
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p:0 }}
      >
        <Toolbar />
        <Box sx={{p: 2}}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}