import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import LoadingScreen from './components/LoadingScreen';
import LoginForm from './components/LoginForm';
import AppLayout from './components/layout/AppLayout';
import * as ApiTypes from './link/ApiTypes';
import * as ApiLink from './link/ServerApi';
import { LocaleState } from './redux/features/localeSlice';
import { LoginState, setConnecting, setUser } from './redux/features/loginSlice';
import * as Api from './util/ApiCall';
import "./configs/aceWorkers";

export default function App() {
  //Imperative functions
  const dispatch = useDispatch();

  //States
  const { connecting, user } = useSelector((state: {login: LoginState}) => state.login);
  const { locale } = useSelector((state: {locale: LocaleState}) => state.locale);

  //Check authentication on page load
  React.useEffect(() => {
    ApiLink.checkLoggedOnUser()
    .then(appResp => {
      if (!appResp.ok) {
        console.error(appResp.errorMessage);
        window.alert(appResp.errorMessage);
        return;
      }
      if (!appResp.value) {
        console.error("No user in server response to checkLoggedOnUser");
        window.alert("Réponse invalide du serveur");
        return;
      }
      dispatch(setConnecting(false));
      dispatch(setUser(appResp.value));
    })
    .catch(reason => {
      if (reason instanceof Api.ServerError) {
        dispatch(setConnecting(false));
        dispatch(setUser(null));
      } else {
        console.error(reason);
        window.alert(reason);
      }
    });
  }, [dispatch]);

  //Global application callback for session expired
  Api.setUnauthenticatedCallback(ue => {
    dispatch(setUser(null));
  });

  //Layout
  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterMoment}>
      <div className="App">
        { (connecting || !user) && <LoadingScreen key='loading-screen' connecting={connecting} /> }
        { (!connecting && !user) && <LoginForm key='login-form' onLoggedIn={handleLoggedIn} /> }
        { (!connecting && user) && <AppLayout key='app-layout' user={user} /> }
      </div>
    </LocalizationProvider>
  );



  //Action handlers

  function handleLoggedIn(user: ApiTypes.User) {
    dispatch(setConnecting(false));
    dispatch(setUser(user));
  }
}
