import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ClientLogoAndName from '../../../components/common/ClientLogoAndName';
import CodeEditor, { CodeEditorHandle } from '../../../components/common/CodeEditor';
import { FlexRow, FormSnackbarProvider } from '../../../components/common/forms/FormComponents';
import * as ApiTypes from '../../../link/ApiTypes';
import * as Api from '../../../link/ModelServerApi';
import { setGlobalProgress } from '../../../redux/features/progressSlice';

type Props = { };

type FormState = {
  blocked: boolean,
  model?: ApiTypes.DetailedSimpleModel,
  loadError?: string,
  errorMessage?: string,
  warnings?: string[],
  alertText?: string,
};

const ModelConfiguratorPage = (props: Props) => {

  //URL Parameters
  const { modelId } = useParams();
  let nModelId = parseInt(modelId || '');

  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false});
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);
  const [ expanded, setExpanded ] = React.useState<string | false>(false);

  //Refs
  const envConfigEditorRef = React.useRef<CodeEditorHandle>(null);
  const optionsConfigEditorRef = React.useRef<CodeEditorHandle>(null);
  const otherFeatureEditorRef = React.useRef<CodeEditorHandle>(null);

  //Load
  React.useEffect(() => {
    if (!dataLoaded) {
      if (isNaN(nModelId)) {
        setDataLoaded(true);
        return;
      }
      startOperation(false);
      Api.getModel(nModelId)
        .then((appResp) => {
          if (!appResp.ok) {
            endOperation({ loadError: "Erreur au chargement\u00a0:" + appResp.errorMessage });
            return;
          }
          if (!appResp.value) {
            endOperation({ loadError: "Erreur au chargement\u00a0: Réponse vide du serveur" });
            return;
          }
          endOperation({ model: appResp.value });
        })
        .catch(err => endOperation({ loading: false,  loadError: "Erreur au chargement\u00a0:" + err }))
        .finally(() => { 
          setDataLoaded(true);
        });
    }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  //Layout
  return (
    <Box component='form' onSubmit={save} onKeyDown={kbHotkeys}>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h5'>Options de configateur pour le modèle {formState.model && formState.model.name}</Typography>
        { formState.model?.client && <FlexRow sx={{gap:0}} m={0}><ClientLogoAndName client={formState.model.client} /></FlexRow> }
      </Box>

        { formState.loadError && <Alert severity='error' sx={{my: 1}}>{formState.loadError}</Alert> }
        { formState.errorMessage && <Alert severity='error' sx={{my: 1}}>{formState.errorMessage}</Alert> }
        { formState.warnings && formState.warnings.map(warnMessage => <Alert severity='warning'>{warnMessage}</Alert>) }
      
      <Box mt={2}>
        <LocalEditorAccordion index={1} name='envConfig' label='Paramètres d&quot;environnement 3D' 
                              codeMode='javascript' value={formState.model?.envConfig} editorRef={envConfigEditorRef}/>
        <LocalEditorAccordion index={1} name='optionsConfig' label='Options de configurateur' 
                              codeMode='javascript' value={formState.model?.optionsConfig} editorRef={optionsConfigEditorRef}/>
        <LocalEditorAccordion index={1} name='otherFeatures' label='Autres fonctions' 
                              codeMode='javascript' value={formState.model?.otherFeatures} editorRef={otherFeatureEditorRef} />
      </Box>

      <FlexRow>
        <Box flexGrow={1} />
        <Button component={RouterLink} to='..' variant='outlined'>Retour</Button>
        <Button type="submit" variant='contained' color='secondary'
                disabled={formState.blocked || formState.loadError ? true : false}
                startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </FlexRow>
      
      <LocalAlertDialog />

      <FormSnackbarProvider />

    </Box>
  );


  //Keyboard hotkeys
  function kbHotkeys(e: React.KeyboardEvent) {
    if (e.ctrlKey) {
      switch (e.key) {
        case 's':
          e.preventDefault();
          e.stopPropagation();
          if (!formState.blocked && !formState.loadError) {
            save(e);
          }
          return false;
        }
    }
    else {
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          e.stopPropagation();
          navigate('..');
          return false;
      }
    }
  }


  //Field change handlers



  //Action handlers
  async function save(e: React.FormEvent) {

    e.preventDefault();

    if (!formState.model) {
      enqueueSnackbar("Erreur à l'enregistrement\u00a0: Données manquantes", { variant: "error" });
      return;
    }

    startOperation();

    // Code validation
    const errors = [];

    const ecEditor = envConfigEditorRef?.current;
    const ecError = await ecEditor?.checkCode();
    if (ecError) {
      errors.push(ecError);
    }
    const ocEditor = optionsConfigEditorRef?.current;
    const ocError = await ocEditor?.checkCode();
    if (ocError) {
      errors.push(ocError);
    }
    const ofEditor = otherFeatureEditorRef?.current;
    const ofError = await ofEditor?.checkCode();
    if (ofError) {
      errors.push(ofError);
    }

    if (errors.length) {
      endOperation({ ...formState, alertText: errors.join('\n') });
      return;
    }

    Api.saveModelOptions(formState.model)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({
            ...formState,
            errorMessage: appResp.errorMessage ? "Erreur à l'enregistrement\u00a0:" + appResp.errorMessage : undefined,
            warnings: appResp.warnings
           });
          return;
        }
        const savedModel = appResp.value;
        endOperation({ model: savedModel });
        enqueueSnackbar('Configuration enregistrée', { variant: "success" });
      })
      .catch(err => endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0:" + err }));
  }




  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, loadError: undefined, errorMessage: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, blocked: false });
  }



  //Local controls

  type Props = {
    index: number;
    name: string;
    label: string;
    codeMode: string;
    value?: string;
    editorRef?: React.Ref<CodeEditorHandle>;
  };

  function LocalEditorAccordion({index, name, label, codeMode, value, editorRef} : Props) {

    return (
      <Accordion expanded={expanded === name} onChange={handleExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
          id={`panel${index}bh-header`}>
          <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <CodeEditor name={name} codeMode={codeMode} value={value} ref={editorRef} onChange={handleChange} codeValidationPrefix='const a = '/>
          </Box>
        </AccordionDetails>
      </Accordion>
    );

    function handleExpand(event: React.SyntheticEvent<Element, Event>, expanded: boolean) {
      setExpanded(expanded ? name : false);
    }
  
    function handleChange(value: string, _event: any) {
      const m = {...formState.model || ApiTypes.emptyModel() };
      (m as any)[name] = value;
      formState.model = m; //Intentionally mutating formState to avoid focus bug
    }
  }

  function LocalAlertDialog(props: { }) : React.ReactElement | null {
    if (formState.alertText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Sauvegarde des options de configuration du modèle"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2} whiteSpace='pre-wrap'>
              {formState.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

}

export default ModelConfiguratorPage;