import CodeIcon from '@mui/icons-material/Code';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Box, SxProps, Theme } from "@mui/material";
import { theme } from "../../configs/themeOptions";

type ImagePreviewProps = {
    fileName?: string,
    id?: number,
    sx?: SxProps<Theme>
}

type ClientImagePreviewProps = {
    file: File,
    sx?: SxProps<Theme>
}

type FilePreviewProps = {
    mimetype?: string,
    filename?: string,
}

export const ImagePreview: React.FunctionComponent<ImagePreviewProps> = (props) => {
    const src = props.fileName && props.id ? `/pub/file/${props.id}/${props.fileName}` : '';
    return (
        <LocalImagePreview src={src} sx={props.sx} />
    );
};

export const ClientImagePreview: React.FunctionComponent<ClientImagePreviewProps> = (props) => {
    const src = URL.createObjectURL(props.file);
    return (
        <LocalImagePreview src={src} sx={props.sx} />
    );
};

export const FilePreview: React.FunctionComponent<FilePreviewProps> = (props, filename) => {

  const renderPreview = () : React.ReactNode => {
    if (props.mimetype?.startsWith('model/') || props.filename?.split('.').pop() === ('glb' || 'gltf'))
      return <ViewInArIcon />;
    if (props.mimetype?.startsWith('font/') || props.filename?.split('.').pop() === ('ttf' || 'otf' || 'woff' || 'woff2' || 'eot'))
      return <FontDownloadIcon />;
    if (props.mimetype?.startsWith('text/' || 'application/json' || 'application/x-javascript' ) || props.filename?.split('.').pop() === ('js' || 'html' || 'css' ))
      return <CodeIcon />;
    return <FilePresentIcon />;
  };

  return (
    <Box width='4rem' minWidth='4rem' minHeight='3rem' display='flex' alignItems='center' justifyContent='center' border='1px solid gray' borderColor={theme.palette.primary.contrastText}>
      {renderPreview()}
    </Box>
  );
};

function LocalImagePreview({src, sx}: {src: string, sx?: SxProps<Theme>}) {
    return (
        <Box width='4rem' minWidth='4rem' minHeight='3rem' display='flex' alignItems='center' justifyContent='center' border='1px solid gray' borderColor={theme.palette.primary.contrastText}>
            <Box maxWidth='100%' maxHeight='100%' component='img' src={src} sx={sx} />
        </Box>
    );
}