import { configureStore } from "@reduxjs/toolkit";
import menuPageSlice from "./features/menuPageSlice";
import loginSlice from "./features/loginSlice";
import usersPageSlice from "./features/usersPageSlice";
import progressSlice from "./features/progressSlice";
import localeSlice from "./features/localeSlice";

export const store = configureStore({
  reducer: {
    locale: localeSlice,
    menuPage: menuPageSlice,
    login: loginSlice,
    usersPage: usersPageSlice,
    globalProgress: progressSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;