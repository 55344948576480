import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { AppBar, Avatar, Divider, IconButton, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import { MouseEvent, UIEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import sizeConfigs from "../../configs/sizeConfigs";
import * as Api from "../../link/ServerApi";
import * as ApiTypes from '../../link/ApiTypes';
import { setUser } from "../../redux/features/loginSlice";
import { RootState } from "../../redux/store";
import { userName } from '../../util/DataUtil';
import { stringAvatar } from "../../util/UiUtil";
import LogoutItem from "../usermenu/LogoutItem";

type Props = {
  title: string,
  user: ApiTypes.User
}

/**
 * The top bar of the window, containing page title, user icon/menu and
 * a global progress indicator
 * @param param0 
 * @returns 
 */
const Topbar = ({ title, user } : Props) => {
  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Menu show/hide state
  const [anchorEl, setAnchorEl] = useState<Element|null>(null);
  //Global progress state
  const { globalProgress } = useSelector((state : RootState) => state.globalProgress);
  



  //No user display
  const noUser = !user && <Avatar />

  //User display
  const open = Boolean(anchorEl);
  const withUser = user && <>
          <IconButton onClick={handleClick}><Avatar {...stringAvatar(userName(user))} /></IconButton>
          <Menu id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: { pt: 0 }
            }}
          >
            <Typography variant="subtitle1" color='secondary.main' sx={{textAlign: 'center', fontWeight: 'bold', mt: '0', p: 1}}>{userName(user)}</Typography>
            <Divider />
            <MenuItem onClick={handleUserAccount}>
              <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
              <ListItemText>Mon Compte</ListItemText>
            </MenuItem>
            <LogoutItem onLogOut={ handleLogOut } />
          </Menu>
          <LinearProgress sx={{position: 'absolute', inset: 'auto 0 0 0'}}
                          variant={globalProgress < 0 ? 'indeterminate' : 'determinate'}
                          value={globalProgress} />
        </>;

  //Full Layout
  return (
    <AppBar
    position="fixed"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: `${sizeConfigs.sidebar.width}`,
        boxShadow: "unset"
      }}
    >
      <Toolbar>
        <Stack direction='row' flexGrow="1" alignItems='center'>
          <Typography variant="h6" noWrap component="div" flexGrow="1">
            {title}
          </Typography>
          {noUser}
          {withUser}
        </Stack>
      </Toolbar>
    </AppBar>
  );




  //Action handlers
  function handleClick(event: MouseEvent) {
    setAnchorEl(event.currentTarget as Element);
  }

  function handleClose(event: MouseEvent) {
    setAnchorEl(null);
  }

  function handleUserAccount(event: MouseEvent) {
    navigate(`/accesscontrol/users/${user?.id}`);
    setAnchorEl(null);
  }

  function handleLogOut(event: UIEvent) {
    Api.logout()
      .then(appResp => {
        if (!appResp.ok) {
          console.log("Error during logout:", appResp.errorMessage);
          return;
        }
        console.log("Logged out");
      })
      .catch(err => {
        console.error("Error during logout:", err);
        console.warn("Session has been closed locally, but user is NOT logged out from server");
      });
    dispatch(setUser(null));
    navigate('/');
  }
};

export default Topbar;