import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect } from 'react';
import * as ApiTypes from '../../link/ApiTypes';
import * as Api from '../../link/ClientServerApi';
import { enqueueSnacks } from './forms/FormComponents';

type ClientModelAutoCompleteProps = TextFieldProps & {
  value?: ApiTypes.SimpleModel,
  client?: ApiTypes.Client,
  onModelChoice: (model?: ApiTypes.SimpleModel) => void,
}

const ClientModelAutoComplete: React.FC<ClientModelAutoCompleteProps> = props => {
  const [ loading, setLoading ] = React.useState<boolean>(true);
  const [ models, setModels ] = React.useState<ApiTypes.SimpleModel[]>([]);
  const [ modelChoices, setModelChoices ] = React.useState<ApiTypes.SimpleModel[]>([]);
  const [ currentClient, setCurrentClient ] = React.useState<ApiTypes.Client|undefined>(props.client);

  if (props.client !== currentClient)
    setCurrentClient(props.client);

  useEffect(() => {
    //Load from API
    //Catch to snackbar
    if (!currentClient) {
      setModels([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    Api.getClientModels(currentClient.id)
    .then(appResp => {
      if (!appResp.ok) {
        enqueueSnacks(undefined, appResp.warnings?.map(w => "Chargement des modèles\u00a0:\n" + w));
        return;
      }
      let models = appResp.value;
      if (!models) {
        enqueueSnacks(undefined, undefined, "Chargement des modèles\u00a0:\nRéponse vide du serveur");
        models = [];
      }
      models.sort((a, b) => b.created.localeCompare(a.created));
      setModels(models);
      setModelChoices(models.slice(0, 20));
    })
    .catch(err => {
      enqueueSnacks(undefined, undefined, "Chargement des modèles\u00a0:\nErreur au chargement\u00a0: " +  err );
    }).finally(() => {
      setLoading(false);
    });
  }, [currentClient]);

  const onInputChange = React.useCallback((event: React.SyntheticEvent, value: string, _reason: string) => {
    const filteredModels = !value
      ? models
      : models.filter(model => model.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())).sort((a, b) => a.created.localeCompare(b.created));
    setModelChoices(filteredModels.slice(0, 20));
  }, [models, setModelChoices]);

  const textFieldProps = {...props, id: ''};
  delete (textFieldProps as any).onModelChoice; //Remove the custom event handler to avoid compiler warning

  return (
    <Autocomplete
      id={props.id || "model-autocomplete"}
      options={modelChoices || []}
      getOptionLabel={(option) => option.name}
      value={props.value || null}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onChange={onModelChoiceChange}
      disabled={props.disabled}
      renderInput={(params) => <TextField {...{...params, ...textFieldProps, InputProps: {...params.InputProps, ...textFieldProps.InputProps } } } />}
      onInputChange={onInputChange}
      style={{...props.style }}
      loadingText="Chargement…"
      loading={loading}
      autoHighlight={true} //Pre-selects closest value
      autoSelect={true} //Selects highlighted value on blur
      autoComplete={true} //Autocompletes the text field when a choice is selected
      clearOnEscape={true} //ESC clears the text field
    />
  );

  function onModelChoiceChange(event: React.SyntheticEvent<Element, Event>, value: ApiTypes.SimpleModel | null, _reason: AutocompleteChangeReason, _details?: AutocompleteChangeDetails<ApiTypes.SimpleModel>) {
    if (props.onModelChoice) {
      props.onModelChoice(value || undefined);
    }
  }

};

export default ClientModelAutoComplete;
