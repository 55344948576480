import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import * as ApiLink from '../../link/ServerApi';
import { EventHandler, MouseEvent, UIEvent } from 'react';

type Props =  { onLogOut?: EventHandler<UIEvent> }

export default function LogoutItem({ onLogOut } : Props) {
    const handleLogout = (e : MouseEvent) => {
        e.preventDefault();

        ApiLink.logout()
            .catch(reason => {
                console.error(reason);
                window.alert(reason);
            })
            .finally(() => {
                if (onLogOut) {
                    onLogOut(e);
                }
            });
    };

    return (
        <MenuItem onClick={ handleLogout }><ListItemIcon><LogoutIcon /></ListItemIcon><ListItemText>Déconnexion</ListItemText></MenuItem>
    );
}