import { Edit as EditIcon } from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridDeleteIcon, GridRowId } from '@mui/x-data-grid';
import moment from 'moment';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NumberFormat } from '../../components/common/Localization';
import { FlexRow, FormSnackbarProvider, StoredFileWithState, UploadButton, UploadedFile, UploadingFile, enqueueSnacks } from '../../components/common/forms/FormComponents';
import { theme } from '../../configs/themeOptions';
import * as ApiTypes from '../../link/ApiTypes';
import * as Api from '../../link/MaterialServerApi';
import { setGlobalProgress } from '../../redux/features/progressSlice';

type Props = { };

type FormState = {
  blocked: boolean,
  selectedRows?: GridRowId[],
  loadError?: string,
  alertText?: string,
  deleteConfirmText?: string,
  uploadingFile?: StoredFileWithState | UploadingFile
  materials?: ApiTypes.MaterialWithRefCount[],
  materialUpload?: ApiTypes.UploadMaterialResult
};

const MaterialsPage = (props : Props) => {
  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false});
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);

  //Load
  React.useEffect(() => {
    if (!dataLoaded) {
      startOperation(false);
      Api.getAllMaterials()
        .then(appResp => {
          if (!appResp.ok) {
            endOperation({ ...formState, loadError: appResp.errorMessage && "Erreur au chargement\u00a0: " + appResp.errorMessage });
            enqueueSnacks(undefined, appResp.warnings);
            return;
          }
          if (!appResp.value) {
            endOperation({ ...formState, loadError: "Réponse vide du serveur" });
            return;
          }
          console.log("Materials loaded", appResp.value);
          endOperation({ ...formState, materials: appResp.value });
        })
        .catch(err => endOperation({ ...formState, loadError: "Erreur au chargement\u00a0: " +  err }))
        .finally(() => { 
          setDataLoaded(true);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);



  //Grid Columns
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nom', width: 250 },
    { 
      field: 'created',
      type:'dateTime',
      headerName: 'Créé le',
      width: 180,
      valueGetter: params => {
        if (!params.value) return null;
        return moment(params.value).toDate();
      }
    },
    {
      field: 'refCount',
      headerName: 'Références',
      width: 100,
      type: 'number',
    },
  ];

  //Layout
  return (
    <Container maxWidth='md'>
      <Typography variant='h5'>Tous les matériaux</Typography>
      {formState.loadError && <Alert severity='error'>{formState.loadError}</Alert>}
      <DataGrid
        rows={formState.materials || []}
        getRowId={row => row.name}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50, 100]} //No more than 100 in MIT version
        checkboxSelection
        loading={formState.blocked}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        rowSelectionModel={formState.selectedRows}
        onRowSelectionModelChange={ (selectionModel, _details) => setFormState({...formState, selectedRows: selectionModel}) }
        onRowClick={ (params, _event, _details) => setFormState({...formState, selectedRows: [params.id]}) }
      />
      <Box>
        <FlexRow sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='primary' onClick={confirmDeleteSelected}
                  disabled={!formState.selectedRows?.length || formState.blocked}
                  startIcon={<GridDeleteIcon />}>
            Supprimer
          </Button>
          <Button variant='contained' color='secondary' onClick={editSelected}
                  disabled={!formState.selectedRows?.length || formState.blocked}
                  startIcon={<EditIcon />}>
            Modifier
          </Button>
        </FlexRow>
        {
          formState.uploadingFile
          ? <Box mt={1}><UploadedFile value={formState.uploadingFile} /></Box>
          : <FlexRow sx={{justifyContent: 'flex-end' }}>
              <UploadButton variant='outlined' color='secondary'
                            accept='.gltf,.glb'
                            startIcon={<UploadFileIcon />}
                            title='Charger un fichier de modèle GLTF contenant des matériaux à importer'
                            onChange={uploadFile}>
                Charger fichier
              </UploadButton>
            </FlexRow>
        }
      </Box>

      <LocalAlertDialog />

      <LocalDeleteConfirmDialog onConfirm={deleteSelected} />

      <LocalUploadedMaterialsDialog checkedMaterials={formState.materialUpload?.materials} onImportClick={ onImportClick } />

      <FormSnackbarProvider />
    </Container>
  );


  

  //Action handlers

  function editSelected(event: React.MouseEvent) {
    if (formState.selectedRows?.length) {
      if (formState.selectedRows.length > 1) {
        setFormState({ ...formState, alertText: "Un seul matériau peut être modifié à la fois" });
      } else {
        navigate("" + formState.selectedRows[0]);
      }
    }
  }

  function uploadFile(e : React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files?.length) {
      const tempFile = files[0];
      const uploadingMat : UploadingFile = { file: tempFile, filename: tempFile.name }; 
      setFormState({...formState, uploadingFile: uploadingMat});
      Api.uploadMaterialFile(tempFile)
        .then(appResp => {
          if (!appResp.ok) {
            if (appResp.errorMessage) {
              console.error("Error uploading file: ", appResp);
              enqueueSnackbar("Erreur à l'envoi du fichier\u00a0: " + appResp.errorMessage, { variant: "error" });
            }
            for (const warn of appResp.warnings || []) {
              enqueueSnackbar("Envoi du fichier\u00a0: " + warn, { variant: "warning" });
            }
            setFormState({ ...formState, uploadingFile: undefined });
            return;
          }
          if (!appResp.value) {
            console.error("Error uploading file: empty server response", appResp);
            enqueueSnackbar("Réponse vide du serveur", { variant: "error" });
            setFormState({ ...formState, uploadingFile: undefined });
            return;
          }

          if (Object.keys(appResp.value).length < 1) {
            setFormState({ ...formState, uploadingFile: undefined });
            console.warn("Uploading materials file: no material found in file", appResp);
            enqueueSnackbar("Aucun matériau trouvé dans le fichier", { variant: "warning" });
            return;
          } 

          setFormState({ ...formState, uploadingFile: undefined, materialUpload: appResp.value });
        })
        .catch(err => {
          console.error("Error uploading file:", err);
          const mess = err.message ? err.message : ('' + err);
          enqueueSnackbar(mess, { variant: "error" });
        });
    }
  }

  function onImportClick(selectedMatNames: string[]): void {
    if (!formState.materialUpload?.basePath) {
      enqueueSnacks(undefined, [], 'Pas d\'import de matériaux en cours');
      setFormState({ ...formState, materialUpload: undefined });
      return;
    }
    startOperation(true);
    Api.importMaterials(formState.materialUpload.basePath, selectedMatNames)
      .then(appResp => {
        if (!appResp.ok) {
          if (appResp.errorMessage) {
            console.error("Error importing materials", selectedMatNames, ": ", appResp);
            enqueueSnackbar("Erreur à l'import de matériaux\u00a0: " + appResp.errorMessage, { variant: "error" });
          }
          for (const warn of appResp.warnings || []) {
            enqueueSnackbar("Import de matériaux\u00a0: " + warn, { variant: "warning" });
          }
          return;
        }
        if (!appResp.value) {
          console.error("Error importing materials: empty server response", appResp);
          enqueueSnackbar("Réponse vide du serveur", { variant: "error" });
          return;
        }

        const matNames = Object.getOwnPropertyNames(appResp.value);
        let remainCount = matNames.length;
        matNames.forEach(matName => {
          if (!appResp.value) return;
          const matResp = appResp.value[matName];
          if ( matResp.reason && ![ 'changed', 'no-change', 'new' ].includes(matResp.reason) ) {
            enqueueSnackbar(`${matName}\u00a0: ${matResp.reason}`, { variant: "warning" });
            remainCount--;
          } else if (!matResp.saved) {
            remainCount--;
          }
        });

        if (remainCount) {
          enqueueSnackbar(`${remainCount} Matériau${remainCount > 1 ? 'x' : ''} importé${remainCount > 1 ? 's' : ''}`, { variant: "success" });
        }
      })
      .catch(err => {
        console.error("Error importing materials:", err);
        const mess = err.message ? err.message : ('' + err);
        enqueueSnackbar(mess, { variant: "error" });
      })
      .finally(() => {
        endOperation({ ...formState, materialUpload: undefined });
        setDataLoaded(false);
      });
  }

  function confirmDeleteSelected(event: React.MouseEvent) {
    if (formState.selectedRows?.length) {
      if (formState.selectedRows.length > 1) {
        setFormState({ ...formState, deleteConfirmText: `Supprimer les ${formState.selectedRows.length} matériaux sélectionnés\u00a0?` });
      } else {
        const mName = formState.selectedRows[0];
        const mat = formState.materials?.find(m => m.name === mName);
        setFormState({ ...formState, deleteConfirmText: `Supprimer le matériau ${mat?.name}\u00a0?` });
      }
    }
  }

  function deleteSelected(event: React.MouseEvent) {
    if (!formState.selectedRows?.length) {
      enqueueSnacks(undefined, ['Aucune sélection']);
      return;
    }
    if (formState.selectedRows?.length !== 1) {
      deleteMany(formState.selectedRows);
      return;
    }
    deleteOne(formState.selectedRows[0]);
  }

  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, deleteConfirmText: undefined, loadError: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, deleteConfirmText: undefined, blocked: false });
  }

  function deleteMany(names: GridRowId[]) {
    console.log("Delete", names);

    startOperation();
    Api.deleteMaterials(names.map(name => name as string))
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          endOperation();
          const success = appResp.value.deleted ? `${appResp.value.deleted} matériaux supprimés` : undefined;
          const warnings = appResp.value.rejections.map(rej => {
            const mat = formState.materials?.find(m => m.name === rej.name);
            const matName = mat ? mat.name : rej.name;
            if (rej.error)
              return `${matName} - Erreur: ${rej.error}`;
            else
              return `${matName} - ${rej.warning}`;
          });
          const error = appResp.value.deleted ? undefined : "Aucun matériau supprimé";
          enqueueSnacks(success, warnings, error);
          setDataLoaded(false);
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }

  function deleteOne(name: GridRowId) {
    const material = formState.materials?.find(m => m.name === name);
    console.log("Delete", material);
    if (!material) {
      endOperation();
      enqueueSnacks(undefined, ['Matériau introuvable']);
      return;
    }

    startOperation();
    Api.deleteMaterial(material.name)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value && appResp.value !== false) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          endOperation({ ...formState, materials: formState.materials?.filter(m => m.name !== name) });
          enqueueSnacks('Matériau supprimé');
        } else {
          endOperation();
          enqueueSnacks('Le matériau n\'a pas été supprimé');
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }

  function translateReason(reason: "changed" | "not-found" | undefined, found: boolean) {
    if (found) return "Connu";
    switch (reason) {
      case 'changed': return "Modifié";
      case 'not-found': return "Nouveau";
      default: return reason;
    }
  }



  //Inner components

  function LocalAlertDialog(props: { }) : React.ReactElement | null {
    if (formState.alertText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Gestion des matériaux"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  function LocalDeleteConfirmDialog(props: {onConfirm?: MouseEventHandler}) : React.ReactElement | null {
    if (formState.deleteConfirmText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Suppression de matériaux"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.deleteConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, deleteConfirmText: undefined })} autoFocus>Annuler</Button>
            <Button onClick={e => {
                props.onConfirm && props.onConfirm(e);
              }}
              variant='contained' color='secondary'
              >
                OK
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }

  function LocalUploadedMaterialsDialog(props: { checkedMaterials?: ApiTypes.CheckedMaterials, onImportClick?: (selectedMatNames: string[]) => void }) : React.ReactElement | null {

    const matsCount = Object.keys(props.checkedMaterials || {}).length;
    const rows = Object.getOwnPropertyNames(props.checkedMaterials || {}).map(name => {
      const status = props.checkedMaterials ? props.checkedMaterials[name] : {found: false};
      return { id: name, name, reason: status.reason, found: status.found };
    });

    const [ selection, setSelection ] = React.useState<GridRowId[]>(rows.filter(r => !r.found).map(r => r.id));
    
    if (matsCount) {
      const columns: GridColDef[] = [
        {
          field: 'reason',
          headerName: 'État',
          width: 150,
          valueGetter: params => translateReason(params.value, params.row.found),
          cellClassName: params => 'reason-' + (params.row.found ? 'found' : params.row.reason)
        },
        { field: 'name', headerName: 'Matériau', width: 300}
      ];

      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Matériaux à importer"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              <NumberFormat>{matsCount}</NumberFormat> matériau{matsCount > 1 ? 'x' : ''} trouvé{matsCount > 1 ? 's' : ''} dans le fichier&nbsp;:
              <DataGrid
                columns={columns}
                rows={rows}
                rowSelection={true}
                isRowSelectable={_row => !(_row as any).found}
                rowSelectionModel={selection}
                onRowSelectionModelChange={newSelection => setSelection(newSelection)}
                checkboxSelection={true}
                pageSizeOptions={[10]}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                density='compact'
                sx={{
                  '& .reason-changed': { color: theme.palette.warning.dark },
                  '& .reason-not-found': { color: theme.palette.success.dark },
                  '& .reason-found': { color: theme.palette.text.disabled },
                  'minHeight': '32.5em',
                }}
                />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={formState.blocked} onClick={_e => setFormState({ ...formState, materialUpload: undefined })}>Annuler</Button>
            <Button disabled={formState.blocked} variant='contained' color='secondary' autoFocus
                    onClick={_e => {
                      if (props.onImportClick)
                        props.onImportClick(selection.map(id => id as string));
                    }}>Importer</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }
};

export default MaterialsPage;