import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DataGrid, GridColDef, GridDeleteIcon } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ClientAutoComplete from '../../components/common/ClientAutoComplete';
import { FlexRow, FormSnackbarProvider, FormTextField, StoredFileWithState, UploadButton, UploadedFile, UploadingFile, enqueueSnacks } from '../../components/common/forms/FormComponents';
import { ConfiguratorOptionsCard, IndoorOptionsCard, MaterialsInfoCard } from '../../components/modelpage/ModelCards';
import { theme } from '../../configs/themeOptions';
import * as ApiTypes from '../../link/ApiTypes';
import * as Api from '../../link/ModelServerApi';
import { setGlobalProgress } from '../../redux/features/progressSlice';

type Props = { };
type FormState = {
  blocked: boolean,
  loadError?: string,
  errorMessage?: string,
  warnings?: string[],
  model: ApiTypes.DetailedSimpleModelWithMatCount,
  uploadingGeometry?: StoredFileWithState | UploadingFile,
  usePositionCompression: boolean,
  useTextureCoordCompression: boolean
};  

const ModelPage = (props : Props) => {
  //URL Parameters
  let { modelId } = useParams();
  let nModelId = parseInt(modelId || '');

  //Imperative functions
  const navigate = useNavigate();
  
  //States
  const initState: FormState = { blocked: false, model: ApiTypes.emptyModel(), usePositionCompression: true, useTextureCoordCompression: true };
  const [ formState, setFormState ] = useState( initState );
  const [ deleteConfirmText, setDeleteConfirmText ] = React.useState<string>();
  const dispatch = useDispatch();
  
  //Load model
  React.useEffect(() => {
    if (modelId === 'new') {
      setFormState({...formState, blocked: false, model: ApiTypes.emptyModel() });
    } else {
      if (isNaN(nModelId)) {
        return;
      }
      startOperation();
      Api.getModel(nModelId)
        .then((appResp) => {
          if (!appResp.ok) {
            endOperation({ loadError: "Erreur au chargement\u00a0:" + appResp.errorMessage });
            return;
          }
          if (!appResp.value) {
            endOperation({ loadError: "Erreur au chargement\u00a0: Réponse vide du serveur" });
            return;
          }
          endOperation({ model: appResp.value });
        })
        .catch(err => endOperation({ ...initState, loadError: "Erreur au chargement\u00a0:" + err }));
    }
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ modelId ]);

  const [isMaterialsDialogOpen, setIsMaterialsDialogOpen] = useState(false);
  const [materialsList, setMaterialsList] = useState<{ name: string; found: boolean; }[]>([]);

  
  //Layout
  
  return (
    <Box component="form" autoComplete="off" onSubmit={save} onKeyDown={kbHotkeys}>
      
      <FlexRow mt={0}>
        <Typography variant='h5'>{ formState.model?.id ? `Modifier le modèle ${formState.model.name}` : "Nouveau modèle" }</Typography>
      </FlexRow>
      
      { formState.loadError && <Alert severity='error'>{formState.loadError}</Alert> }
      { formState.errorMessage && <Alert severity='error'>{formState.errorMessage}</Alert> }
      { formState.warnings && formState.warnings.map((warnMessage, index) => <Alert severity='warning' key={`w-${index}`}>{warnMessage}</Alert>) }

      <Box className="fields">
        <FlexRow>
          <ClientAutoComplete label="Client" name="client" onClientChoice={handleClientChoice} value={formState.model?.client}
                              sx={{ width: '50ch' }} variant='standard' disabled={formState.blocked} required />
        </FlexRow>
        <FlexRow>
          <FormTextField label="Nom" name="name" value={formState.model?.name} onChange={handleChange} sx={{width: '40ch'}} required />
          <Box>
            <FlexRow alignItems='center' sx={{ml: 2}}>
              <Typography variant='subtitle1' fontWeight='bold'>Géométrie&nbsp;:</Typography>
              {
                formState.model?.geometry 
                ? <Typography variant='body1'>{formState.model.geometry.filename}</Typography>
                : <Typography variant='body1' fontStyle='italic' color={theme.palette.grey[500]}> (Géométrie non définie)</Typography>
              }
              <UploadButton name='icon' variant='contained' startIcon={<CloudUploadIcon />}
                            disabled={formState.blocked || !!formState.uploadingGeometry} accept='.gltf,.glb'
                            title='Charger un fichier de modèle GLTF contenant la géométrie du modèle'
                            onChange={uploadGeometry}>
                Charger fichier
              </UploadButton>
            </FlexRow>
            <FlexRow mt="0" justifyContent='flex-end' alignItems='center'>
              <Typography variant='caption' color='text.secondary' title="Décocher les deux cases désactive la compression">Compression</Typography>
              <FormControlLabel label="CP" title="Utiliser la compression de position" control={
                  <Checkbox checked={formState.usePositionCompression} disabled={!!formState.uploadingGeometry}
                            onChange={e => setFormState({ ...formState, usePositionCompression: e.target.checked })} />
                } />
                <FormControlLabel label="CTC" title="Utiliser la compression de coordonnées de textures" control={
                    <Checkbox checked={formState.useTextureCoordCompression} disabled={!!formState.uploadingGeometry}
                              onChange={e => setFormState({ ...formState, useTextureCoordCompression: e.target.checked })} />
                  } />
            </FlexRow>
          </Box>
        </FlexRow>
        {formState.uploadingGeometry && <UploadedFile value={formState.uploadingGeometry} /> }

        <Divider sx={{my: 4}}>Paramètres du configurateur</Divider>

        <Container>
          <Grid2 container spacing={2} columns={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }} alignItems='stretch' justifyContent='space-around'>
            <Grid2 xs={2}>
              <ConfiguratorOptionsCard model={formState.model} />
            </Grid2>
            <Grid2 xs={2}>
              <IndoorOptionsCard model={formState.model} />
            </Grid2>
            <Grid2 xs={2}>
              <MaterialsInfoCard model={formState.model} handleClick={fetchMaterials} />
            </Grid2>
          </Grid2>
        </Container>

      </Box>

      <FlexRow>
        <Button variant='contained' color='primary' onClick={confirmDelete}  hidden={!formState.model?.id}
                disabled={!formState.model?.id || formState.blocked || formState.loadError ? true : false}
                startIcon={<GridDeleteIcon />}>
          Supprimer
        </Button>
        <Box flexGrow={1} />
        <Button component={RouterLink} to="../models" variant='outlined'>Retour</Button>
        <Button type="submit" variant='contained' color='secondary'
                disabled={formState.blocked || formState.loadError ? true : false || formState.uploadingGeometry ? true : false}
                startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </FlexRow>

      <LocalDeleteConfirmDialog onConfirm={deleteCurrent} />
      <LocalMaterialsDialog isOpen={isMaterialsDialogOpen} 
                       onClose={() => setIsMaterialsDialogOpen(false)}
                       materials={materialsList}/>

      {/* <LocalMaterialList materials={formState.} /> */}
      
      <FormSnackbarProvider />

    </Box>
  );

  //Keyboard hotkeys
  function kbHotkeys(e: React.KeyboardEvent) {
    if (e.ctrlKey) {
      switch (e.key) {
        case 's':
          e.preventDefault();
          e.stopPropagation();
          if (!formState.blocked && !formState.loadError) {
            save(e);
          }
          return false;
        }
    }
    else {
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          e.stopPropagation();
          navigate('../models');
          return false;
      }
    }
  }
  
  //Field change handlers
  
  function handleClientChoice(client?: ApiTypes.Client) {
    setFormState({ ...formState, model: { ...formState.model, client } });
  }

  function handleChange(e: ChangeEvent) {
    const {name, value} = e.target as HTMLInputElement;
    const m = { ...formState.model || ApiTypes.emptyModel() };
    (m as any)[name] = value;
    setFormState({ ...formState, model: m });
  }
  
  //Action event handlers
  function uploadGeometry(e : React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files?.length) {
      const tempFile = files[0];
      const uploadingModel : UploadingFile = { file: tempFile, filename: tempFile.name }; 
      setFormState({...formState, uploadingGeometry: uploadingModel});
      Api.uploadModelFile(tempFile, formState.usePositionCompression, formState.useTextureCoordCompression)
        .then(appResp => {
          if (!appResp.ok) {
            if (appResp.errorMessage) {
              console.error("Error uploading file: ", appResp);
              enqueueSnackbar("Erreur à l'envoi du fichier\u00a0: " + appResp.errorMessage, { variant: "error" });
            }
            for (const warn of appResp.warnings || []) {
              enqueueSnackbar("Envoi du fichier\u00a0: " + warn, { variant: "warning" });
            }
            setFormState({ ...formState, uploadingGeometry: undefined });
            return;
          }
          if (!appResp.value) {
            console.error("Error uploading file: empty server response", appResp);
            enqueueSnackbar("Réponse vide du serveur", { variant: "error" });
            setFormState({ ...formState, uploadingGeometry: undefined });
            return;
          }

          let newName = formState.model.name;
          if (!newName || formState.model?.id === undefined) {
            const pos = tempFile.name.lastIndexOf('.');
            newName = pos > 0 ? tempFile.name.substring(0, pos) : tempFile.name;
          }

          setFormState({ ...formState, model: { ...formState.model, name: newName, geometry: appResp.value.tempFile }, uploadingGeometry: undefined });
        })
        .catch(err => {
          console.error("Error uploading file:", err);
          const mess = err.message ? err.message : ('' + err);
          enqueueSnackbar(mess, { variant: "error" });
        });
    }
  }

  function confirmDelete(event: React.MouseEvent) {
      setDeleteConfirmText(`Supprimer le modèle\u00a0?`);
  }

  function deleteCurrent(event: React.MouseEvent) {
    console.log("Delete", formState.model);
    if (!formState.model?.id) {
      endOperation();
      enqueueSnacks(undefined, ['Pas de modèle connu en base de données']);
      return;
    }

    startOperation();
    Api.deleteModel(formState.model.id)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value && appResp.value !== false) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          setGlobalProgress(0);
          enqueueSnacks('Modèle supprimé');
          setTimeout(() => navigate('../models'), 1000);
        } else {
          endOperation();
          enqueueSnacks('Le modèle n\'a pas été supprimé');
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }
  
  function save(e: React.FormEvent) {
    e.preventDefault();
    startOperation();
    Api.saveModel({
      ...formState.model || ApiTypes.emptyModel()
    })
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({
            ...formState,
            errorMessage: appResp.errorMessage ? "Erreur à l'enregistrement\u00a0:" + appResp.errorMessage : undefined,
            warnings: appResp.warnings
           });
          return;
        }
        if (!appResp.value) {
          endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0: Réponse vide du serveur" });
          return;
        }
        const savedModel = appResp.value as ApiTypes.DetailedSimpleModelWithMatCount;
        endOperation({ model: savedModel });
        enqueueSnacks('Modèle enregistré');
        if (modelId === 'new') {
          navigate('../models/' + savedModel.id);
        }
      })
      .catch(err => endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0:" + err }));
  }

  function fetchMaterials() {
    if (!formState.model?.id || isNaN(formState.model?.id)){
      return;
    }
    startOperation();
    Api.getModelMaterialRefs(formState.model.id)
      .then(async appResp => {
        if (!appResp.ok) {
          endOperation({ warnings: appResp.warnings, errorMessage: appResp.errorMessage });
          return;
        }
        if (!appResp.value) {
          endOperation({ warnings: appResp.warnings, errorMessage: "Réponse vide du serveur" });
          return;
        }
        const mats = await appResp.value;
        setMaterialsList(mats);
        setIsMaterialsDialogOpen(true);
        endOperation({ model: { ...formState.model, materialRefs: appResp.value } });
      })
      .catch(err => {
        endOperation({ warnings: undefined, errorMessage: "Erreur au chargement des matériaux\u00a0:" + err });
      });
  }


  //private utility functions
  function startOperation() {
    dispatch(setGlobalProgress(-1));
    setFormState({ ...formState, blocked: true, errorMessage: undefined, loadError: undefined, warnings: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    else {
      newFormState.usePositionCompression = formState.usePositionCompression;
      newFormState.useTextureCoordCompression = formState.useTextureCoordCompression;
    }
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, blocked: false });
  }
  

  //Inner components

  function LocalDeleteConfirmDialog(props: {onConfirm?: MouseEventHandler}) : React.ReactElement | null {
    if (deleteConfirmText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Suppression de modèle"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {deleteConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setDeleteConfirmText(undefined)} autoFocus>Annuler</Button>
            <Button onClick={e => {
                setDeleteConfirmText(undefined);
                props.onConfirm && props.onConfirm(e);
              }}
              variant='contained' color='secondary'
              >
                OK
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }
  
  function LocalMaterialsDialog(props: { isOpen: boolean, onClose: () => void, materials: { name: string; found: boolean; }[] }) : React.ReactElement | null {

    if (props.materials.length) {
      const columns: GridColDef[] = [
        {
          field: 'status',
          headerName: 'État',
          width: 150,
          valueGetter: params => params.row.found ? 'Connu' : 'Inconnu',
          cellClassName: params => 'status-' + (params.row.found ? 'found' : 'not-found')
        },
        { field: 'name', headerName: 'Matériau', width: 300}
      ];

      return (
        <Dialog open={props.isOpen} onClose={props.onClose} aria-labelledby="materials-dialog-title" >
        <DialogTitle id="materials-dialog-title">Matériaux</DialogTitle>
          <DialogContent>
            <DialogContentText id="materials-dialog-description" mt={2}>
              <DataGrid
                columns={columns}
                rows={props.materials}
                getRowId={row => row.name}
                pageSizeOptions={[10]}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'status', sort: 'desc' }],
                  },
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                density='compact'
                sx={{
                  '& .status-not-found': { color: theme.palette.error.main },
                  '& .status-found': { color: theme.palette.text.disabled },
                  'minHeight': '32.5em',
                }}
                disableRowSelectionOnClick
                />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }


};

export default ModelPage;