import * as Api from "../util/ApiCall";
import { AppServerResponse, DeleteManyResult, User, UserFormData } from './ApiTypes';
import { readServerResponse } from './ServerApi';



export async function getAllUsers(): Promise<AppServerResponse<User[]>> {
    const resp = await Api.get("/api/user/all");
    return readServerResponse<User[]>("/api/user/all", resp);
}

export async function getUser(userId: any): Promise<AppServerResponse<User>> {
    const uid = !userId ? NaN : parseInt(userId);
    if (Number.isNaN(uid))
        throw new Error(`Could not parse user id from "${userId}"`);
    const resp = await Api.get("/api/user/id/" + uid);
    return readServerResponse<User>("/api/user/id/" + uid, resp);
}

export async function saveUser(user: UserFormData): Promise<AppServerResponse<User>> {
    const resp = await Api.post("/api/user/save/", user);
    return readServerResponse<User>("/api/user/save/", resp);
}

export async function deleteUser(userId: number): Promise<AppServerResponse<boolean>> {
    const resp = await Api.del("/api/user/id/" + userId);
    return readServerResponse<boolean>("/api/user/id/" + userId, resp);
}

export async function deleteUsers(userIds: number[]): Promise<AppServerResponse<DeleteManyResult>> {
    const resp = await Api.del("/api/user", userIds);
    return readServerResponse<DeleteManyResult>("/api/user", resp);
}
