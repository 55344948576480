import AddIcon from '@mui/icons-material/Add';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, TextField, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ClientLogoAndName from '../../../components/common/ClientLogoAndName';
import CodeEditor, { CodeEditorHandle } from '../../../components/common/CodeEditor';
import { FlexRow, FormSnackbarProvider, SelectList, StoredFileWithState, UploadingFile } from '../../../components/common/forms/FormComponents';
import { FloorConfig } from '../../../components/modelpage/FloorConfig';
import { theme } from '../../../configs/themeOptions';
import * as ApiTypes from '../../../link/ApiTypes';
import * as FilesApi from '../../../link/DbFilesServerApi';
import * as Api from '../../../link/ModelServerApi';
import * as AppSettingsApi from '../../../link/SettingsServerApi';
import { setGlobalProgress } from '../../../redux/features/progressSlice';

type Props = { };

type FormState = {
  blocked: boolean,
  model?: ApiTypes.ModelInterior,
  loadError?: string,
  errorMessage?: string,
  warnings?: string[],
  alertText?: string,
};

const ModelIndoorPage = (props: Props) => {

  //URL Parameters
  const { modelId } = useParams();
  let nModelId = parseInt(modelId || '');

  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false});
  const [ selectedFloor, setSelectedFloor ] = React.useState<ApiTypes.ModelFloor|undefined>(undefined);
  const [ namingFloor, setNamingFloor ] = React.useState<ApiTypes.ModelFloor|undefined>(undefined);
  const [ uploadingPlan, setUploadingPlan ] = React.useState<StoredFileWithState | UploadingFile>();
  const [ newFloors, setNewFloors ] = React.useState<ApiTypes.ModelFloor[]>([]);
  const [ floorDefaultDefinition, setFloorDefaultDefinition ] = React.useState<string | undefined>(undefined);

  //Refs
  const interiorConfigEditorRef = React.useRef<CodeEditorHandle>(null);
  const floorDefinitionEditorRef = React.useRef<CodeEditorHandle>(null);
  const floorPartitionsEditorRef = React.useRef<CodeEditorHandle>(null);

  //Load
  React.useEffect(() => {
    console.log('ModelIndoorPage loading model', nModelId, selectedFloor);

    if (isNaN(nModelId)) {
      return;
    }
    
    startOperation(false);
    Api.getModelInterior(nModelId)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({ loadError: "Erreur au chargement\u00a0:" + appResp.errorMessage });
          return;
        }
        if (!appResp.value) {
          endOperation({ loadError: "Erreur au chargement\u00a0: Réponse vide du serveur" });
          return;
        }

        fixFloorSelect(appResp.value);

        endOperation({ model: appResp.value });
      })
      .catch(err => endOperation({ loading: false,  loadError: "Erreur au chargement\u00a0:" + err }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!newFloors.length)
      return;
    if (floorDefaultDefinition) {
      setFloorDefaults(floorDefaultDefinition);
      return;
    }

    AppSettingsApi.getApplicationSetting('default.model.floor.definition')
      .then(appResp => {
        if (!appResp.ok) {
          console.error("Error loading default floor definition", appResp);
          enqueueSnackbar("Erreur au chargement de la définition d'étage par défaut\u00a0: " + appResp.errorMessage, { variant: "error" });
          return;
        }
        setFloorDefaultDefinition(appResp.value);
        setFloorDefaults(appResp.value);
      })
      .catch(err => {
        console.error("Error loading default floor definition", err);
        enqueueSnackbar("Erreur au chargement de la définition d'étage par défaut\u00a0: " + err, { variant: "error" });
      });

    function setFloorDefaults(floorDefaultDefinition?: string) {
      const newFloorPlans = formState.model?.floorPlans.map(floor => {
        if (newFloors.find(f => f.id === floor.id) && !floor.definition) {
          floor.definition = floorDefaultDefinition || '';
        }
        return floor;
      });
      if (!formState.model || !newFloorPlans) 
        return;
      setFormState({ ...formState, model: { ...formState.model, floorPlans: newFloorPlans }});
      setNewFloors([]);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFloors] );



  //Layout
  let selectedFloorIndex = formState.model?.floorPlans?.findIndex(f => f.id === selectedFloor?.id);
  if (selectedFloorIndex === undefined)
    selectedFloorIndex = -1;
  return (
    <Box component='form' onSubmit={save} onKeyDown={kbHotkeys}>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h5'>Options d'intérieur pour le modèle {formState.model && formState.model.name}</Typography>
        { formState.model?.client && <FlexRow sx={{gap:0}} m={0}><ClientLogoAndName client={formState.model.client} /></FlexRow> }
      </Box>

      { formState.loadError && <Alert severity='error' sx={{my: 1}}>{formState.loadError}</Alert> }
      { formState.errorMessage && <Alert severity='error' sx={{my: 1}}>{formState.errorMessage}</Alert> }
      { formState.warnings && formState.warnings.map(warnMessage => <Alert severity='warning'>{warnMessage}</Alert>) }
      <FlexRow sx={{gap:2}}>
        <Box flexGrow={1}>
          <FormControlLabel control={
                              <CodeEditor name='interiorConfig' codeMode='javascript'
                                          value={formState.model?.interiorConfig || ""}
                                          onChange={handleInteriorConfigChange}
                                          style={{height: '10rem'}}
                                          ref={interiorConfigEditorRef}
                                          codeValidationPrefix='const a = '
                                          />
                            }
                            label="Config intérieur" labelPlacement='top'
                            disabled={formState.blocked}
                            sx={{width: '100%',  m:0}} />
        </Box>
        <Box display='flex' flexDirection='column' >
          <Typography variant='subtitle1'>Étages</Typography>
          <Box display='flex' flexDirection='row' gap={1} flexGrow={1} >
            <SelectList items={formState.model?.floorPlans || []} value={selectedFloor}
                        onSelectChange={handleFloorSelect}
                        valueequals={floor => floor?.id === selectedFloor?.id}
                        itemdisplay={floor => floor?.name || ''} 
                        sx={{minWidth: '20ch', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '4px'}}
                        disabled={formState.blocked || formState.loadError || uploadingPlan ? true: false}
                        />
            <Box display='flex' flexDirection='column' justifyContent='space-between' gap={1}>
              <Box display='flex' flexDirection='column' gap={1}>
                <Button variant='contained' color='primary' sx={{minWidth: 0, width:'fit-content', p:0}} onClick={addFloor} disabled={formState.blocked}><AddIcon /></Button>
                <Button variant='contained' color='primary' sx={{minWidth: 0, width:'fit-content', p:0}} onClick={removeFloor} disabled={formState.blocked || !selectedFloor}><RemoveIcon /></Button>
              </Box>
              <Button variant='contained' color='primary' sx={{minWidth: 0, width:'fit-content', p:0}} onClick={renameFloor} disabled={formState.blocked || !selectedFloor}><DriveFileRenameOutlineIcon /></Button>
            </Box>
          </Box>
                            
        </Box>
      </FlexRow>

      {selectedFloor?.name ? <Divider sx={{my: 2}} >Configuration de l'étage : {selectedFloor.name}</Divider> : ""}

      <FloorConfig  model={formState.model} floor={selectedFloor} floorIndex={selectedFloorIndex}
                    disabled={formState.blocked} 
                    onUploadPlan={uploadPlan} uploadingPlan={uploadingPlan} onDeletePlan={removePlan}
                    onFloorChange={handleFloorChange} floorDefinitionEditorRef={floorDefinitionEditorRef} floorPartitionsEditorRef={floorPartitionsEditorRef}/>

      <FlexRow>
        <Box flexGrow={1} />
        <Button component={RouterLink} to='..' variant='outlined'>Retour</Button>
        <Button type="submit" variant='contained' color='secondary'
                disabled={formState.blocked || formState.loadError || uploadingPlan ? true: false}
                startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </FlexRow>

      <LocalFloorNameDialog existing={namingFloor} />
      <LocalAlertDialog />

      <FormSnackbarProvider />
    </Box>
  );



  //Keyboard hotkeys
  function kbHotkeys(e: React.KeyboardEvent) {
    if (e.ctrlKey) {
      switch (e.key) {
        case 's':
          e.preventDefault();
          e.stopPropagation();
          if (!formState.blocked && !formState.loadError && !uploadingPlan) {
            save(e);
          }
          return false;
        }
    }
    else {
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          e.stopPropagation();
          navigate('..');
          return false;
      }
    }
  }


  //Field change handlers

  function handleInteriorConfigChange(value: string) {
    if (!formState.model)
      return;
    formState.model.interiorConfig = value;
  }

  //Action handlers
  async function save(e: React.FormEvent) {

    e.preventDefault();

    startOperation();

    try {

      //Code validation 
      const errors = [];

      const icEditor = interiorConfigEditorRef?.current;
      const icError = await icEditor?.checkCode();
      if (icError) {
        errors.push(icError);
      }
      const fdEditor = floorDefinitionEditorRef?.current;
      const fdError = await fdEditor?.checkCode();
      if (fdError) {
        errors.push(fdError);
      }
      const fpEditor = floorPartitionsEditorRef?.current;
      const fpError = await fpEditor?.checkCode();
      if (fpError) {
        errors.push(fpError);
      }

      if (errors.length) {
        endOperation({ ...formState, alertText: errors.join('\n') });
        return;
      }

      if (!formState.model) {
        enqueueSnackbar("Erreur à l'enregistrement\u00a0: Données manquantes", { variant: "error" });
        return;
      }

      const floorPlans = formState.model.floorPlans;
      if (floorPlans?.length > 0) {
        const invalidDef = floorPlans.filter(f => {
          if (typeof f.partitions === 'string' && f.partitions.length > 0) {
            try {
              JSON.parse(f.partitions)
            } catch {
              return true;
            }
          }
          return false;
        })
        const invalidNames = invalidDef.map(f => f.name);
        const faulty = invalidNames.join(', ');
        if (invalidDef?.length > 0) {
          setFormState({...formState, alertText: "Saisie invalide\u00a0: Syntaxe JSON invalide pour les étages : " + faulty });
          return;
        }
      }

    } catch (err) {
      console.error("Error validating code", err);
      endOperation({ alertText: "Erreur à la validation du code\u00a0: " + err });
      return;
    }
    
    Api.saveModelInterior(formState.model)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({
            ...formState,
            errorMessage: appResp.errorMessage ? "Erreur à l'enregistrement\u00a0:" + appResp.errorMessage : undefined,
            warnings: appResp.warnings
           });
          return;
        }
        const savedModel = appResp.value as ApiTypes.ModelInterior;
        fixFloorSelect(savedModel);
        endOperation({ model: savedModel});
        enqueueSnackbar('Configuration enregistrée', { variant: "success" });
      })
      .catch(err => endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0:" + err }));
  }

  function addFloor() {
    setNamingFloor({ id: 0, name: '' });
  }

  function removeFloor() {
    if (!formState.model || !selectedFloor)
      return;
    setFormState({ ...formState, model: { ...formState.model, floorPlans: formState.model.floorPlans.filter(floor => floor.id !== selectedFloor.id) }});
    setSelectedFloor(undefined);
  }

  function renameFloor() {
    if (!selectedFloor)
      return;
    setNamingFloor(selectedFloor);
  }

  function handleFloorSelect(floor: ApiTypes.ModelFloor) {
    if (selectedFloor) {
      const f = selectedFloor;
      if (typeof f.partitions === 'string' && f.partitions.length > 0) {
        try {
          JSON.parse(f.partitions)
        } catch {
          enqueueSnackbar("Syntaxe JSON invalide pour les cloisons", { variant: "warning" });
          return ;
        }
      }
    }
    setSelectedFloor(floor);
  }

  function handleFloorChange(floor: ApiTypes.ModelFloor) {
    console.log('handleFloorChange', floor);
    if (!formState.model || !formState.model.floorPlans)
      return;
    const index = formState.model.floorPlans.findIndex(f => f.id === floor.id);
    if (index < 0)
      return;
    const floorPlans = [...formState.model.floorPlans];
    floorPlans[index] = {...floor};
    setFormState({ ...formState, model: { ...formState.model, floorPlans: floorPlans }});
  }

  function uploadPlan(floor: ApiTypes.ModelFloor, file: File) {
    setUploadingPlan({ filename: file.name, file });
    uploadTempFile(file,
      storedTempFile => {
        setUploadingPlan(undefined);
        if (!formState.model || !formState.model.floorPlans)
          return;
        const floors = [...formState.model.floorPlans];
        const index = floors.findIndex(f => f.id === floor.id);
        if (index < 0)
          return;
        floors[index].plan = storedTempFile;
        setFormState({ ...formState, model: { ...formState.model, floorPlans: floors }});
      },
      errorMessage => {
        setUploadingPlan(undefined);
        console.error("Error uploading plan:", errorMessage);
        enqueueSnackbar("Erreur à l'envoi du plan\u00a0: " + errorMessage, { variant: "error" });
      })
  }

  function uploadTempFile(file: File, onUploadDone?: (storedTempFile : StoredFileWithState) => void, onError?: (errorMessage: string) => void) {
    FilesApi.uploadTempFile(file)
      .then(appResp => {
        if (!appResp.ok) {
          if (appResp.errorMessage) {
            console.error("Error uploading file: ", appResp);
            if (onError) onError(appResp.errorMessage);
          }
          for (const warn of appResp.warnings || []) {
            enqueueSnackbar("Envoi du fichier\u00a0: " + warn, { variant: "warning" });
          }
          return;
        }
        if (!appResp.value) {
          console.error("Error uploading file: empty server response", appResp);
          if (onError) onError("Réponse vide du serveur");
          return;
        }

        const storedTempFile = appResp.value as StoredFileWithState;
        if (storedTempFile.id) {
          storedTempFile.unsaved = false;
          onUploadDone?.(storedTempFile);
          return;
        }

        console.error("Error uploading file: invalid server response", appResp);
        if (onError) onError("Réponse invalide du serveur");
      })
      .catch(err => {
        console.error("Error uploading file:", err);
        const mess = err.message ? err.message : ('' + err);
        if (onError) onError(mess);
      });
  }

  function removePlan(floor: ApiTypes.ModelFloor) {
    if (!formState.model || !formState.model.floorPlans)
      return;
    const floors = [...formState.model.floorPlans];
    const index = floors.findIndex(f => f.id === floor.id);
    if (index < 0)
      return;
    floors[index].plan = undefined;
    setFormState({ ...formState, model: { ...formState.model, floorPlans: floors }});
  }
    

  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, loadError: undefined, errorMessage: undefined, alertText: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, blocked: false });
  }

  function getNewFakeId() {
    if (!formState.model || !formState.model.floorPlans)
      return -1;
    let max = 0;
    formState.model.floorPlans.forEach(floor => {
      if (Math.abs(floor.id) > max)
        max = Math.abs(floor.id);
    });
    return -max - 1;
  }

  function fixFloorSelect(model: ApiTypes.ModelInterior) {
    if (selectedFloor && model.floorPlans?.length) {
      let found = model.floorPlans.find(f => f.id === selectedFloor.id);
      if (found) {
        if (found !== selectedFloor)
          setSelectedFloor(found);
      } else {
        setSelectedFloor(model.floorPlans.find(f => f.name === selectedFloor.name) || model.floorPlans[0]);
      }
    }
  }

  //Local controls

  function LocalFloorNameDialog(props: {existing?: ApiTypes.ModelFloor}) : React.ReactElement | null {
    const [ floorName, setFloorName ] = React.useState<string>(props.existing?.name || '');
    if (!namingFloor) {
      return null;
    }
    return (
      <Dialog
        open={true}
        component='form' disableRestoreFocus
        aria-labelledby="alert-dialog-title"
        onSubmit={handleNameSet}
      >
        <DialogTitle id="alert-dialog-title">
          {props.existing?.id ? "Renommer un étage" : "Ajout d'un étage"} 
        </DialogTitle>
        <DialogContent>
          <TextField  name="floorName" type="text" value={floorName}
                      onChange={e => setFloorName(e.target.value)} required
                      label={props.existing?.id ? `Nouveau nom pour ${props.existing.name}` : "Nom de l'étage à ajouter"}
                      fullWidth autoFocus sx={{ mt:2 }} />
        </DialogContent>
        <DialogActions>
          <Button type='reset' onClick={_e => setNamingFloor(undefined)}>Annuler</Button>
          <Button type='submit'
                  variant='contained' color='secondary'
                  >
                  OK
          </Button>
        </DialogActions>
      </Dialog>
    );

    function handleNameSet(e: React.FormEvent) {
      e.preventDefault();
      e.stopPropagation();
      if (!formState.model) {
        setNamingFloor(undefined);
        return;
      }
      if (!formState.model.floorPlans)
        formState.model.floorPlans = [];
      if (!floorName)
        return;
      const tfloorName = floorName.trim();
      if (!tfloorName.length)
        return;
      const exists = formState.model.floorPlans.find(floor => {
            return (!props.existing?.id || floor.id !== props.existing.id) && floor.name.toLocaleLowerCase() === tfloorName.toLocaleLowerCase();
          });
      if (exists) {
        enqueueSnackbar("Un étage de ce nom existe déjà", { variant: "warning" });
        return;
      }
      if (props.existing?.id) {
        props.existing.name = tfloorName;
        const floors = [...formState.model.floorPlans];
        const index = floors.findIndex(f => f.id === props.existing?.id);
        if (index < 0)
          return;
        floors[index] = props.existing;
        setFormState({ ...formState, model: { ...formState.model, floorPlans: floors }});
      } else {
        const newFloor = props.existing || { id: getNewFakeId(), name: tfloorName };
        newFloor.id = getNewFakeId();
        newFloor.name = tfloorName;
        formState.model.floorPlans.push(newFloor);
        setNewFloors([...newFloors, newFloor]);
        setSelectedFloor(newFloor);
      }
      setNamingFloor(undefined);
    }
  }

  function LocalAlertDialog(props: { }) : React.ReactElement | null {
    if (formState.alertText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Enregistrement de la configuration intérieure"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }


}

export default ModelIndoorPage;