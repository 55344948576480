import { PaletteColor, PaletteColorOptions, createTheme } from "@mui/material";
import { frFR as gridFrFR } from '@mui/x-data-grid';
import { frFR } from '@mui/material/locale';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
  interface Palette {
    card1: PaletteColor;
  }
  interface PaletteOptions {
    card1: PaletteColorOptions;
  }
  interface Palette {
    card2: PaletteColor;
  }
  interface PaletteOptions {
    card2: PaletteColorOptions;
  }
  interface Palette {
    card3: PaletteColor;
  }
  interface PaletteOptions {
    card3: PaletteColorOptions;
  }
  interface Palette {
    card4: PaletteColor;
  }
  interface PaletteOptions {
    card4: PaletteColorOptions;
  }
  interface Palette {
    card5: PaletteColor;
  }
  interface PaletteOptions {
    card5: PaletteColorOptions;
  }
}

const p = createTheme().palette;
const themePalette = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#585858',
      contrastText: '#dcdcdc',
    },
    secondary: {
      main: '#d60e00',
    },
    background: {
      default: '#ffffff',
    },
    card1: p.augmentColor({
      color: {
        main: 'hsl(0, 17, 75%)',
      }
    }),
    card2: p.augmentColor({
      color: {
        main: 'hsl(72, 17, 75%)',
      }
    }),
    card3: p.augmentColor({
      color: {
        main: 'hsl(145, 17, 75%)',
      }
    }),
    card4: p.augmentColor({
      color: {
        main: 'hsl(215, 17, 75%)',
      }
    }),
    card5: p.augmentColor({
      color: {
        main: 'hsl(289, 17, 75%)',
      }
    }),
  },
  components: {
  }
}, frFR, gridFrFR);

export const theme = createTheme(themePalette, {
  typography: {
    fontFamily: 'Inter, Arial, Sans-Serif, sansserif, sans',
  },
  components: {
    // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
        },
        columnHeader: {
          '&:focus': {
            outline: 'none'
          },
          '&:focus-within': {
            outline: 'none'
          },
        }
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: themePalette.palette.primary.contrastText,
          backgroundColor: themePalette.palette.primary.dark,
          padding: '.25rem 2rem'
        },
      }
    },
    MuiSwitch: {
      styleOverrides: {
        //Override thumb color for boolean switch
        colorPrimary: {
          "&.Mui-checked": {
            color: themePalette.palette.secondary.main
          }
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '0 9px'
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: themePalette.palette.secondary.main
          }
        },
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          backgroundColor: themePalette.palette.secondary.main
        },
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: themePalette.palette.secondary.main
        },
      }
    },
  },
});