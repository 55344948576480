import SaveIcon from '@mui/icons-material/Save';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Select, SelectChangeEvent, Switch, Typography } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import React, { ChangeEvent, MouseEventHandler, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { FlexRow, FormSnackbarProvider, enqueueSnacks } from '../../components/common/forms/FormComponents';
import { UserInfo, UserInfoFields } from '../../components/userpage/UserInfo';
import { theme } from '../../configs/themeOptions';
import * as ApiTypes from '../../link/ApiTypes';
import * as getAllUsers from "../../link/UserServerApi";
import { LoginState } from '../../redux/features/loginSlice';
import { setGlobalProgress } from '../../redux/features/progressSlice';
import { roleToString } from '../../util/DataUtil';
import ClientLogoAndName from '../../components/common/ClientLogoAndName';

const ROLE_VALUES = ['ADMIN', 'MODELER'];

type Props = { };

type FormState = { 
  userInfo?: UserInfoFields,
  blocked: boolean,
  loadError?: string,
  errorMessage?: string,
  warnings?: string[]
};  

const UserPage = (props : Props) => {
  //URL Parameters
  let { userId } = useParams();

  //Imperative functions
  const navigate = useNavigate();
  
  //States
  const initState: FormState = { blocked: false };
  const [ formState, setFormState ] = useState( initState );
  const [ deleteConfirmText, setDeleteConfirmText ] = React.useState<string>();
  const { user : currentUser } = useSelector((state: {login: LoginState}) => state.login);
  const dispatch = useDispatch();

  //Load
  React.useEffect(() => {
    if (userId === 'new') {
      setFormState({...formState, blocked: false, userInfo: { user: ApiTypes.emptyUser() }});
      return
    }
    startOperation();
    getAllUsers.getUser(userId)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({ loadError: "Erreur au chargement\u00a0:" + appResp.errorMessage });
          return;
        }
        if (!appResp.value) {
          endOperation({ loadError: "Erreur au chargement\u00a0: Réponse vide du serveur" });
          return;
        }
        endOperation({ userInfo: { user: appResp.value } });
      })
      .catch(err => endOperation({ ...initState, loadError: "Erreur au chargement\u00a0:" + err }));
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ userId ]);
  
  
  //Layout

  return (
    <Box component="form" autoComplete="off" onSubmit={save} onKeyDown={kbHotkeys} >
      <FlexRow mt={0}>
        <Typography variant='h5'>{ formState.userInfo?.user?.id ? `Modifier l'utilisateur ${formState.userInfo?.user?.login}` : "Nouvel utilisateur" }</Typography>
        <Box flexGrow={1} />

        { currentUser?.id !== formState.userInfo?.user?.id 
          ? <FormControlLabel
            control={ <Switch name="active" checked={formState.userInfo?.user?.active || false} onChange={handleCheck} disabled={currentUser?.id === formState.userInfo?.user?.id} /> }
            label="Actif" labelPlacement='start' />
          : ''
        }

      </FlexRow>
      { formState.loadError && <Alert severity='error'>{formState.loadError}</Alert> }
      { formState.errorMessage && <Alert severity='error'>{formState.errorMessage}</Alert> }
      { formState.warnings && formState.warnings.map((warnMessage, index) => <Alert severity='warning' key={`w-${index}`}>{warnMessage}</Alert>) }
      <Box className="fields">
        <UserInfo userInfo={formState.userInfo || {user: ApiTypes.emptyUser()}} onUserInfoChanged={userInfo => setFormState({ ...formState, userInfo: userInfo })} />

        {
          formState.userInfo?.user?.client
          ?
          <FlexRow p={3} sx={{
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                '& a': {
                  color: 'inherit'
                }
              }}>
            <Typography className='field-header' fontWeight='bold'>Utilisateur du client&nbsp;:</Typography>
            { 
              currentUser?.roles.includes('ADMIN' || 'MODELER') 
                ? <Link component={RouterLink} to={`../clients/${formState.userInfo?.user?.client?.id}`} display='flex' gap={1} alignItems='center'>
                    <ClientLogoAndName client={formState.userInfo?.user?.client} />
                  </Link>
                : <ClientLogoAndName client={formState.userInfo?.user?.client} />
            }
          </FlexRow>
          :
          <FlexRow>
            <FormControl>
              <InputLabel id="roles-label" htmlFor='roles'>Rôles *</InputLabel>
              <Select id="roles"
                      label="Rôles"
                      multiple
                      variant='standard'
                      labelId="roles-label"
                      value={ formState.userInfo?.user?.roles || [] }
                      onChange={handleRoleChange}
                      disabled={currentUser?.id === formState.userInfo?.user?.id && !currentUser?.roles.includes('ADMIN')}
                      required={true}
                      sx={{minWidth: "20em"}}>
                { ROLE_VALUES.map(rv => <MenuItem value={rv} key={rv}>{roleToString(rv)}</MenuItem>) }
              </Select>
            </FormControl>
          </FlexRow>
        }
      </Box>
      <FlexRow>
        { 
          currentUser?.id !== formState.userInfo?.user?.id
            ? <Button variant='contained' color='primary' onClick={confirmDelete}  hidden={!formState.userInfo?.user?.id}
                    disabled={!formState.userInfo?.user?.id || formState.blocked || formState.loadError ? true : false}
                    startIcon={<GridDeleteIcon />}>
                Supprimer
              </Button>
            : ''  
        }
        <Box flexGrow={1} />
        {
          !currentUser?.roles.includes('CLIENT') 
            ? <Button component={RouterLink} to="../users" variant='outlined'>Retour</Button>
            : ''
        }
        <Button type="submit" variant='contained' color='secondary'
                disabled={formState.blocked || formState.loadError ? true : false}
                startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </FlexRow>

      <LocalDeleteConfirmDialog onConfirm={deleteCurrent} />
      
      <FormSnackbarProvider />
    </Box>
  );

  //Keyboard hotkeys
  function kbHotkeys(e: React.KeyboardEvent) {
    if (e.ctrlKey) {
      switch (e.key) {
        case 's':
          e.preventDefault();
          e.stopPropagation();
          if (!formState.blocked && !formState.loadError) {
            save(e);
          }
          return false;
        }
    }
    else {
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          e.stopPropagation();
          navigate('../users');
          return false;
      }
    }
  }
  
  //Field change handlers

  function handleCheck(e: ChangeEvent) {
    const {name, checked} = e.target as HTMLInputElement;
    const ui = { ...formState.userInfo || { user: ApiTypes.emptyUser() } };
    (ui.user as any)[name] = !!checked;
    setFormState({ ...formState, userInfo: ui });
  }
  
  function handleRoleChange(e: SelectChangeEvent<string[]>) {
    const roles = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
    const ui = { ...formState.userInfo || { user: ApiTypes.emptyUser() } };
    if (!ui.user)
      ui.user = ApiTypes.emptyUser();
    ui.user.roles = roles;
    setFormState({ ...formState, userInfo: ui });
  }
  
  
  
  //Action event handlers

  function confirmDelete(event: React.MouseEvent) {
      setDeleteConfirmText(`Supprimer l'utilisateur\u00a0?`);
  }

  function deleteCurrent(event: React.MouseEvent) {
    console.log("Delete", formState.userInfo?.user);
    if (!formState.userInfo?.user?.id) {
      endOperation();
      enqueueSnacks(undefined, ['Pas d\'utilisateur connu en base de données']);
      return;
    }

    startOperation();
    getAllUsers.deleteUser(formState.userInfo.user.id)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value && appResp.value !== false) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          setGlobalProgress(0);
          enqueueSnacks('Utilisateur supprimé');
          setTimeout(() => navigate('../users'), 1000);
        } else {
          endOperation();
          enqueueSnacks('L\'utilisateur n\'a pas été supprimé');
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }
  
  function save(e: React.FormEvent) {
    e.preventDefault();
    if (formState.userInfo?.password1) {
      if (formState.userInfo?.password2 !== formState.userInfo?.password1) {
        setFormState({...formState, warnings: ['Les mots de passe ne correspondent pas']});
        return;
      }
    }

    startOperation();
    getAllUsers.saveUser({
      ...formState.userInfo?.user || ApiTypes.emptyUser(),
      password1: formState.userInfo?.password1,
      password2: formState.userInfo?.password2,
    })
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({
            ...formState,
            errorMessage: appResp.errorMessage ? "Erreur à l'enregistrement\u00a0:" + appResp.errorMessage : undefined,
            warnings: appResp.warnings
           });
          return;
        }
        if (!appResp.value) {
          endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0: Réponse vide du serveur" });
          return;
        }
        const savedUser = appResp.value as ApiTypes.User;
        endOperation({ userInfo: { user: savedUser } });
        enqueueSnacks('Utilisateur enregistré');
        if (userId === 'new') {
          navigate('../users/' + savedUser.id);
        }
      })
      .catch(err => endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0:" + err }));
  }


  //private utility functions
  function startOperation() {
    dispatch(setGlobalProgress(-1));
    setFormState({ ...formState, blocked: true, errorMessage: undefined, loadError: undefined, warnings: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, blocked: false });
  }



  //Inner components

  function LocalDeleteConfirmDialog(props: {onConfirm?: MouseEventHandler}) : React.ReactElement | null {
    if (deleteConfirmText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Suppression d'utilisateur"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {deleteConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setDeleteConfirmText(undefined)} autoFocus>Annuler</Button>
            <Button onClick={e => {
                setDeleteConfirmText(undefined);
                props.onConfirm && props.onConfirm(e);
              }}
              variant='contained' color='secondary'
              >
                OK
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }
  
  
};

export default UserPage;