import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import HomePage from "../pages/home/HomePage";
import AccessPageLayout from "../pages/access/AccessPageLayout";
import AccessIndexPage from "../pages/access/AccessIndexPage";
import UsersPage from "../pages/access/UsersPage";
import UserPage from '../pages/access/UserPage';
import ClientsPage from "../pages/access/client/ClientsPage";
import ModellingPageLayout from '../pages/modelling/ModellingPageLayout';
import ModellingIndexPage from '../pages/modelling/ModellingIndexPage';
import MaterialsPage from '../pages/modelling/MaterialsPage';
import ModelsPage from '../pages/modelling/ModelsPage';
import ClientModelsPage from '../pages/modelling/ClientModelsPage';
import StatisticsPageLayout from '../pages/statistics/StatisticsPageLayout';
import ConversionsPage from '../pages/statistics/ConversionsPage';
import { ReactNode } from 'react';
import ClientPage from '../pages/access/client/ClientPage';
import ClientUsersPage from '../pages/access/client/ClientUsersPage';
import ClientContactFormPage from '../pages/access/client/ClientContactFormPage';
import ClientCustomizationPage from '../pages/access/client/ClientCustomizationPage';
import ModelPage from '../pages/modelling/ModelPage';
import ModelConfiguratorPage from '../pages/modelling/model/ModelConfiguratorPage';
import { Outlet } from 'react-router-dom';
import ModelIndoorPage from '../pages/modelling/model/ModelIndoorPage';

export type RouteState = {
  menu: string,
  sidebarProps?: {
    displayText: string,
    roles?: string[]
  }
  pageProps?: {
    title: string
  }
}

export type RouteDef = {
  index?: Boolean,
  path?: string,
  icon?: ReactNode,
  element: ReactNode,
  state: RouteState,
  child?: RouteDef[]
};

const appRoutes : RouteDef[] = [
  {
    index: true,
    element: <HomePage />,
    state: {
      menu: "home",
      pageProps: {
        title: 'Accueil',
      }
    }
  },
  {
    path: "/accesscontrol",
    element: <AccessPageLayout />,
    state: {
      menu: "accesscontrol",
      sidebarProps: {
        displayText: "Accès",
        roles: ['ADMIN', 'MODELER']
      }
    },
    icon: <SupervisedUserCircleIcon />,
    child: [
      {
        index: true,
        element: <AccessIndexPage />,
        state: { menu: "accesscontrol.index" }
      },
      {
        path: "/accesscontrol/users",
        element: <UsersPage />,
        state: {
          menu: "accesscontrol.users",
          sidebarProps: {
            displayText: "Utilisateurs",
            roles: ['ADMIN']
          },
          pageProps: {
            title: 'Utilisateurs',
          }
        }
      },
      {
        path: "/accesscontrol/users/:userId",
        element: <UserPage />,
        state: {
          menu: "accesscontrol.users",
          pageProps: {
            title: 'Utilisateur',
          }
        }
      },
      {
        path: "/accesscontrol/clients",
        element: <ClientsPage />,
        state: {
          menu: "accesscontrol.clients",
          sidebarProps: {
            displayText: "Clients"
          },
          pageProps: {
            title: 'Clients',
          }
        }
      },
      {
        path: "/accesscontrol/clients/:clientId",
        element: <Outlet />,
        state: {
          menu: "accesscontrol.clients"
        },
        child: [
          {
            index: true,
            element: <ClientPage />,
            state: {
              menu: "accesscontrol.clients",
              pageProps: {
                title: 'Client',
              }
            },
          },
          {
            path: "/accesscontrol/clients/:clientId/users",
            element: <ClientUsersPage />,
            state: {
              menu: "accesscontrol.clients",
              pageProps: {
                title: 'Utilisateurs du client',
              }
            }
          },
          {
            path: "/accesscontrol/clients/:clientId/customize",
            element: <ClientCustomizationPage />,
            state: {
              menu: "accesscontrol.clients",
              pageProps: {
                title: 'Personnalisations de l\'appli 3D pour le client',
              }
            }
          },
          {
            path: "/accesscontrol/clients/:clientId/contactform",
            element: <ClientContactFormPage />,
            state: {
              menu: "accesscontrol.clients",
              pageProps: {
                title: 'Paramètres du formulaire de contact',
              }
            }
          },
        ]
      },
    ]
  },
  {
    path: "/modelling",
    element: <ModellingPageLayout />,
    state: {
      menu: "modelling",
      sidebarProps: {
        displayText: "Modélisation",
        roles: ['ADMIN', 'MODELER', 'CLIENT']
      }
    },
    icon: <HomeWorkIcon />,
    child: [
      {
        index: true,
        element: <ModellingIndexPage />,
        state: { menu: "modelling.index" }
      },
      {
        path: "/modelling/materials",
        element: <MaterialsPage />,
        state: {
          menu: "modelling.materials",
          sidebarProps: {
            displayText: "Matériaux",
            roles: ['ADMIN', 'MODELER']
          },
          pageProps: {
            title: 'Matériaux',
          }
        }
      },
      {
        path: "/modelling/models",
        element: <ModelsPage />,
        state: {
          menu:  "modelling.models",
          sidebarProps: {
            displayText: "Modèles",
            roles: ['ADMIN', 'MODELER']
          },
          pageProps: {
            title: 'Modèles',
          }
        }
      },
      {
        path: "/modelling/clientmodels",
        element: <ClientModelsPage />,
        state: {
          menu:  "modelling.clientmodels",
          sidebarProps: {
            displayText: "Mes modèles",
            roles: ['CLIENT']
          },
          pageProps: {
            title: 'Mes modèles',
          }
        }
      },
      {
        path: "/modelling/models/:modelId",
        element: <Outlet />,
        state: {
          menu: "modelling.models",
        },
        child: [
          {
            index: true,
            element: <ModelPage />,
            state: {
              menu: "modelling.models",
              pageProps: {
                title: 'Modèle',
              }
            },
          },
          {
            path: "/modelling/models/:modelId/configurator",
            element: <ModelConfiguratorPage />,
            state: {
              menu: "modelling.models",
              pageProps: {
                title: 'Configuration des options disponibles pour le modèle',
              }
            }
          },
          {
            path: "/modelling/models/:modelId/interior",
            element: <ModelIndoorPage />,
            state: {
              menu: "modelling.models",
              pageProps: {
                title: 'Configuration intérieure du modèle : étages, plans, cloisons',
              }
            }
          },
        ]
      },
      
    ]
  },
  {
    path: "/statistics",
    element: <StatisticsPageLayout />,
    state: { 
      menu: "statistics",
      sidebarProps: {
        displayText: "Statistiques",
        roles: ['ADMIN', 'MODELER', 'CLIENT']
      },
      pageProps: {
        title: 'Statistiques',
      }
    },
    icon: <AnalyticsIcon />,
    child: [
      {
        path: "/statistics/conversions",
        element: <ConversionsPage />,
        state: {
          menu: "statistics.conversions",
          sidebarProps: {
            displayText: "Conversions",
            roles: ['ADMIN', 'MODELER', 'CLIENT']
          },
          pageProps: {
            title: 'Conversions des visites en leads',
          }
        },
      }
    ]
  }
];

export default appRoutes;