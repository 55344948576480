import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "../../configs/themeOptions";
import * as ApiTypes from "../../link/ApiTypes";
import * as Api from '../../link/ClientServerApi';
import { InfoCard } from '../common/InfoCard';
import { useSelector } from 'react-redux';
import { LoginState } from '../../redux/features/loginSlice';

type ClientCardProps = {
  client?: ApiTypes.Client,
}
export function UsersCard({client} : ClientCardProps) {

  const [ usersCount, setUsersCount ] = useState<number>(0);
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ errorText, setErrorText ] = useState<string>();
  const { user : currentUser } = useSelector((state: {login: LoginState}) => state.login);

  useEffect(() => {
    if (!client)
      return;
    if (!client.id) {
      setUsersCount(0);
      setLoading(false);
      return;
    };
    Api.getClientUsersCount(client.id)
      .then(appResp => {
        if (!appResp.ok) {
          setErrorText(appResp.errorMessage);
          return;
        }
        if (!appResp.value && appResp.value !== 0) {
          setErrorText("Réponse vide du serveur");
          return;
        }
        setErrorText(undefined);
        setUsersCount(appResp.value);
      })
      .catch(err => setErrorText("" + err))
      .finally(() => setLoading(false));
  }, [client]);

  return (
    <InfoCard
      background={theme.palette.card2.light}
      color={theme.palette.card2.contrastText}
      IconType={SupervisedUserCircleIcon}
      title="Utilisateurs"
      leftContent={usersCount}
      loading={loading}
      errorText={errorText}
      actions={<Button component={RouterLink} to={`../clients/${client?.id}/users`} disabled={!client?.id || !currentUser?.roles.includes('ADMIN')}>Liste</Button>}
      >
      <Typography variant="body1" fontStyle='italic'>Gérer les accès du client au back-office</Typography>
    </InfoCard>
  );

}

export function CustomizationsCard({client} : ClientCardProps) {

  return (
    <InfoCard
      background={theme.palette.card4.light}
      color={theme.palette.card4.contrastText}
      IconType={TuneIcon}
      title="Apparence de l'application"
      actions={<Button component={RouterLink} to={`../clients/${client?.id}/customize`} disabled={!client?.id}>Modifier</Button>}
      >
      <Typography variant="h6">CSS, JavaScript, Logos</Typography>
      <Typography variant="body1" fontStyle='italic'>Mettre l'appli aux couleurs du client</Typography>
    </InfoCard>
  );

}

export function ContactFormCard({client} : ClientCardProps) {

  return (
    <InfoCard
      background={theme.palette.card5.light}
      color={theme.palette.card5.contrastText}
      IconType={PermContactCalendarIcon}
      title="Formulaire de contact"
      actions={<Button component={RouterLink} to={`../clients/${client?.id}/contactform`} disabled={!client?.id}>Modifier</Button>}
      >
      <Typography variant="h6">HTML, Validation, Plugin</Typography>
      <Typography variant="body1" fontStyle='italic'>Personnaliser le formulaire et le traitement des leads</Typography>
    </InfoCard>
  );

}