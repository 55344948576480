import * as Api from '../util/ApiCall';
import { AppServerResponse, DeleteManyByNameResult, MaterialImportResults, MaterialWithRefCount, UploadMaterialResult } from './ApiTypes';
import { readServerResponse } from './ServerApi';


export async function getAllMaterials(): Promise<AppServerResponse<MaterialWithRefCount[]>> {
  const resp = await Api.get("/api/material/all");
  return readServerResponse<MaterialWithRefCount[]>("/api/material/all", resp);
}

export async function deleteMaterial(name: string): Promise<AppServerResponse<boolean>> {
  const resp = await Api.del("/api/material/name/" + name);
  return readServerResponse<boolean>("/api/material/name/" + name, resp);
}

export async function deleteMaterials(names: string[]): Promise<AppServerResponse<DeleteManyByNameResult>> {
  const resp = await Api.del("/api/material", names);
  return readServerResponse<DeleteManyByNameResult>("/api/material", resp);
}

export async function uploadMaterialFile(file: File): Promise<AppServerResponse<UploadMaterialResult>> {
  const resp = await Api.putFile("/api/material/file", file);
  return readServerResponse<UploadMaterialResult>("/api/material/file", resp);
}

export async function importMaterials(basePath: string, selectedMatNames: string[]) : Promise<AppServerResponse<MaterialImportResults>> {
  const resp = await Api.post("/api/material/import", { basePath, materials: selectedMatNames });
  return readServerResponse<MaterialImportResults>("/api/material/import", resp);
}

