import { Box } from '@mui/material';
import React from 'react';
import * as ApiTypes from '../../link/ApiTypes';

type ClientLogoAndNameProps = {
  client?: ApiTypes.Client
}

const ClientLogoAndName: React.FC<ClientLogoAndNameProps> = props => {

  return (
    <Box display='flex' gap={.5} alignItems='center'>
      { props.client &&
        <Box minWidth='1rem' maxHeight='1.5rem' component='img' src={ `/api/client/iconlogo/${props.client?.id}`} />
      }
      { props.client?.name }
    </Box>
  );
};

export default ClientLogoAndName;
