import { User } from '../link/ApiTypes';

  
const ROLE_NAMES = {'ADMIN': 'Administrateur', 'MODELER': 'Modeleur' , 'CLIENT': 'Client' };

/**
 * Generate a user name string from a user object, using its first and last names, and
 * its login if necessary
 * @param user 
 * @returns 
 */
export function userName(user : User) : string {
  let uText = '';
  if (user) {
    uText = [user.firstName, user.lastName].filter(n => n != null).join(' ').trim();
    if (!uText) {
      uText = user.login;
    }
  }
  return uText;
}

/**
 * Returns a user-friendly name for a given role's technical name
 * @param role 
 * @returns 
 */
export function roleToString(role: string) : string {
  if (role in ROLE_NAMES) {
    return (ROLE_NAMES as any)[role];
  }
  return role;
}