import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ClientLogoAndName from '../../../components/common/ClientLogoAndName';
import CodeEditor, { CodeEditorHandle } from '../../../components/common/CodeEditor';
import { FlexRow, FormSnackbarProvider, FormTextField, enqueueSnacks } from '../../../components/common/forms/FormComponents';
import { theme } from '../../../configs/themeOptions';
import * as ApiTypes from '../../../link/ApiTypes';
import * as Api from '../../../link/ClientServerApi';
import { setGlobalProgress } from '../../../redux/features/progressSlice';

type Props = { };

type FormState = {
  blocked: boolean,
  client?: ApiTypes.Client,
  contactFormParams?: ApiTypes.ContactFormParams,
  loadError?: string,
  errorMessage?: string,
  warnings?: string[],
  alertText?: string
};

const ClientContactFormPage = (props: Props) => {

  //URL Parameters
  const { clientId } = useParams();
  let nClientId = parseInt(clientId || '');

  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false});
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);
  const [ expanded, setExpanded ] = React.useState<string | false>(false);

  //Refs
  const htmlSourceEditorRef = React.useRef<CodeEditorHandle>(null);
  const serverValidationEditorRef = React.useRef<CodeEditorHandle>(null);
  const mailSignatureEditorRef = React.useRef<CodeEditorHandle>(null);
  

  //Load
  React.useEffect(() => {
    if (!dataLoaded) {
      if (isNaN(nClientId)) {
        setDataLoaded(true);
        return;
      }
      startOperation(false);
      Api.getClient(nClientId)
        .then(appResp => {
          if (!appResp.ok) {
            endOperation({ ...formState, loadError: appResp.errorMessage && "Erreur au chargement\u00a0: " + appResp.errorMessage });
            enqueueSnacks(undefined, appResp.warnings);
            setDataLoaded(true);
            return;
          }
          if (!appResp.value) {
            endOperation({ ...formState, loadError: "Réponse vide du serveur" });
            setDataLoaded(true);
            return;
          }
          const client = appResp.value;
          Api.getContactFormParams(nClientId)
            .then(appResp => {
              if (!appResp.ok) {
                endOperation({ ...formState, client: client, loadError: "Erreur au chargement\u00a0: " + appResp.errorMessage });
                enqueueSnacks(undefined, appResp.warnings);
                return;
              }
              if (!appResp.value) {
                endOperation({ ...formState, client: client, loadError: "Réponse vide du serveur" });
                return;
              }
              endOperation({ ...formState, client: client, contactFormParams: appResp.value });
            })
            .catch(err => endOperation({ ...formState, client: client, loadError: "Erreur au chargement\u00a0: " +  err }))
            .finally(() => {
              setDataLoaded(true);
            });
        })
        .catch(err => {
          endOperation({ ...formState, loadError: "Erreur au chargement\u00a0: " +  err });
          setDataLoaded(true);
        })
    }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  //Layout
  return (
    <Box component='form' onSubmit={save} onKeyDown={kbHotkeys}>
      <Typography variant='h5'>Formulaire de contact pour le client {formState.client && <> <ClientLogoAndName client={formState.client} /></>}</Typography>

      { formState.loadError && <Alert severity='error' sx={{my: 1}}>{formState.loadError}</Alert> }
      { formState.errorMessage && <Alert severity='error' sx={{my: 1}}>{formState.errorMessage}</Alert> }
      { formState.warnings && formState.warnings.map(warnMessage => <Alert severity='warning'>{warnMessage}</Alert>) }

      <LocalEditorAccordion index={0} name='formHtml' label='Source HTML' codeMode='html' 
                            value={formState.contactFormParams?.formHtml} editorRef={htmlSourceEditorRef}/>
      <LocalEditorAccordion index={1} name='formValidator' label='Code de validation serveur' codeMode='javascript' 
                            value={formState.contactFormParams?.formValidator} editorRef={serverValidationEditorRef}/>
      
      <Accordion expanded={expanded === 'formEmailOptions'} onChange={handleExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel2bh-content`}
          id={`panel2bh-header`}
          sx={{ "& .MuiAccordionSummary-content": {m: 0}, "&.Mui-expanded": { minHeight: '48px' } }}>
          <Typography>Options d'email</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <FlexRow mt={-2}>
              <FormTextField  name="formFrom" label="Expéditeur"
                              maxLength={255} sx={{flexGrow: 1}}
                              helperText="Adresse email d'expéditeur pour les soumissions de contact"
                              value={formState.contactFormParams?.formFrom || ''}
                              onChange={handleInput} />
            </FlexRow>

            <FlexRow mt={1}>
              <FormTextField  name="formSendTo" label="Destinataires"
                              maxLength={1024} sx={{flexGrow: 1}}
                              helperText="Adresse email des destinataires des soumissions de contact"
                              placeholder='Les adresses email doivent être séparées par des points-virgules'
                              value={formState.contactFormParams?.formSendTo || ''}
                              onChange={handleInput} />
            </FlexRow>

            <FlexRow mt={1}>
              <FormTextField  name="formTestSendTo" label="Destinataire en mode test"
                              maxLength={255} sx={{flexGrow: 1, mt: 0}}
                              helperText="Adresse email du destinataire des soumissions de contact en mode test"
                              value={formState.contactFormParams?.formTestSendTo || ''}
                              onChange={handleInput} />
              <FormControlLabel label="Mode Test"
                                control={ <Checkbox checked={formState.contactFormParams?.formTestMode || false} onChange={handleTestModeChange} />} />
            </FlexRow>

            <FlexRow mt={1}>
              <FormTextField  name="formMailSubject" label="Sujet"
                              maxLength={255} sx={{flexGrow: 1}}
                              helperText="Sujet des emails de soumissions de contact"
                              value={formState.contactFormParams?.formMailSubject || ''}
                              onChange={handleInput} />
            </FlexRow>

            <FormControlLabel control={
                              <CodeEditor name='formMailSignature' codeMode='html'
                                          value={formState.contactFormParams?.formMailSignature || ""}
                                          onChange={handleCodeChange}
                                          style={{ minHeight: '5rem' }}
                                          ref={mailSignatureEditorRef}
                                          />
                            }
                            label="Signature des emails" labelPlacement='top'
                            sx={{width: '100%', m: 0, mt: 1, alignItems: 'flex-start', color: theme.palette.grey[600] }} />
          </Box>
        </AccordionDetails>
      </Accordion>
    

      <FlexRow>
        <FormTextField  name="plugin" label="Plugin"
                        fullWidth={true} maxLength={255}
                        helperText="Chemin relatif sur le serveur pour le module de post-traitement des soumissions de contact à partir du dossier des plugins"
                        value={formState.contactFormParams?.pluginFile || ''}
                        onChange={e => setFormState({...formState, contactFormParams: {...formState.contactFormParams || ApiTypes.emptyContactFormParams(), pluginFile: e.target.value} })} />
      </FlexRow>

      <FlexRow>
        <Box flexGrow={1} />
        <Button component={RouterLink} to='..' variant='outlined'>Retour</Button>
        <Button type="submit" variant='contained' color='secondary'
                disabled={formState.blocked || formState.loadError ? true : false}
                startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </FlexRow>

      <LocalAlertDialog />

      <FormSnackbarProvider />

    </Box>
  );


  //Keyboard hotkeys
  function kbHotkeys(e: React.KeyboardEvent) {
    if (e.ctrlKey) {
      switch (e.key) {
        case 's':
          e.preventDefault();
          e.stopPropagation();
          if (!formState.blocked && !formState.loadError) {
            save(e);
          }
          return false;
        }
    }
    else {
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          e.stopPropagation();
          navigate('..');
          return false;
      }
    }
  }


  //Field change handlers


  function handleExpand(event: React.SyntheticEvent<Element, Event>, expanded: boolean) {
    setExpanded(expanded ? 'formEmailOptions' : false);
  }

  function handleCodeChange(value: string, _event: any) {
    const c = {...formState.contactFormParams || ApiTypes.emptyContactFormParams() };
    (c as any)['formMailSignature'] = value;
    formState.contactFormParams = c; //Intentionally mutating formState to avoid focus bug
  }

  function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
    const {name, value} = e.target as HTMLInputElement;
    const cfp = { ...formState.contactFormParams || ApiTypes.emptyContactFormParams() };
    (cfp as any)[name] = value;
    setFormState({ ...formState, contactFormParams: cfp });
  }

  function handleTestModeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormState({
      ...formState, 
      contactFormParams: {
        ...formState.contactFormParams || ApiTypes.emptyContactFormParams(),
        formTestMode: e.target.checked
      }
    });
  }


  //Action handlers
  async function save(e: React.FormEvent) {

    e.preventDefault();

    //Code validation
    const errors = [];
    // nunjucks used in htmlEditor cannot be validated
      // const hsEditor = htmlSourceEditorRef?.current;
      // const hsError = await hsEditor?.checkCode();
      // if (hsError) {
      //   errors.push(hsError);
      // }
    const svEditor = serverValidationEditorRef?.current;
    const svError = await svEditor?.checkCode();
    if (svError) {
      errors.push(svError);
    }
    const msEditor = mailSignatureEditorRef?.current;
    const msError = await msEditor?.checkCode();
    if (msError) {
      errors.push(msError);
    }

    if (errors.length) {
      endOperation({ ...formState, alertText: errors.join('\n') });
      return;
    }

    startOperation();
    Api.saveContactFormParams(formState.client || ApiTypes.emptyClient(),
                                    formState.contactFormParams || ApiTypes.emptyContactFormParams())
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({
            ...formState,
            errorMessage: appResp.errorMessage ? "Erreur à l'enregistrement\u00a0:" + appResp.errorMessage : undefined,
            warnings: appResp.warnings
           });
          return;
        }
        if (!appResp.value) {
          endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0: Réponse vide du serveur" });
          return;
        }
        const savedContactFormParams = appResp.value as ApiTypes.ContactFormParams;
        endOperation({ client: formState.client, contactFormParams: savedContactFormParams });
        enqueueSnackbar('Formulaire de contact enregistré', { variant: "success" });
      })
      .catch(err => endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0:" + err }));
  }

  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, loadError: undefined, errorMessage: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, blocked: false });
  }

  type Props = {
    index: number;
    name: string;
    label: string;
    codeMode: string;
    value?: string;
    editorRef?: React.Ref<CodeEditorHandle>;
  };

  //Local controls

  function LocalEditorAccordion({index, name, label, codeMode, value, editorRef} : Props) {

    return (
      <Accordion expanded={expanded === name} onChange={handleExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
          id={`panel${index}bh-header`}
          sx={{ "& .MuiAccordionSummary-content": {m: 0}, "&.Mui-expanded": { minHeight: '48px' } }}>
          <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mt={-1}>
            <CodeEditor name={name} codeMode={codeMode} value={value} ref={editorRef} onChange={handleChange}/>
          </Box>
        </AccordionDetails>
      </Accordion>
    );

    function handleExpand(event: React.SyntheticEvent<Element, Event>, expanded: boolean) {
      setExpanded(expanded ? name : false);
    }
  
    function handleChange(value: string, _event: any) {
      const c = {...formState.contactFormParams || ApiTypes.emptyContactFormParams() };
      (c as any)[name] = value;
      formState.contactFormParams = c; //Intentionally mutating formState to avoid focus bug
    }
  }

  function LocalAlertDialog(props: { }) : React.ReactElement | null {
    if (formState.alertText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Sauvegarde des paramètres du formulaire de contact"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2} whiteSpace='pre-wrap'>
              {formState.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

}

export default ClientContactFormPage;