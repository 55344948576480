import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, FormControlLabel, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { ModelFloor, SimpleModel } from "../../link/ApiTypes";
import CodeEditor, { CodeEditorHandle } from "../common/CodeEditor";
import { FlexRow, StoredFileWithState, UploadButton, UploadedFile, UploadingFile } from "../common/forms/FormComponents";
import LaunchIcon from '@mui/icons-material/Launch';
import { theme } from '../../configs/themeOptions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export type FloorConfigProps = {
  model?: SimpleModel;
  floorIndex: number;
  floor?: ModelFloor;
  disabled?: boolean;
  onUploadPlan?: (floor: ModelFloor, file: File) => void;
  uploadingPlan?: StoredFileWithState | UploadingFile;
  onFloorChange?: (floor: ModelFloor) => void;
  floorDefinitionEditorRef?: React.RefObject<CodeEditorHandle>;
  floorPartitionsEditorRef?: React.RefObject<CodeEditorHandle>;
  onDeletePlan?: (floor: ModelFloor) => void;
};

export function FloorConfig(props: FloorConfigProps) : React.JSX.Element | null {

  if (!props.floor)
    return null;
  console.log('FloorConfig', props.floor);
  const domain = props.model?.client?.domainName ? encodeURIComponent(props.model.client.domainName) : null;
  const modelName = props.model?.name ? encodeURIComponent(props.model.name) : null;
  const partitionsDesignUrl = domain && modelName && props.floorIndex >= 0
    ? new URL(`/dev/${domain}/cloisons/${modelName}/${props.floorIndex}`, window.location.href)
    : null;
  return (
    <Box>
      {
        props.uploadingPlan
        ? <Box mt={1}><UploadedFile value={props.uploadingPlan} /></Box>
        : <FlexRow alignItems='center' sx={{gap:5}}>
            <Typography variant='subtitle1' fontWeight='bold'>Plan&nbsp;:</Typography>
            {
              props.floor.plan 
              ? <FlexRow mt={0} sx={{gap:1}}>
                  <Typography variant='body1'>{props.floor.plan.filename}</Typography>
                  <IconButton color='secondary' size='small' sx={{padding:0}}
                              onClick={handleClick}
                              disabled={props.disabled}>
                    <DeleteForeverIcon />
                  </IconButton>
                </FlexRow>
              : <Typography variant='body1' fontStyle='italic' color={theme.palette.grey[500]}> (Plan non défini)</Typography>
            }
            
            <UploadButton name='icon' variant='contained' startIcon={<CloudUploadIcon />}
                          disabled={props.disabled} accept='.svg'
                          title="Charger un fichier de plan d'étage SVG"
                          onChange={e => {
                            if (e.target.files && e.target.files.length > 0 && props.floor)
                              props.onUploadPlan?.(props.floor, e.target.files[0]); 
                          }}>
              Charger fichier
            </UploadButton>
          </FlexRow>
      }
      <FlexRow>
        <Box flexGrow={1}>
          <FormControlLabel control={
                              <CodeEditor name='definition' codeMode='javascript'
                                          value={props.floor?.definition || ""}
                                          onChange={value => {
                                            if (props.floor)
                                              props.floor.definition = value;
                                          }}
                                          onBlur={e => {
                                            if (props.floor)
                                              props.onFloorChange?.(props.floor);
                                          }}
                                          style={{height: '10rem'}}
                                          ref={props.floorDefinitionEditorRef}
                                          codeValidationPrefix='const a = '
                                          />
                            }
                            label="Options" labelPlacement='top'
                            sx={{width: '100%',  m:0}} />
        </Box>
        <Box flexGrow={1}>
          <FormControlLabel control={
                              <CodeEditor name='partitions' codeMode='json'
                                          value={jsonOrString(props.floor?.partitions)}
                                          onChange={value => {
                                            if (props.floor)
                                              props.floor.partitions = value;
                                          }}
                                          onBlur={e => {
                                            if (props.floor)
                                              props.onFloorChange?.(props.floor);
                                          }}
                                          style={{height: '10rem'}}
                                          ref={props.floorPartitionsEditorRef}
                                          />
                            }
                            label={
                              partitionsDesignUrl
                              ? <>
                                Cloisons
                                <Typography component='a' target="__partitions_editor" href={partitionsDesignUrl.href} ml={.5}>
                                  Éditeur
                                  <LaunchIcon fontSize='small' sx={{display: 'inline-block', verticalAlign: 'text-bottom', ml: .5}} />
                                </Typography>
                              </> 
                              : "Cloisons"
                            }
                            labelPlacement='top'sx={{width: '100%',  m:0}} />
        </Box>
      </FlexRow>
    </Box>
  );

  function jsonOrString(value?: any) {
    if (!value)
      return '';
    return typeof value === 'string' ? value : JSON.stringify(value, undefined, 2);
  }

  function handleClick() {
    if (!props.onDeletePlan) return;
    if (props.floor?.plan) 
      props.onDeletePlan(props.floor);
  }

}