import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RouteDef } from "../../../routes/appRoutes";
import { RootState } from "../../../redux/store";
import { LoginState } from "../../../redux/features/loginSlice";

type Props = {
  item: RouteDef
}

/**
 * A menu item for a given {@link RouteDef} to be displayed the {@link Sidebar}.
 * Represents a collapsible item with submenu
 * @param param
 * @returns 
 */
const SidebarItemCollapse = ({ item }: Props) => {
  //States
  const [open, setOpen] = useState(false);
  const { menuState } = useSelector((state : RootState) => state.menuPage);
  const { user } = useSelector((state: {login: LoginState}) => state.login);

  //Rendered state
  useEffect(() => {
    if (menuState && menuState.menu && menuState.menu.includes(item.state.menu)) {
      setOpen(true);
    }
  }, [menuState, item]);

  //Layout
  return (
    item.state && item.state.sidebarProps ? (
      <>
        <ListItemButton
          onClick={() => setOpen(!open)}
          sx={{
            "&: hover": {
              backgroundColor: 'action.hover'
            },
          }}>
          <ListItemIcon sx={{ minWidth: '2.5em', color: 'inherit' }}>
            {item.icon && item.icon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography>
                {item.state.sidebarProps.displayText}
              </Typography>
            }
          />
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto">
          <List>
            {item.child?.map((itemChild, index) => {
              if (!itemChild.state || !itemChild.state.sidebarProps) {
                return null;
              }
              if (itemChild.state.sidebarProps.roles) {
                if (!user)
                  return null;
                if (!itemChild.state.sidebarProps.roles.some(ic => user.roles.includes(ic)))
                  return null;
              }
            return (
                itemChild.child ? (
                  <SidebarItemCollapse item={itemChild} key={index} />
                ) : (
                  <SidebarItem item={itemChild} key={index} />
                )
                );
              })}
          </List>
        </Collapse>
      </>
    ) : null
  );
};

export default SidebarItemCollapse;