import SaveIcon from "@mui/icons-material/Save";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, LinearProgress, Switch, Typography } from "@mui/material";
import { GridDeleteIcon } from "@mui/x-data-grid";
import React, { ChangeEvent, MouseEventHandler } from "react";
import * as ApiTypes from '../../link/ApiTypes';
import * as UsersApi from "../../link/UserServerApi";
import { UserInfo, UserInfoFields } from "../userpage/UserInfo";
import ClientLogoAndName from "../common/ClientLogoAndName";

type Props = {
  client?: ApiTypes.Client,
  userId?: string|number,
  onOperationMessage?: (success?: string, warnings?: string[], error?: string) => void,
  onClose?: (reloadRequired: boolean) => void,
}

type FormState = { userInfo?: UserInfoFields, loading: boolean, loadError?: string, errorMessage?: string, warnings?: string[] };

export function UserDialog({ client, userId, onOperationMessage, onClose } : Props) {

  //States
  const initState: FormState = { loading: true };
  const [ formState, setFormState ] = React.useState( initState );
  const [ deleteConfirmText, setDeleteConfirmText ] = React.useState<string>();
  const [ progressValue, setProgressValue ] = React.useState<number>(0);

  //Load
  React.useEffect(() => {
    if (!userId)
      return;
    if (userId === 'new') {
      setFormState({...formState, loading: false, userInfo: { user: ApiTypes.emptyUser() }});
    } else {
      startOperation();
      UsersApi.getUser(userId)
        .then((appResp) => {
          if (!appResp.ok) {
            endOperation({ loadError: "Erreur au chargement\u00a0:" + appResp.errorMessage });
            return;
          }
          if (!appResp.value) {
            endOperation({ loadError: "Erreur au chargement\u00a0: Réponse vide du serveur" });
            return;
          }
          endOperation({ userInfo: { user: appResp.value } });
        })
        .catch(err => endOperation({ ...initState, loadError: "Erreur au chargement\u00a0:" + err }));
    }
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ userId ]);


  //Layout
  
  return (
    <Dialog open={userId && client ? true : false} className='client-user-dialog'>
      {(userId && client ? true : false) &&
        <Box component='form' onSubmit={save} className="client-user-form">
          <DialogTitle>{ formState.userInfo?.user?.id ? `Modifier l'utilisateur ${formState.userInfo?.user?.login}` : "Nouvel utilisateur" }</DialogTitle>
          <DialogContent>
            { formState.loadError && <Alert severity='error'>{formState.loadError}</Alert> }
            { formState.errorMessage && <Alert severity='error'>{formState.errorMessage}</Alert> }
            { formState.warnings && formState.warnings.map(warnMessage => <Alert severity='warning'>{warnMessage}</Alert>) }
            <Box display='flex' justifyContent='space-between'>
              <Typography pt={1} display='flex' alignItems='center'>Client&nbsp;:&nbsp;<ClientLogoAndName client={client} /></Typography>
              <FormControlLabel
                control={ <Switch name="active" checked={formState.userInfo?.user?.active || false} onChange={handleCheck} /> }
                label="Actif" labelPlacement='start' />
            </Box>
            <UserInfo userInfo={formState.userInfo || {user: ApiTypes.emptyUser()}} onUserInfoChanged={userInfo => setFormState({ ...formState, userInfo: userInfo })} />
          </DialogContent>
          <DialogActions sx={{display: 'flex', position: 'relative'}}>
            <LinearProgress value={progressValue >= 0 ? progressValue : 0}
                            variant={progressValue < 0 ? 'indeterminate' : 'determinate'}
                            sx={{ position: 'absolute', inset: '0 0 auto 0', visibility: progressValue === 0 ? 'hidden' : 'visible' }} />
            <Button variant='contained' color='primary' onClick={confirmDelete}  hidden={!formState.userInfo?.user?.id}
                  disabled={!formState.userInfo?.user?.id || formState.loading || formState.loadError ? true : false}>
              <GridDeleteIcon sx={{fontSize: '1.2em', marginRight: '.25em'}} />
              Supprimer
            </Button>
            <Box flexGrow={1} />
            <Button variant='outlined' onClick={e => { if (onClose) onClose(false); }}>Annuler</Button>
            <Button type="submit" variant='contained' color='secondary'
                    disabled={formState.loading || formState.loadError ? true : false}>
              <SaveIcon sx={{fontSize: '1.2em', marginRight: '.25em'}} />
              Enregistrer
            </Button>
          </DialogActions>
          <LocalDeleteConfirmDialog onConfirm={deleteCurrent} />
        </Box>
      }
    </Dialog>
  );
  
  //Field change handlers

  function handleCheck(e: ChangeEvent) {
    const {name, checked} = e.target as HTMLInputElement;
    const ui = { ...formState.userInfo || { user: ApiTypes.emptyUser() } };
    (ui.user as any)[name] = !!checked;
    setFormState({ ...formState, userInfo: ui });
  }//Action event handlers

  function confirmDelete(event: React.MouseEvent) {
      setDeleteConfirmText(`Supprimer l'utilisateur\u00a0?`);
  }

  function deleteCurrent(event: React.MouseEvent) {
    console.log("Delete", formState.userInfo?.user);
    if (!formState.userInfo?.user?.id) {
      endOperation();
      statusMessages(undefined, ['Pas d\'utilisateur connu en base de données']);
      return;
    }

    startOperation();
    UsersApi.deleteUser(formState.userInfo.user.id)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          statusMessages(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value && appResp.value !== false) {
          endOperation();
          statusMessages(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          setProgressValue(0);
          statusMessages('Utilisateur supprimé');
          if (onClose) onClose(true);
        } else {
          endOperation();
          statusMessages('L\'utilisateur n\'a pas été supprimé');
        }
      })
      .catch(err => {
        endOperation();
        statusMessages(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }
  
  function save(e: React.FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (formState.userInfo?.password1) {
      if (formState.userInfo?.password2 !== formState.userInfo?.password1) {
        setFormState({...formState, warnings: ['Les mots de passe ne correspondent pas']});
        return;
      }
    }

    startOperation();
    UsersApi.saveUser({
      ...formState.userInfo?.user || ApiTypes.emptyUser(),
      clientId: client?.id,
      password1: formState.userInfo?.password1,
      password2: formState.userInfo?.password2,
    })
    .then((appResp) => {
      if (!appResp.ok) {
        endOperation({
          ...formState,
          errorMessage: appResp.errorMessage ? "Erreur à l'enregistrement\u00a0:" + appResp.errorMessage : undefined,
          warnings: appResp.warnings
         });
        return;
      }
      if (!appResp.value) {
        endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0: Réponse vide du serveur" });
        return;
      }
      const savedUser = appResp.value as ApiTypes.User;
      endOperation({ userInfo: { user: savedUser } });
      statusMessages('Utilisateur enregistré');
      if (onClose) onClose(true);
    })
    .catch(err => endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0:" + err }));
  }


  //private utility functions
  function startOperation() {
    setProgressValue(-1);
    setFormState({ ...formState, loading: true, errorMessage: undefined, loadError: undefined, warnings: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    setProgressValue(0);
    setFormState({ ...newFormState, loading: false });
  }

  function statusMessages(success?: string, warnings?: string[], error?: string) {
    if (onOperationMessage) onOperationMessage(success, warnings, error);
  }



  //Inner components

  function LocalDeleteConfirmDialog(props: {onConfirm?: MouseEventHandler}) : React.ReactElement | null {
    if (deleteConfirmText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Suppression d'utilisateur"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={3}>
              {deleteConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setDeleteConfirmText(undefined)} autoFocus>Annuler</Button>
            <Button onClick={e => {
                setDeleteConfirmText(undefined);
                props.onConfirm && props.onConfirm(e);
              }}
              variant='contained' color='secondary'
              >
                OK
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }
}