import { Moment } from 'moment';


export type User = {
  id: number;
  created: string;
  login: string;
  firstName?: string;
  lastName?: string;
  roles: string[];
  active: boolean;
  lastLogin?: string;
  client?: Client;
};

export function emptyUser(): User { return { id: 0, created: '', login: '', active: true, lastLogin: '', roles: [] }; }

export type UserFormData = {
  id: number;
  clientId?: number;
  login: string;
  firstName?: string;
  lastName?: string;
  roles: string[];
  active: boolean;
  password1?: string;
  password2?: string;
};

export type Client = {
  id: number;
  created: string;
  name: string;
  contactName?: string;
  active: boolean;
  apiKey?: string;
  apiKeyExpires?: Moment | null;
  domainName?: string;
  formSendTo?: string;
  formTestMode: boolean;
  formTestSendTo?: string;
  logo?: StoredFile;
};
export function emptyClient(): Client { return { id: 0, created: '', name: '', formTestMode: true, active: true }; }
;

export type ClientCustomization = {
  id: number;
  created: string;
  css?: string;
  js?: string;
  logo?: StoredFile;
  overlaylogo?: StoredFile;
  favicon?: StoredFile;
  files: StoredFile[];
};
export function emptyClientCustomization(): ClientCustomization { return { id: 0, created: '', files: [] }; }

export type ContactFormParams = {
  id: number;
  created: string;
  formHtml?: string;
  formValidator?: string;
  formFrom?: string;
  formSendTo?: string;
  formTestSendTo?: string;
  formTestMode?: boolean;
  formMailSubject?: string;
  formMailSignature?: string;
  pluginFile?: string;
};
export function emptyContactFormParams(): ContactFormParams { return { id: 0, created: '' }; }

export type DeleteManyResult = { rejections: [{ id: number; warning?: string; error?: string; }]; deleted: number; };

export type DeleteManyByNameResult = { rejections: [{ name: string; warning?: string; error?: string; }]; deleted: number; };

export type StoredFile = {
  id: number;
  created: string;
  filename: string;
  mimeType: string;
  temp: boolean;
};

export type AppServerResponse<T> = {
  ok: boolean;
  httpStatus: number;
  errorMessage?: string;
  warnings?: string[];
  value?: T;
};

export type Material = {
  name: string;
  created: string;
  definition: string;
  priority: number;
};

export type MaterialWithRefCount = Material & {
  refCount: number;
};

export type SimpleModel = {
  id: number;
  created: string;
  name: string;
  displayName?: string;
  client?: Client;
  hash?: string;
};

export type ModelWithMatCount = SimpleModel & {
  matsCount: number;
  unknownMatsCount: number;
}

export type DetailedSimpleModel = SimpleModel & {
  geometry?: StoredFile;
  envConfig?: string;
  optionsConfig?: string;
  otherFeatures?: string;
};

export type DetailedSimpleModelWithMatCount = DetailedSimpleModel & {
  matsCount?: number;
  unknownMatsCount?: number;
}

export function emptyModel(): DetailedSimpleModelWithMatCount { return { id: 0, created: '', name: ''}; }

export type ModelInterior = SimpleModel & {
  interiorConfig?: string;
  floorPlans: ModelFloor[];
};

export type ModelFloor ={
  id: number;
  floor?: number;
  name: string;
  definition?: string;
  partitions?: string|object;
  plan?: StoredFile;
};

export type CheckedMaterials = {
  [name: string]: {
    found: boolean;
    material?: Material;
    reason?: 'changed' | 'not-found';
  };
};

export type UploadMaterialResult = {
  basePath: string;
  materials: CheckedMaterials;
};

type TextureResults = {
  [name: string]: {
    knownFile: boolean;
    file: StoredFile;
    added: boolean;
  };
};

export type MaterialImportResult = {
  saved: boolean;
  material?: Material;
  reason?: string;
  textures?: TextureResults;
};

export type MaterialImportResults = {
    [name: string]: MaterialImportResult;
}

export type UploadModelResult = {
  materials: string[];
  tempFile: StoredFile;
};

export type ModelRequest = {
  id: number;
  host?: string;
  userAgent?: string;
  url?: string;
  referer?: string;
  sessionId?: string;
  modelName?: string;
  client?: Client;
};

export type ModelFormSubmit = ModelRequest & {
  formData: any;
  uniqueId: string;
};

export type ConversionsData = {
  date : Date;
  client: Client;
  modelName: string;
  reqCount: number;
  formCount: number;
  conversionRate: number;
}

export type ConversionsFilterQuery = {
  clientId?: number;
  modelName?: string;
  startDate?: Date;
  endDate?: Date;
}

