import { createSlice } from "@reduxjs/toolkit";

export type ProgressState = {
  globalProgress: number
}

const initialState : ProgressState = {
  globalProgress: 0
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setGlobalProgress: (state, action) => {
      console.log('setValue', action)
      state.globalProgress = action.payload;
    }
  }
});

export const {
  setGlobalProgress
} = progressSlice.actions;

export default progressSlice.reducer;