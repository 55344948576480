export class ServerError extends Error {}
export class AccessDeniedError extends ServerError {}
export class UnauthenticatedError extends ServerError {}

type Callbacks = {
    unauthenticated?: (e: UnauthenticatedError) => void
}

const errorCallbacks : Callbacks = {
    unauthenticated: undefined,
};

/**
 * Perform a GET request on the server API. Expects a JSON-formatted response
 * @param url URL to be queried
 * @param queryParms Request parameters to be passed in URL
 * @returns 
 */
export async function get(url: string, queryParms?: string | Record<string, string> | URLSearchParams | string[][] | undefined) : Promise<Response> {

    let queryString = '';
    if (queryParms) {
        queryString = '?' + new URLSearchParams(queryParms);
    }
    const resp = await fetch(url + queryString, {
        method: 'get',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });

    await throwAccessDeniedIf403(resp);
    await registerLoggedOutIf401(resp);

    return resp;
}

/**
 * Perform a POST request on the server API. Expects a JSON-formatted response
 * @param url URL to be queried
 * @param body Object to be passed as request body, encoded into JSON
 * @returns 
 */
export async function post(url: string, body?: object) : Promise<Response> {
    const resp = await fetch(url, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            credentials: 'include'
        });

    await throwAccessDeniedIf403(resp);
    await registerLoggedOutIf401(resp);

    return resp;
}

/**
 * Perform a DELETE request on the server API. Expects a JSON-formatted response
 * @param url URL to be queried
 * @param body Object to be passed as request body, encoded into JSON
 * @returns 
 */
export async function del(url: string, body?: object) : Promise<Response> {
    const resp = await fetch(url, {
            method: 'delete',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            credentials: 'include'
        });

    await throwAccessDeniedIf403(resp);
    await registerLoggedOutIf401(resp);

    return resp;
}

/**
 * Perform a PUT request on the server API to upload a file. Expects a JSON-formatted response
 * @param url URL to be queried
 * @param file File to be uploaded as multipart/formdata
 * @param formData Additional form data to be passed along with the file
 * @returns 
 */
export async function putFile(url: string, file: File, formData?: {[key: string]: any}) : Promise<Response> {
    const data = new FormData();
    data.append('file', file);
    for (const key in formData) {
        data.append(key, formData[key]);
    }
    const resp = await fetch(url, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
            },
            body: data,
            credentials: 'include'
        });

    await throwAccessDeniedIf403(resp);
    await registerLoggedOutIf401(resp);

    return resp;
}

/**
 * If response in 403 HTTP status, then throw an {@link AccessDeniedError} with formatted message
 * @param resp HTTP response to bve analyzed
 */
async function throwAccessDeniedIf403(resp : Response) {
    if (resp.status === 403) {
        const respText = await resp.text();
        const messParts = [`Accès refusé par le serveur: ${resp.status} ${resp.statusText}`];
        if (respText)
            messParts.push(respText);
        throw new AccessDeniedError(messParts.join(' - '));
    }
}

/**
 * If response in 403 HTTP status, then throw an {@link UnauthenticatedError} with formatted message,
 * but right before that, call {@link errorCallbacks.unauthenticated}
 * @param resp HTTP response to bve analyzed
 */
async function registerLoggedOutIf401(resp: Response) {
    if (resp.status === 401) {
        const respText = await resp.text();
        const messParts = [`Accès refusé par le serveur: ${resp.status} ${resp.statusText}`];
        if (respText)
            messParts.push(respText);
        const err = new UnauthenticatedError(messParts.join(' - '));
        if (errorCallbacks.unauthenticated) {
            try {
                errorCallbacks.unauthenticated(err);
            } catch (err) {
                console.error(err);
            }
        }
        throw err;
    }
}

/**
 * 
 * @param callback to be called right before throwing an 
 */
export function setUnauthenticatedCallback(callback: (e: UnauthenticatedError) => void) {
    errorCallbacks.unauthenticated = callback;
}