import { AddCircle as AddCircleIcon, Edit as EditIcon } from '@mui/icons-material';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridDeleteIcon, GridRowId } from '@mui/x-data-grid';
import moment from 'moment';
import { closeSnackbar } from 'notistack';
import React, { MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { UserDialog } from '../../../components/clientpage/UserDialog';
import { FlexRow, FormSnackbarProvider, enqueueSnacks } from '../../../components/common/forms/FormComponents';
import * as ApiTypes from '../../../link/ApiTypes';
import * as Api from '../../../link/ClientServerApi';
import * as UsersApi from '../../../link/UserServerApi';
import { setGlobalProgress } from '../../../redux/features/progressSlice';
import ClientLogoAndName from '../../../components/common/ClientLogoAndName';

type Props = { };

type FormState = {
  blocked: boolean,
  client?: ApiTypes.Client,
  users?: ApiTypes.User[],
  selectedRows?: GridRowId[],
  loadError?: string,
  alertText?: string,
  deleteConfirmText?: string
};

const ClientUsersPage = (props : Props) => {
  //URL Parameters
  const { clientId } = useParams();
  let nClientId = parseInt(clientId || '');

  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false});
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);
  const [ editUserId, setEditUserId ] = React.useState<number|string|undefined>();
  //Load
  React.useEffect(() => {
    if (!dataLoaded) {
      if (isNaN(nClientId)) {
        setDataLoaded(true);
        return;
      }
      startOperation(false);
      Api.getClient(nClientId)
        .then(appResp => {
          if (!appResp.ok) {
            endOperation({ ...formState, loadError: appResp.errorMessage && "Erreur au chargement\u00a0: " + appResp.errorMessage });
            enqueueSnacks(undefined, appResp.warnings);
            setDataLoaded(true);
          }
          if (!appResp.value) {
            endOperation({ ...formState, loadError: "Réponse vide du serveur" });
            setDataLoaded(true);
            return;
          }
          const client = appResp.value;
          Api.getClientUsers(nClientId)
            .then(appResp => {
              if (!appResp.ok) {
                endOperation({ ...formState, client: client, loadError: "Erreur au chargement\u00a0: " + appResp.errorMessage });
                enqueueSnacks(undefined, appResp.warnings);
                return;
              }
              if (!appResp.value) {
                endOperation({ ...formState, client: client, loadError: "Réponse vide du serveur" });
                return;
              }
              endOperation({ ...formState, client: client, users: appResp.value });
            })
            .catch(err => endOperation({ ...formState, client: client, loadError: "Erreur au chargement\u00a0: " +  err }))
            .finally(() => { 
              setDataLoaded(true);
            });
        })
        .catch(err => {
          endOperation({ ...formState, loadError: "Erreur au chargement\u00a0: " +  err });
          setDataLoaded(true);
        })
    }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);



  //Grid Columns
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 40 },
    { 
      field: 'login',
      headerName: 'login',
      width: 150,
      renderCell: (params) => (
        <Link onClick={e => setEditUserId(params.row.id)}>{params.value}</Link>
      )
    },
    { 
      field: 'fullName',
      headerName: 'Nom',
      width: 200,
      valueGetter: params => {
        const arr = [params.row.firstName, params.row.lastName]
          .map(s => s && s.trim())
          .filter(s => s && s.length);
        return arr.join(' ');
      }
    },
    { field: 'active', type: 'boolean', headerName: 'Actif', width: 40 },
    {
      field: 'lastLogin',
      type:'dateTime',
      headerName: 'Dernière co.',
      width: 180,
      valueGetter: params => {
        if (!params.value) return null;
        return moment(params.value).toDate();
      }
    },
  ];

  //Layout
  return (
    <Container maxWidth='md'>
      <Typography variant='h5'>Utilisateurs du client{formState.client && <> <ClientLogoAndName client={formState.client} /></>}</Typography>
      {formState.loadError && <Alert severity='error'>{formState.loadError}</Alert>}
      <DataGrid
        rows={formState.users || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]} //No more than 100 in MIT version
        checkboxSelection
        loading={formState.blocked}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        rowSelectionModel={formState.selectedRows}
        onRowSelectionModelChange={ (selectionModel, _details) => setFormState({...formState, selectedRows: selectionModel}) }
        onRowClick={ (params, _event, _details) => setFormState({...formState, selectedRows: [params.id]}) }
        onRowDoubleClick={ (params, _event, _details) => setEditUserId(params.row.id) }
      />
      <Box>
        <FlexRow>
          <Button variant='contained' color='primary' onClick={confirmDeleteSelected}
                  disabled={!formState.selectedRows?.length}
                  startIcon={<GridDeleteIcon />}>
            Supprimer
          </Button>
          <Box flexGrow={1} />
          <Button variant='outlined' color='primary' onClick={e => navigate('..')}>Retour</Button>
          <Button variant='contained' color='secondary' onClick={editSelected}
                  disabled={!formState.selectedRows?.length}
                  startIcon={<EditIcon />}>
            Modifier
          </Button>
        </FlexRow>
        <FlexRow sx={{justifyContent: 'flex-end' }}>
          <Button variant='outlined' color='secondary' onClick={createNew}><AddCircleIcon sx={{fontSize: '1.2em', marginRight: '.25em'}} /> Nouveau</Button>
        </FlexRow>
      </Box>

      <LocalAlertDialog />

      <LocalDeleteConfirmDialog onConfirm={deleteSelected} />

      <UserDialog client={formState.client} userId={editUserId} onClose={ userClosed } onOperationMessage={enqueueSnacks} />

      <FormSnackbarProvider />
    </Container>
  );


  

  //Action handlers

  function editSelected(event: React.MouseEvent) {
    if (formState.selectedRows?.length) {
      if (formState.selectedRows.length > 1) {
        setFormState({ ...formState, alertText: "Un seul utilisateur peut être modifié à la fois" });
      } else {
        setEditUserId(formState.selectedRows[0]);
      }
    }
  }

  function createNew(event: React.MouseEvent) {
    setEditUserId('new');
  }

  function confirmDeleteSelected(event: React.MouseEvent) {
    if (formState.selectedRows?.length) {
      if (formState.selectedRows.length > 1) {
        setFormState({ ...formState, deleteConfirmText: `Supprimer les ${formState.selectedRows.length} utilisateurs sélectionnés\u00a0?` });
      } else {
        const uid = formState.selectedRows[0];
        const user = formState.users?.find(u => u.id === uid);
        setFormState({ ...formState, deleteConfirmText: `Supprimer l'utilisateur ${user?.login}\u00a0?` });
      }
    }
  }

  function deleteSelected(event: React.MouseEvent) {
    if (!formState.selectedRows?.length) {
      enqueueSnacks(undefined, ['Aucune sélection']);
      return;
    }
    if (formState.selectedRows?.length !== 1) {
      deleteMany(formState.selectedRows);
      return;
    }
    deleteOne(formState.selectedRows[0]);
  }

  function userClosed(reloadRequired: boolean) {
    setEditUserId(undefined);
    if (reloadRequired)
      setDataLoaded(false);
  }

  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, deleteConfirmText: undefined, loadError: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, deleteConfirmText: undefined, blocked: false });
  }

  function deleteMany(ids: GridRowId[]) {
    console.log("Delete", ids);

    startOperation();
    UsersApi.deleteUsers(ids.map(id => id as number))
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          endOperation();
          const success = appResp.value.deleted ? `${appResp.value.deleted} utilisateurs supprimés` : undefined;
          const warnings = appResp.value.rejections.map(rej => {
            const u = formState.users?.find(u => u.id === rej.id);
            const uName = u ? u.login : rej.id;
            if (rej.error)
              return `${uName} - Erreur: ${rej.error}`;
            else
              return `${uName} - ${rej.warning}`;
          });
          const error = appResp.value.deleted ? undefined : "Aucun utilisateur supprimé";
          enqueueSnacks(success, warnings, error);
          setDataLoaded(false);
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }

  function deleteOne(id: GridRowId) {
    const user = formState.users?.find(u => u.id === id);
    console.log("Delete", user);
    if (!user) {
      endOperation();
      enqueueSnacks(undefined, ['Utilisateur introuvable']);
      return;
    }

    startOperation();
    UsersApi.deleteUser(user.id)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value && appResp.value !== false) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          endOperation({ ...formState, users: formState.users?.filter(u => u.id !== id) });
          enqueueSnacks('Utilisateur supprimé');
        } else {
          endOperation();
          enqueueSnacks('L\'utilisateur n\'a pas été supprimé');
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }



  //Inner components

  function LocalAlertDialog(props: { }) : React.ReactElement | null {
    if (formState.alertText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Gestion des utilisateurs"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  function LocalDeleteConfirmDialog(props: {onConfirm?: MouseEventHandler}) : React.ReactElement | null {
    if (formState.deleteConfirmText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Suppression d'utilisateurs"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.deleteConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, deleteConfirmText: undefined })} autoFocus>Annuler</Button>
            <Button onClick={e => {
                props.onConfirm && props.onConfirm(e);
              }}
              variant='contained' color='secondary'
              >
                OK
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }

};

export default ClientUsersPage;