import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect } from 'react';
import * as ApiTypes from '../../link/ApiTypes';
import * as Api from '../../link/ClientServerApi';
import { enqueueSnacks } from './forms/FormComponents';

type ClientAutoCompleteProps = TextFieldProps & {
  value?: ApiTypes.Client,
  onClientChoice: (client?: ApiTypes.Client) => void,
  singleClient?: boolean
}


const ClientAutoComplete: React.FC<ClientAutoCompleteProps> = props => {
  const [ loading, setLoading ] = React.useState<boolean>(true);
  const [ clients, setClients ] = React.useState<ApiTypes.Client[]>([]);
  const [ clientChoices, setClientChoices ] = React.useState<ApiTypes.Client[]>([]);

  useEffect(() => {
    //Use client from props
    if (props.singleClient) {
      if (props.value) {
        setClients([props.value]);
        setClientChoices([props.value]);
        if (props.onClientChoice) {
          props.onClientChoice(props.value);
        }
      }
      setLoading(false);
      return;
    }
    //Load from API
    //Catch to snackbar
    Api.getAllClients()
    .then(appResp => {
      if (!appResp.ok) {
        enqueueSnacks(undefined, appResp.warnings?.map(w => "Chargement des clients\u00a0:\n" + w));
        return;
      }
      let clients = appResp.value;
      if (!clients) {
        enqueueSnacks(undefined, undefined, "Chargement des clients\u00a0:\nRéponse vide du serveur");
        clients = [];
      }
      clients.sort((a, b) => b.created.localeCompare(a.created));
      setClients(clients);
      setClientChoices(clients.slice(0, 20));
    })
    .catch(err => {
      enqueueSnacks(undefined, undefined, "Chargement des clients\u00a0:\nErreur au chargement\u00a0: " +  err );
    }).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = React.useCallback((event: React.SyntheticEvent, value: string, _reason: string) => {
    const filteredClients = !value
      ? clients
      : clients.filter(client => client.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())).sort((a, b) => a.created.localeCompare(b.created));
    setClientChoices(filteredClients.slice(0, 20));
  }, [clients, setClientChoices]);

  const textFieldProps = {...props, id: ''};
  delete (textFieldProps as any).onClientChoice; //Remove the custom event handler to avoid compiler warning
  delete (textFieldProps as any).singleClient; //Remove the custom property to avoid compiler warning

  return (
    <Autocomplete
      id={props.id || "client-autocomplete"}
      options={clientChoices}
      getOptionLabel={(option) => option.name}
      value={props.value || null}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onChange={onClientChoiceChange}
      disabled={props.disabled}
      renderInput={(params) => <TextField {...{...params, ...textFieldProps, InputProps: {...params.InputProps, ...textFieldProps.InputProps } } } />}
      onInputChange={onInputChange}
      style={{ ...props.style }}
      loadingText="Chargement…"
      loading={loading}
      autoHighlight={true} //Pre-selects closest value
      autoSelect={true} //Selects highlighted value on blur
      autoComplete={true} //Autocompletes the text field when a choice is selected
      clearOnEscape={true} //ESC clears the text field
    />
  );

  function onClientChoiceChange(event: React.SyntheticEvent<Element, Event>, value: ApiTypes.Client | null, _reason: AutocompleteChangeReason, _details?: AutocompleteChangeDetails<ApiTypes.Client>) {
    if (props.onClientChoice) {
      props.onClientChoice(value || undefined);
    }
  }

};

export default ClientAutoComplete;
