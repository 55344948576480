import { AddCircle as AddCircleIcon, Edit as EditIcon } from '@mui/icons-material';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridDeleteIcon, GridRowId } from '@mui/x-data-grid';
import moment from 'moment';
import { closeSnackbar } from 'notistack';
import React, { MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ClientLogoAndName from '../../components/common/ClientLogoAndName';
import { FlexRow, FormSnackbarProvider, enqueueSnacks } from '../../components/common/forms/FormComponents';
import * as ApiTypes from '../../link/ApiTypes';
import * as Api from "../../link/UserServerApi";
import { setGlobalProgress } from '../../redux/features/progressSlice';
import { roleToString } from '../../util/DataUtil';

type Props = { };

type FormState = {
  blocked: boolean,
  users?: ApiTypes.User[],
  selectedRows?: GridRowId[],
  loadError?: string,
  alertText?: string,
  deleteConfirmText?: string
};

const UsersPage = (props : Props) => {
  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false});
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);

  //Load
  React.useEffect(() => {
    if (!dataLoaded ) {
      startOperation(false);
      Api.getAllUsers()
        .then(appResp => {
          if (!appResp.ok) {
            endOperation({ ...formState, loadError: appResp.errorMessage && "Erreur au chargement\u00a0: " + appResp.errorMessage });
            enqueueSnacks(undefined, appResp.warnings);
            return;
          }
          if (!appResp.value) {
            endOperation({ ...formState, loadError: "Réponse vide du serveur" });
            return;
          }
          endOperation({ ...formState, users: appResp.value });
        })
        .catch(err => endOperation({ ...formState, loadError: "Erreur au chargement\u00a0: " +  err }))
        .finally(() => {
          setDataLoaded(true);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);



  //Grid Columns
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 40 },
    { 
      field: 'login',
      headerName: 'login',
      width: 150,
      renderCell: (params) => (
        <Link component={RouterLink} to={`${params.row.id}`}>{params.value}</Link>
      )
    },
    { 
      field: 'fullName',
      headerName: 'Nom',
      width: 200,
      valueGetter: params => {
        const arr = [params.row.firstName, params.row.lastName]
          .map(s => s && s.trim())
          .filter(s => s && s.length);
        return arr.join(' ');
      }
    },
    {
      field: 'role',
      headerName: 'Rôles',
      width: 150,
      description: 'Liste des rôles (non-triable)',
      sortable: false,
      renderCell: (params) => (
        <>
          {(params.row.roles as string[]).map(rv => roleToString(rv)).join(', ')}
          {params.row.client && params.row.roles?.length && <>&nbsp;:&nbsp;</>}
          {params.row.client && <ClientLogoAndName client={params.row.client} />}
        </>
      ),
    },
    { field: 'active', type: 'boolean', headerName: 'Actif', width: 40 },
    {
      field: 'lastLogin',
      type:'dateTime',
      headerName: 'Dernière co.',
      width: 180,
      valueGetter: params => {
        if (!params.value) return null;
        return moment(params.value).toDate();
      }
    },
  ];

  //Layout
  return (
    <Container maxWidth='md'>
      <Typography variant='h5'>Tous les utilisateurs</Typography>
      {formState.loadError && <Alert severity='error'>{formState.loadError}</Alert>}
      <DataGrid
        rows={formState.users || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]} //No more than 100 in MIT version
        checkboxSelection
        loading={formState.blocked}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        rowSelectionModel={formState.selectedRows}
        onRowSelectionModelChange={ (selectionModel, _details) => setFormState({...formState, selectedRows: selectionModel}) }
        onRowClick={ (params, _event, _details) => setFormState({...formState, selectedRows: [params.id]}) }
        onRowDoubleClick={ (params, _event, _details) => navigate("" + params.id) }
      />
      <Box>
        <FlexRow sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='primary' onClick={confirmDeleteSelected}
                  disabled={!formState.selectedRows?.length || formState.blocked}
                  startIcon={<GridDeleteIcon />}>
            Supprimer
          </Button>
          <Button variant='contained' color='secondary' onClick={editSelected}
                  disabled={!formState.selectedRows?.length || formState.blocked}
                  startIcon={<EditIcon />}>
            Modifier
          </Button>
        </FlexRow>
        <FlexRow sx={{justifyContent: 'flex-end' }}>
          <Button variant='outlined' color='secondary' onClick={createNew}
                  startIcon={<AddCircleIcon />} disabled={formState.blocked}>
            Nouveau
          </Button>
        </FlexRow>
      </Box>

      <LocalAlertDialog />

      <LocalDeleteConfirmDialog onConfirm={deleteSelected} />

      <FormSnackbarProvider />
    </Container>
  );


  

  //Action handlers

  function editSelected(event: React.MouseEvent) {
    if (formState.selectedRows?.length) {
      if (formState.selectedRows.length > 1) {
        setFormState({ ...formState, alertText: "Un seul utilisateur peut être modifié à la fois" });
      } else {
        navigate("" + formState.selectedRows[0]);
      }
    }
  }

  function createNew(event: React.MouseEvent) {
    navigate("new");
  }

  function confirmDeleteSelected(event: React.MouseEvent) {
    if (formState.selectedRows?.length) {
      if (formState.selectedRows.length > 1) {
        setFormState({ ...formState, deleteConfirmText: `Supprimer les ${formState.selectedRows.length} utilisateurs sélectionnés\u00a0?` });
      } else {
        const uid = formState.selectedRows[0];
        const user = formState.users?.find(u => u.id === uid);
        setFormState({ ...formState, deleteConfirmText: `Supprimer l'utilisateur ${user?.login}\u00a0?` });
      }
    }
  }

  function deleteSelected(event: React.MouseEvent) {
    if (!formState.selectedRows?.length) {
      enqueueSnacks(undefined, ['Aucune sélection']);
      return;
    }
    if (formState.selectedRows?.length !== 1) {
      deleteMany(formState.selectedRows);
      return;
    }
    deleteOne(formState.selectedRows[0]);
  }

  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, deleteConfirmText: undefined, loadError: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, deleteConfirmText: undefined, blocked: false });
  }

  function deleteMany(ids: GridRowId[]) {
    console.log("Delete", ids);

    startOperation();
    Api.deleteUsers(ids.map(id => id as number))
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          endOperation();
          const success = appResp.value.deleted ? `${appResp.value.deleted} utilisateurs supprimés` : undefined;
          const warnings = appResp.value.rejections.map(rej => {
            const u = formState.users?.find(u => u.id === rej.id);
            const uName = u ? u.login : rej.id;
            if (rej.error)
              return `${uName} - Erreur: ${rej.error}`;
            else
              return `${uName} - ${rej.warning}`;
          });
          const error = appResp.value.deleted ? undefined : "Aucun utilisateur supprimé";
          enqueueSnacks(success, warnings, error);
          setDataLoaded(false);
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }

  function deleteOne(id: GridRowId) {
    const user = formState.users?.find(u => u.id === id);
    console.log("Delete", user);
    if (!user) {
      endOperation();
      enqueueSnacks(undefined, ['Utilisateur introuvable']);
      return;
    }

    startOperation();
    Api.deleteUser(user.id)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value && appResp.value !== false) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          endOperation({ ...formState, users: formState.users?.filter(u => u.id !== id) });
          enqueueSnacks('Utilisateur supprimé');
        } else {
          endOperation();
          enqueueSnacks('L\'utilisateur n\'a pas été supprimé');
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }



  //Inner components

  function LocalAlertDialog(props: { }) : React.ReactElement | null {
    if (formState.alertText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Gestion des utilisateurs"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  function LocalDeleteConfirmDialog(props: {onConfirm?: MouseEventHandler}) : React.ReactElement | null {
    if (formState.deleteConfirmText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Suppression d'utilisateurs"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.deleteConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, deleteConfirmText: undefined })} autoFocus>Annuler</Button>
            <Button onClick={e => {
                props.onConfirm && props.onConfirm(e);
              }}
              variant='contained' color='secondary'
              >
                OK
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }

};

export default UsersPage;