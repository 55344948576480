import { ChangeEvent } from "react"
import { User, emptyUser } from '../../link/ApiTypes';
import { Box } from "@mui/material";
import { FlexRow, FormTextField } from "../common/forms/FormComponents";

export type UserInfoFields = {
  user?: User,
  password1?: string,
  password2?: string
}

type Props = {
  userInfo: UserInfoFields,
  onUserInfoChanged: (user: UserInfoFields) => void
}

export function UserInfo({userInfo, onUserInfoChanged}: Props) {

  return (
    <Box className='user-info'>
      <FlexRow>
        <FormTextField name="firstName" label="Prénom" value={userInfo.user?.firstName || ''} onChange={handleChange} />
        <FormTextField name="lastName" label="Nom" value={userInfo.user?.lastName || ''} onChange={handleChange} />
      </FlexRow>

      <FlexRow>
        <FormTextField name="login" label="Identifiant" value={userInfo.user?.login || ''} onChange={ handleChange } required={true} />
        <FormTextField  type="password" name="password1" label="Mot de passe" value={userInfo.password1 || ''}
                        onChange={handleChange} required={userInfo.password2 ? true : false}
                        placeholder='Pas de changement'/>
        <FormTextField  type="password" name="password2" label="Répéter MdP" value={userInfo.password2 || ''}
                        onChange={handleChange} required={userInfo.password1 ? true : false}
                        placeholder='Retaper Mot de passe' disabled={!userInfo.password1} />
      </FlexRow>
    </Box>
  );



  //Field change handlers

  function handleChange(e: ChangeEvent) {
    if (!onUserInfoChanged)
      return;
    const {name, value} = e.target as HTMLInputElement;
    const ui = {...userInfo || { user: emptyUser(), password1: undefined, password2: undefined }}
    switch (name) {
      case "password1":
        ui.password1 = value;
        break;
      case "password2":
        ui.password2 = value;
        break;
      default:
        const u = ui.user;
        (u as any)[name] = value;
        break;
    }
    onUserInfoChanged(ui);
  }
}