import * as ace from "ace-builds";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-html";
import React, { useRef } from "react";
import { IAceEditor } from 'react-ace/lib/types';


type Props = {
  name: string;
  codeMode: string;
  value?: string;
  onChange?: (value: string, _event: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void,
  style?: React.CSSProperties,
  codeValidationPrefix?: string,
};

export type CodeEditorHandle = {
  checkCode: () => Promise<string|undefined>;
}

const CodeEditor = React.forwardRef<CodeEditorHandle, Props>(({name, codeMode, value, onChange, onBlur, style, codeValidationPrefix} : Props, ref) => {
  
  //AceEditor
  const localEditorRef = useRef<AceEditor>(null);

  React.useImperativeHandle(ref, () => ({
    async checkCode() {
      if (!localEditorRef?.current) {
        return 'Éditeur non initialisé';
      }
  
      return await checkCodeFor(localEditorRef.current.editor, codeValidationPrefix);
    },
  }));

  return (

    <AceEditor  mode={codeMode} name={name}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  useWorker: false,
                  tabSize: 2,
                }}
                style={ {width: '100%', height: '50vh', minHeight: '20em', ...style}}
                value={value}
                onChange={onChange}
                ref={localEditorRef} 
                onBlur={onBlur} />
  );

  async function checkCodeFor(editor: IAceEditor, codePrefix : string|undefined) : Promise<string|undefined> {
    let value = editor.getValue();
    if (codePrefix && value)
      value = codePrefix + value;
    const mode =  editor.getSession().getOption('mode');

    let editSession = new ace.EditSession(value, mode as unknown as ace.Ace.SyntaxMode);

    return await new Promise((resolve) => {
      return (editSession as any).on('changeAnnotation', () => {
        const annotations = editSession.getAnnotations();

        editSession.removeAllListeners('changeAnnotation');
        editSession.destroy();

        let ret: string|undefined = undefined;
        //Annotations to string
        const mess: string[] = [];
    
        const codeErrors = annotations.filter(annotation => annotation.type === 'error');

        if (codeErrors?.length) {
          const editorLang = mode.split('/')[2];
          mess.push(`Erreurs ${editorLang} :`); 
          codeErrors.forEach(annotation => {
            mess.push(`${annotation.text} at line ${annotation.row}`);
          });
          mess.push('');
        }

        ret = mess.join('\n');
        resolve(ret);
      });

    });

  }
  
});


export default CodeEditor;
