import { Box } from "@mui/material";
import { ChangeEvent } from "react";
import { Client, emptyClient } from '../../link/ApiTypes';
import { FlexRow, FormTextField } from "../common/forms/FormComponents";

type Props = {
  client?: Client,
  onClientChanged: (client: Client) => void
}

export function ClientInfo({client, onClientChanged}: Props) {

  return (
    <Box className="client-info">
      
      <FlexRow>
      <FormTextField  name="name" label={'Raison sociale'}
                      value={client?.name || ''} onChange={handleChange}
                      sx={{ flexGrow: 1, maxWidth: '40ch' }} required />
      <FormTextField  name="contactName" label="Contact" value={client?.contactName || ''}
                      sx={{ flexGrow: 1, maxWidth: '50ch' }} onChange={handleChange} />
      </FlexRow>
    </Box>
  );

  //Field change handlers
  
  function handleChange(e: ChangeEvent) {
    if (!onClientChanged) return;
    const {name, value} = e.target as HTMLInputElement;
    const c = { ...client || emptyClient() };
    (c as any)[name] = value;
    onClientChanged(c);
  }

}