import { Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { closeSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormSnackbarProvider, enqueueSnacks } from '../../components/common/forms/FormComponents';
import * as ApiTypes from '../../link/ApiTypes';
import * as Api from '../../link/ModelServerApi';
import { LoginState } from '../../redux/features/loginSlice';
import { setGlobalProgress } from '../../redux/features/progressSlice';

type Props = { };

type FormState = {
  loadError?: string,
  alertText?: string,
  models?: ApiTypes.SimpleModel[]
};

const ClientModelsPage = (props : Props) => {
  //Imperative functions
  const dispatch = useDispatch();

  //States
  const [ formState, setFormState ] = React.useState<FormState>();
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);
  const { user } = useSelector((state: {login: LoginState}) => state.login);

  //Load
  React.useEffect(() => {
    if (!dataLoaded) {
      startOperation(false);
      Api.getClientModels(user?.client?.id)
        .then(appResp => {
          if (!appResp.ok) {
            endOperation({ ...formState, loadError: appResp.errorMessage && "Erreur au chargement\u00a0: " + appResp.errorMessage });
            enqueueSnacks(undefined, appResp.warnings);
            return;
          }
          if (!appResp.value) {
            endOperation({ ...formState, loadError: "Réponse vide du serveur" });
            return;
          }
          endOperation({ ...formState, models: appResp.value });
        })
        .catch(err => endOperation({ ...formState, loadError: "Erreur au chargement\u00a0: " +  err }))
        .finally(() => {
          setDataLoaded(true);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);



  //Grid Columns
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nom',
      width: 220,
      valueGetter: params => {
        if (!params.value) return null;
        return params.value;
      },
    },
    { 
      field: 'created',
      type:'dateTime',
      headerName: 'Créé le',
      width: 200,
      valueGetter: params => {
        if (!params.value) return null;
        return moment(params.value).toDate();
      },
    },
    {
      field: 'hash',
      headerName: 'Version',
      width: 200
    },
  ];

  //Layout
  return (
    <Container sx={{width:'fit-content'}}>
      <Typography variant='h5'>Tous mes modèles</Typography>
      {formState?.loadError && <Alert severity='error'>{formState.loadError}</Alert>}
      <DataGrid
        rows={formState?.models || []}
        getRowId={row => row.id}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50, 100]} //No more than 100 in MIT version
        autoHeight={true}
        disableRowSelectionOnClick={true}
      />

      <LocalAlertDialog />

      <FormSnackbarProvider />
    </Container>
  );


  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, loadError: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState});
  }
  

  //Inner components

  function LocalAlertDialog(props: { }) : React.ReactElement | null {
    if (formState?.alertText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Gestion des modèles"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState?.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

};

export default ClientModelsPage;