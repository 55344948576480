import { AddCircle as AddCircleIcon, Edit as EditIcon } from '@mui/icons-material';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridDeleteIcon, GridRowId } from '@mui/x-data-grid';
import moment from 'moment';
import { closeSnackbar } from 'notistack';
import React, { MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { FlexRow, FormSnackbarProvider, enqueueSnacks } from '../../components/common/forms/FormComponents';
import * as ApiTypes from '../../link/ApiTypes';
import * as Api from '../../link/ModelServerApi';
import { setGlobalProgress } from '../../redux/features/progressSlice';
import ClientLogoAndName from '../../components/common/ClientLogoAndName';

type Props = { };

type FormState = {
  blocked: boolean,
  selectedRows?: GridRowId[],
  loadError?: string,
  alertText?: string,
  deleteConfirmText?: string,
  models?: ApiTypes.SimpleModel[],
};

const ModelsPage = (props : Props) => {
  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false});
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);

  //Load
  React.useEffect(() => {
    if (!dataLoaded) {
      startOperation(false);
      Api.getAllModels()
        .then(appResp => {
          if (!appResp.ok) {
            endOperation({ ...formState, loadError: appResp.errorMessage && "Erreur au chargement\u00a0: " + appResp.errorMessage });
            enqueueSnacks(undefined, appResp.warnings);
            return;
          }
          if (!appResp.value) {
            endOperation({ ...formState, loadError: "Réponse vide du serveur" });
            return;
          }
          console.log('Models', appResp.value);
          endOperation({ ...formState, models: appResp.value });
        })
        .catch(err => endOperation({ ...formState, loadError: "Erreur au chargement\u00a0: " +  err }))
        .finally(() => {
          setDataLoaded(true);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  //Grid Columns
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nom',
      width: 160,
      renderCell: (params) => 
        <Link component={RouterLink} to={'./' + params.row.id}>
          {params.value}
        </Link>
    },
    { 
      field: 'created',
      type:'dateTime',
      headerName: 'Créé le',
      width: 180,
      valueGetter: params => {
        if (!params.value) return null;
        return moment(params.value).toDate();
      },
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 150,
      renderCell: (params) => <ClientLogoAndName client={params.row.client} />
    },
    {
      field: 'hash',
      headerName: 'Version',
      width: 120
    },
    {
      field: 'materials',
      headerName: 'Matériaux',
      width: 80,
      valueGetter: params => {
        return params.row.matsCount;
      }
    },
    {
      field: 'unknowMaterials',
      headerName: 'Inconnus',
      width: 100,
      valueGetter: params => {
        return params.row.unknownMatsCount;
      }
    }
  ];

  //Layout
  return (
    <Container maxWidth='md'>
      <Typography variant='h5'>Tous les modèles</Typography>
      {formState.loadError && <Alert severity='error'>{formState.loadError}</Alert>}
      <DataGrid
        rows={formState.models || []}
        getRowId={row => row.id}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50, 100]} //No more than 100 in MIT version
        checkboxSelection
        loading={formState.blocked}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        rowSelectionModel={formState.selectedRows}
        onRowSelectionModelChange={ (selectionModel, _details) => setFormState({...formState, selectedRows: selectionModel}) }
        onRowClick={ (params, _event, _details) => setFormState({...formState, selectedRows: [params.id]}) }
        onRowDoubleClick={ (params, _event, _details) => navigate("" + params.id) }
      />
      <Box>
        <FlexRow sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='primary' onClick={confirmDeleteSelected}
                  disabled={!formState.selectedRows?.length}
                  startIcon={<GridDeleteIcon />}>
            Supprimer
          </Button>
          <Button variant='contained' color='secondary' onClick={editSelected}
                  disabled={!formState.selectedRows?.length}
                  startIcon={<EditIcon />}>
            Modifier
          </Button>
        </FlexRow>
        <FlexRow sx={{justifyContent: 'flex-end' }}>
          <Button variant='outlined' color='secondary' onClick={createNew}
                  startIcon={<AddCircleIcon />}>
            Nouveau
          </Button>
        </FlexRow>
      </Box>

      <LocalAlertDialog />

      <LocalDeleteConfirmDialog onConfirm={deleteSelected} />

      <FormSnackbarProvider />
    </Container>
  );


  //Action handlers

  function editSelected(event: React.MouseEvent) {
    if (formState.selectedRows?.length) {
      if (formState.selectedRows.length > 1) {
        setFormState({ ...formState, alertText: "Un seul modèle peut être modifié à la fois" });
      } else {
        navigate("" + formState.selectedRows[0]);
      }
    }
  }

  function createNew(event: React.MouseEvent) {
    navigate("new");
  }

  function confirmDeleteSelected(event: React.MouseEvent) {
    if (formState.selectedRows?.length) {
      if (formState.selectedRows.length > 1) {
        setFormState({ ...formState, deleteConfirmText: `Supprimer les ${formState.selectedRows.length} modèles sélectionnés\u00a0?` });
      } else {
        const mId = formState.selectedRows[0];
        const mod = formState.models?.find(m => m.id === mId);
        setFormState({ ...formState, deleteConfirmText: `Supprimer le modèle ${mod?.name}\u00a0?` });
      }
    }
  }

  function deleteSelected(event: React.MouseEvent) {
    if (!formState.selectedRows?.length) {
      enqueueSnacks(undefined, ['Aucune sélection']);
      return;
    }
    if (formState.selectedRows?.length !== 1) {
      deleteMany(formState.selectedRows);
      return;
    }
    deleteOne(formState.selectedRows[0]);
  }

  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, deleteConfirmText: undefined, loadError: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, deleteConfirmText: undefined, blocked: false });
  }

  function deleteMany(ids: GridRowId[]) {
    console.log("Delete", ids);

    startOperation();
    Api.deleteModels(ids.map(id => id as number))
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          endOperation();
          const success = appResp.value.deleted ? `${appResp.value.deleted} modèles supprimés` : undefined;
          const warnings = appResp.value.rejections.map(rej => {
            const mod = formState.models?.find(m => m.id === rej.id);
            const modName = mod ? mod.name : `Modèle [id=${rej.id}]`;
            if (rej.error)
              return `${modName} - Erreur: ${rej.error}`;
            else
              return `${modName} - ${rej.warning}`;
          });
          const error = appResp.value.deleted ? undefined : "Aucun modèle supprimé";
          enqueueSnacks(success, warnings, error);
          setDataLoaded(false);
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }

  function deleteOne(id: GridRowId) {
    const model = formState.models?.find(m => m.id === id);
    console.log("Delete", model);
    if (!model) {
      endOperation();
      enqueueSnacks(undefined, ['Modèle introuvable']);
      return;
    }

    startOperation();
    Api.deleteModel(model.id)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value && appResp.value !== false) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          endOperation({ ...formState, models: formState.models?.filter(m => m.id !== id) });
          enqueueSnacks('Modèle supprimé');
        } else {
          endOperation();
          enqueueSnacks('Le modèle n\'a pas été supprimé');
        }
      })
      .catch(err => {
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }



  //Inner components

  function LocalAlertDialog(props: { }) : React.ReactElement | null {
    if (formState.alertText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Gestion des modèles"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  function LocalDeleteConfirmDialog(props: {onConfirm?: MouseEventHandler}) : React.ReactElement | null {
    if (formState.deleteConfirmText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Suppression de modèles"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {formState.deleteConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setFormState({ ...formState, deleteConfirmText: undefined })} autoFocus>Annuler</Button>
            <Button onClick={e => {
                props.onConfirm && props.onConfirm(e);
              }}
              variant='contained' color='secondary'
              >
                OK
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }

};

export default ModelsPage;