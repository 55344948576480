import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ClientLogoAndName from '../../../components/common/ClientLogoAndName';
import CodeEditor, { CodeEditorHandle } from '../../../components/common/CodeEditor';
import { NumberFormat } from '../../../components/common/Localization';
import { FlexRow, FormSnackbarProvider, StoredFileWithState, UploadButton, UploadedFile, UploadingFile, enqueueSnacks } from '../../../components/common/forms/FormComponents';
import { theme } from '../../../configs/themeOptions';
import * as ApiTypes from '../../../link/ApiTypes';
import * as Api from '../../../link/ClientServerApi';
import * as FilesApi from '../../../link/DbFilesServerApi';
import { setGlobalProgress } from '../../../redux/features/progressSlice';

type Props = { };

type FormState = {
  blocked: boolean,
  uploadingFiles?: number,
  client?: ApiTypes.Client,
  customization?: ApiTypes.ClientCustomization,
  loadError?: string,
  errorMessage?: string,
  warnings?: string[],
  alertText?: string,
  uploadedLogo?: StoredFileWithState|UploadingFile,
  uploadedOverlayLogo?: StoredFileWithState|UploadingFile,
  uploadedFavicon?: StoredFileWithState|UploadingFile,
  uploadedFiles: (StoredFileWithState|UploadingFile)[],
};

const ClientCustomizationPage = (props: Props) => {

  //URL Parameters
  const { clientId } = useParams();
  let nClientId = parseInt(clientId || '');

  //Imperative functions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false, uploadedFiles: []});
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);
  const [ expanded, setExpanded ] = React.useState<string | false>(false);

  //Refs
  const jsEditorRef = React.useRef<CodeEditorHandle>(null);
  const cssEditorRef = React.useRef<CodeEditorHandle>(null);


  //Load
  React.useEffect(() => {
    
    if (!dataLoaded) {
      if (isNaN(nClientId)) {
        setDataLoaded(true);
        return;
      }
      startOperation(false);
      Api.getClient(nClientId)
        .then(appResp => {
          if (!appResp.ok) {
            endOperation({ ...formState, loadError: appResp.errorMessage && "Erreur au chargement\u00a0: " + appResp.errorMessage });
            enqueueSnacks(undefined, appResp.warnings);
            setDataLoaded(true);
            return;
          }
          if (!appResp.value) {
            endOperation({ ...formState, loadError: "Réponse vide du serveur" });
            setDataLoaded(true);
            return;
          }
          const client = appResp.value;
          Api.getClientCustomization(nClientId)
            .then(appResp => {
              if (!appResp.ok) {
                endOperation({ ...formState, client: client, loadError: "Erreur au chargement\u00a0: " + appResp.errorMessage });
                enqueueSnacks(undefined, appResp.warnings);
                return;
              }
              if (!appResp.value) {
                endOperation({ ...formState, client: client, loadError: "Réponse vide du serveur" });
                return;
              }
              endOperation({ ...formState, client: client, customization: appResp.value,
                            uploadedLogo: appResp.value.logo, uploadedOverlayLogo: appResp.value.overlaylogo,
                            uploadedFavicon: appResp.value.favicon, uploadedFiles: appResp.value.files || [] });
            })
            .catch(err => endOperation({ ...formState, client: client, loadError: "Erreur au chargement\u00a0: " +  err }))
            .finally(() => {
              setDataLoaded(true);
            });
        })
        .catch(err => {
          endOperation({ ...formState, loadError: "Erreur au chargement\u00a0: " +  err });
          setDataLoaded(true);
        })
    }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  //Layout
  return (
    <Box component='form' onSubmit={save} onKeyDown={kbHotkeys}>
      <Typography variant='h5'>Personnalisations pour le client {formState.client && <> <ClientLogoAndName client={formState.client} /></>}</Typography>

      { formState.loadError && <Alert severity='error' sx={{my: 1}}>{formState.loadError}</Alert> }
      { formState.errorMessage && <Alert severity='error' sx={{my: 1}}>{formState.errorMessage}</Alert> }
      { formState.warnings && formState.warnings.map(warnMessage => <Alert severity='warning'>{warnMessage}</Alert>) }

      

      <LocalFilesAccordion index={0} name='files' label='Fichiers' />
      <LocalEditorAccordion index={1} name='js' label='JavaScript' codeMode='javascript' value={formState.customization?.js} editorRef={jsEditorRef}/>
      <LocalEditorAccordion index={2} name='css' label='CSS' codeMode='css' value={formState.customization?.css} editorRef={cssEditorRef}/>

      <FlexRow>
        <Box flexGrow={1} />
        <Button component={RouterLink} to='..' variant='outlined'>Retour</Button>
        <Button type="submit" variant='contained' color='secondary'
                disabled={formState.uploadingFiles || formState.blocked || formState.loadError ? true : false}
                startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </FlexRow>
    
      <LocalAlertDialog />

      <FormSnackbarProvider />
    </Box>
  );

  //Keyboard hotkeys
  function kbHotkeys(e: React.KeyboardEvent) {
    if (e.ctrlKey) {
      switch (e.key) {
        case 's':
          e.preventDefault();
          e.stopPropagation();
          if (!formState.blocked && !formState.loadError && !formState.uploadingFiles) {
            save(e);
          }
          return false;
        }
    }
    else {
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          e.stopPropagation();
          navigate('..');
          return false;
      }
    }
  }

  //Field change handlers



  //Action handlers
  async function save(e: React.FormEvent) {
    e.preventDefault();

    // Code validation
    const errors = [];
    const jsEditor = jsEditorRef?.current;
    const jsError = await jsEditor?.checkCode();
    if (jsError) {
      errors.push(jsError);
    }
    const cssEditor = cssEditorRef?.current;
    const cssError = await cssEditor?.checkCode();
    if (cssError) {
      errors.push(cssError);
    }

    if (errors.length) {
      endOperation({ ...formState, alertText: errors.join('\n') });
      return;
    }

    if ((formState.uploadedLogo && formState.uploadedLogo.hasOwnProperty('file')) || (formState.uploadedOverlayLogo && formState.uploadedOverlayLogo.hasOwnProperty('file')) ||
        (formState.uploadedFavicon && formState.uploadedFavicon.hasOwnProperty('file')) || (formState.uploadedFiles.find(f => f.hasOwnProperty('file')))){
      console.warn('tried to save while file was being uploaded');
      return;
    }

    startOperation();
    Api.saveClientCustomization(formState.client || ApiTypes.emptyClient(),
                                    formState.customization || ApiTypes.emptyClientCustomization(),
                                    formState.uploadedLogo as StoredFileWithState,
                                    formState.uploadedOverlayLogo as StoredFileWithState,
                                    formState.uploadedFavicon as StoredFileWithState,
                                    formState.uploadedFiles as StoredFileWithState[])
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({
            ...formState,
            errorMessage: appResp.errorMessage ? "Erreur à l'enregistrement\u00a0:" + appResp.errorMessage : undefined,
            warnings: appResp.warnings
           });
          return;
        }
        if (!appResp.value) {
          endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0: Réponse vide du serveur" });
          return;
        }
        const savedCustomization = appResp.value as ApiTypes.ClientCustomization;
        endOperation({ client: formState.client, customization: savedCustomization, uploadedLogo: savedCustomization.logo,
                       uploadedOverlayLogo: savedCustomization.overlaylogo, uploadedFavicon: savedCustomization.favicon, uploadedFiles: savedCustomization.files });
        enqueueSnackbar('Personnalisation enregistrée', { variant: "success" });
      })
      .catch(err => endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0:" + err }));
  }

  


  function uploadTempFile(file: File, savedFiles: (StoredFileWithState|undefined)[], onUploadDone?: (storedTempFile : StoredFileWithState) => void, onError?: (errorMessage: string) => void) {
    FilesApi.uploadTempFile(file)
      .then(appResp => {
        if (!appResp.ok) {
          if (appResp.errorMessage) {
            console.error("Error uploading file: ", appResp);
            if (onError) onError(appResp.errorMessage);
          }
          for (const warn of appResp.warnings || []) {
            enqueueSnackbar("Envoi du fichier\u00a0: " + warn, { variant: "warning" });
          }
          return;
        }
        if (!appResp.value) {
          console.error("Error uploading file: empty server response", appResp);
          if (onError) onError("Réponse vide du serveur");
          return;
        }

        const storedTempFile = appResp.value as StoredFileWithState;
        if (storedTempFile.id) {
          storedTempFile.unsaved = !!storedTempFile.id && !(savedFiles.find(f => f?.id === storedTempFile.id));
          if (onUploadDone) onUploadDone(storedTempFile);
          return;
        }

        console.error("Error uploading file: invalid server response", appResp);
        if (onError) onError("Réponse invalide du serveur");
      })
      .catch(err => {
        console.error("Error uploading file:", err);
        const mess = err.message ? err.message : ('' + err);
        if (onError) onError(mess);
      });
  }

  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    dispatch(setGlobalProgress(-1));
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, loadError: undefined, errorMessage: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, blocked: false });
  }

  //Local controls

  function LocalFilesAccordion({ index, name, label } : { index: number, name: string, label: string }) {

    const files = formState.uploadedFiles;
    
    return (
      <Accordion expanded={expanded === name} onChange={handleExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
          id={`panel${index}bh-header`}>
          <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display='flex' flexDirection='column' gap={1}>
            <>
              <FlexRow alignItems='flex-start' mt={0}>
                <Typography variant='subtitle1' fontWeight='bold' flexGrow={1}>Logo client&nbsp;:</Typography>
                <UploadButton name='icon' variant='contained' startIcon={<CloudUploadIcon />}
                              disabled={!!formState.uploadingFiles} accept='image/*'
                              onChange={(e) => uploadFile(e, 'Logo')}>
                  Charger fichier
                </UploadButton>
              </FlexRow>
              {
                (!formState.uploadedLogo && formState.customization?.logo) &&
                <Typography fontStyle='italic' color={theme.palette.text.disabled}>* Enregistrement en attente</Typography>
              }
              <FlexRow alignItems='flex-end' mt={0}>
                <UploadedFile  value={formState.uploadedLogo as StoredFileWithState} disabled={!!formState.uploadingFiles}
                                    onDeleteFile={_storedFile => setFormState({...formState, uploadedLogo: undefined})} />
              </FlexRow>

              <Divider orientation='horizontal' />
              
              <FlexRow alignItems='flex-start' mt={0}>
                <Typography variant='subtitle1' fontWeight='bold' flexGrow={1}>Logo superposé client&nbsp;:</Typography>
                <UploadButton name='icon' variant='contained' startIcon={<CloudUploadIcon />}
                              disabled={!!formState.uploadingFiles} accept='image/*'
                              onChange={(e) => uploadFile(e, 'OverlayLogo')}>
                  Charger fichier
                </UploadButton>
              </FlexRow>
              {
                (!formState.uploadedOverlayLogo && formState.customization?.overlaylogo) &&
                <Typography fontStyle='italic' color={theme.palette.text.disabled}>* Enregistrement en attente</Typography>
              }
              <FlexRow alignItems='flex-end' mt={0}>
                <UploadedFile  value={formState.uploadedOverlayLogo as StoredFileWithState} disabled={!!formState.uploadingFiles}
                                    onDeleteFile={_storedFile => setFormState({...formState, uploadedOverlayLogo: undefined})} />
              </FlexRow>

              <Divider orientation='horizontal' />
              
              <FlexRow alignItems='flex-start' mt={0}>
                <Typography variant='subtitle1' fontWeight='bold' flexGrow={1}>Favicon client&nbsp;:</Typography>
                <UploadButton name='icon' variant='contained' startIcon={<CloudUploadIcon />}
                              disabled={!!formState.uploadingFiles} accept='image/*'
                              onChange={(e) => uploadFile(e, 'Favicon')}>
                  Charger fichier
                </UploadButton>
              </FlexRow>
              {
                (!formState.uploadedFavicon && formState.customization?.favicon) &&
                <Typography fontStyle='italic' color={theme.palette.text.disabled}>* Enregistrement en attente</Typography>
              }
              <FlexRow alignItems='flex-end' mt={0}>
                <UploadedFile  value={formState.uploadedFavicon as StoredFileWithState} disabled={!!formState.uploadingFiles}
                                    onDeleteFile={_storedFile => setFormState({...formState, uploadedFavicon: undefined})} />
              </FlexRow>

              <Divider orientation='horizontal' />
              
              <FlexRow alignItems='flex-start' mt={0}>
                <Typography  variant='subtitle1' fontWeight='bold' mt={1} flexGrow={1}>
                    <Box component='label'>Autres fichiers&nbsp;: </Box>
                    <NumberFormat>{files?.length}</NumberFormat>
                </Typography>
                <UploadButton name='files' variant='contained' startIcon={<CloudUploadIcon />}
                              disabled={!!formState.uploadingFiles} multiple={true}
                              onChange={uploadFiles}>
                  Ajouter des fichiers
                </UploadButton>
              </FlexRow>
              {
                !!(files?.length) && 
                files.map((file, index) => <UploadedFile value={file} key={`file-${index}`} disabled={!!formState.uploadingFiles} onDeleteFile={deleteUploadedFile} />)
              }
              {
                ((files?.length || 0) < (formState.customization?.files?.length || 0)) &&
                <Typography fontStyle='italic' color={theme.palette.text.disabled}>* Enregistrement en attente</Typography>
              }
            </>
          </Box>
        </AccordionDetails>
      </Accordion>
    );

    function handleExpand(event: React.SyntheticEvent<Element, Event>, expanded: boolean) {
      setExpanded(expanded ? name : false);
    }

    function deleteUploadedFile(storedFile: ApiTypes.StoredFile) {
      const nUlFiles = [...formState.uploadedFiles];
      const index = nUlFiles.findIndex(f => f.hasOwnProperty('id') && (f as StoredFileWithState).id === storedFile.id);
      nUlFiles.splice(index, 1);
      setFormState({...formState, uploadedFiles: nUlFiles});
    }

    type FileType = 'Logo' | 'OverlayLogo' | 'Favicon';
    function uploadFile(e: React.ChangeEvent<HTMLInputElement>, type: FileType) {
      const files = e.target.files;
      if (files?.length) {
        const tempFile = files[0];
        const uploadingFile: UploadingFile = { file: tempFile, filename: tempFile.name, mimeType: tempFile.type};
        const successMessage = {
          Logo: 'Fichier de logo envoyé',
          OverlayLogo: 'Fichier du logo superposé envoyé',
          Favicon: 'Fichier du favicon envoyé'
        }[type];
        const customizationType = {
          Logo: formState.customization?.logo,
          OverlayLogo: formState.customization?.overlaylogo,
          Favicon: formState.customization?.favicon
        }[type];

        setFormState({...formState, uploadingFiles: (formState.uploadingFiles || 0) + 1, [`uploaded${type}`]: uploadingFile});
        uploadTempFile(tempFile, [customizationType],
          storedTempFile => {
            setFormState({...formState, [`uploaded${type}`]: storedTempFile, uploadingFiles: (formState.uploadingFiles ? formState.uploadingFiles - 1 : 0)});
            enqueueSnackbar(successMessage, { variant: "success" });
          },
          errorMessage => {
            setFormState({...formState, [`uploaded${type}`]: {...uploadingFile, uploadError: errorMessage}, uploadingFiles: (formState.uploadingFiles ? formState.uploadingFiles - 1 : 0)});
            enqueueSnackbar("Erreur à l'envoi du fichier : " + errorMessage, { variant: "error" });
          });
      }
    }

    function uploadFiles(e : React.ChangeEvent<HTMLInputElement>) {
      const files = e.target.files;
      const stateUlFiles = [...formState.uploadedFiles];
      const ulFiles : (UploadingFile|StoredFileWithState)[] = [];

      for (const tempFile of files || []) {
        const ulFile = { file: tempFile, filename: tempFile.name, mimeType: tempFile.type};
        if (stateUlFiles.find(f => f.filename === tempFile.name))
          continue;
        ulFiles.push(ulFile);
        stateUlFiles.push(ulFile);
      }

      let filesRemaining = ulFiles.length, filesUploaded = 0;
      setFormState({...formState, uploadingFiles: (formState.uploadingFiles || 0) + ulFiles.length, uploadedFiles: [...formState.uploadedFiles, ...ulFiles]});
      
      function uploadDone(storedTempFile: StoredFileWithState, tempFileIndex: number) {
        stateUlFiles[tempFileIndex] = storedTempFile;
        setFormState({ ...formState, uploadedFiles: [...stateUlFiles], uploadingFiles: --filesRemaining });
        ++filesUploaded;
        if (filesRemaining <= 0) {
          const s = filesUploaded > 1 ? 's' : '';
          enqueueSnackbar(`${filesUploaded} fichier${s} envoyé${s}`, { variant: "success" });
        }
      }

      function uploadError(errorMessage: string, tempFileIndex: number) {
        stateUlFiles[tempFileIndex] = {...stateUlFiles[tempFileIndex], uploadError: errorMessage };
        setFormState({ ...formState, uploadedFiles: [...stateUlFiles], uploadingFiles: --filesRemaining });
      }
      
      for (const tempFile of ulFiles) {
        uploadTempFile( (tempFile as UploadingFile).file,
                        formState.customization?.files || [],
                        storedTempFile => uploadDone(storedTempFile, stateUlFiles.indexOf(tempFile)),
                        errorMessage => uploadError(errorMessage, stateUlFiles.indexOf(tempFile))
                      );
      }
  
    }

  }

  function LocalEditorAccordion({index, name, label, codeMode, value, editorRef} : {index: number, name: string, label: string, codeMode: string, value?: string, editorRef?: React.Ref<CodeEditorHandle>}) {

    return (
      <Accordion expanded={expanded === name} onChange={handleExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
          id={`panel${index}bh-header`}>
          <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <CodeEditor name={name} codeMode={codeMode} value={value} ref={editorRef} onChange={handleChange}/>
          </Box>
        </AccordionDetails>
      </Accordion>
    );

    function handleExpand(event: React.SyntheticEvent<Element, Event>, expanded: boolean) {
      setExpanded(expanded ? name : false);
    }
  
    function handleChange(value: string, _event: any) {
      const c = {...formState.customization || ApiTypes.emptyClientCustomization() };
      (c as any)[name] = value;
      formState.customization = c; //Intentionally mutating formState to avoid focus bug
    }
  }

    function LocalAlertDialog(props: { }) : React.ReactElement | null {
      if (formState.alertText) {
        return (
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Sauvegarde de la personnalisation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" mt={2} whiteSpace='pre-wrap'>
                {formState.alertText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={_e => setFormState({ ...formState, alertText: undefined })} autoFocus variant='contained' color='secondary'>OK</Button>
            </DialogActions>
          </Dialog>
        );
      }
      return null;
    };
   
}

export default ClientCustomizationPage;