import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LocaleState = {
  locale: string
}

const initialState : LocaleState = {
  locale: 'fr'
};

export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale: (state: LocaleState, action: PayloadAction<string>) => {
      state.locale = action.payload;
    }
  }
});

export const {
  setLocale
} = localeSlice.actions;

export default localeSlice.reducer;