import React from 'react';

type Props = { };

const AccessIndexPage = (props : Props) => {
  return (
    <div>AccessIndexPage</div>
  );
};

export default AccessIndexPage;