import { useSelector } from 'react-redux';
import { LocaleState } from '../../redux/features/localeSlice';
import React from 'react';
import { isNumber } from '@mui/x-data-grid/internals';

type NumberFormatProps = { children: React.ReactNode };

type DateFormatProps = { children: React.ReactNode, formatOptions?: Intl.DateTimeFormatOptions };

export function NumberFormat(props: NumberFormatProps) : React.ReactElement {
  
  const { locale } = useSelector((state: {locale: LocaleState}) => state.locale);

  if (!props.children && props.children !== 0) {
    return ( <></> );
  }

  if (!isNumber(props.children)) {
    console.warn('Invalid value passed to NumberFormat:', props.children);
    return ( <>{props.children}</> );
  }

  try {
    return (
      <span className='number'>
        {Intl.NumberFormat(locale).format(props.children)}
      </span>
    );
  } catch (err) {
    console.error(err);
    return ( <>{props.children}</> );
  }

}

export function DateFormat(props: DateFormatProps) : React.ReactElement {

  const { locale } = useSelector((state: {locale: LocaleState}) => state.locale);

  if (!props.children && props.children !== 0) {
    return ( <></> );
  }
  let date;
  if (!(props.children instanceof Date)) {
    if (typeof props.children === 'string' || props.children instanceof String) {
      try {
        const timestamp = Date.parse(props.children as string);
        if (isNaN(timestamp))
          throw Error('Not a valid date string');
        date = new Date(timestamp);
      } catch {
        console.warn('Invalid value passed to NumberFormat:', props.children);
        return ( <>{props.children}</> );
      }
    } else if (typeof props.children === "number" && !isNaN(props.children)) {
      date = new Date(props.children);
    } else {
      console.warn('Invalid value passed to NumberFormat:', props.children);
      return ( <>{props.children}</> );
    }
  } else {
    date = props.children;
  }

  if (!date) {
    return ( <></> );
  }

  try {
    return (
      <span className='number'>
        {Intl.DateTimeFormat(locale, props.formatOptions).format(date)}
      </span>
    );
  } catch (err) {
    console.error(err);
    return ( <>{props.children}</> );
  }

}
