import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, CardActions, CardContent, CardHeader, CircularProgress, SvgIconProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { theme } from "../../configs/themeOptions";

type InfoCardProps = {
  IconType?: React.ComponentType<SvgIconProps>;
  background?: string;
  color?: string;
  title: ReactNode | string;
  leftContent?: ReactNode | string;
  children: ReactNode | string;
  actions?: ReactNode | string;
  loading?: boolean;
  errorText?: string;
};
export function InfoCard({ IconType, background, color, title, leftContent, children, actions, loading, errorText }: InfoCardProps) {
  const textColor = color ? color : undefined;
  return (
    <Card sx={{ background: background, color: textColor, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        avatar={IconType ? <IconType sx={{ fontSize: '1.8em' }} /> : undefined}
        title={<Typography variant="inherit">{title}</Typography>}
        sx={{ pb: 0, fontSize: '1.25em' }} />
      <CardContent sx={{ flexGrow: 1, display: 'flex', gap: 1, alignItems: 'flex-end' }}>
        <Box flexShrink={0} flexGrow={1}>
          <Typography variant="h2" fontWeight='bold'>
            {loading
              ?
              <CircularProgress />
              : (
                errorText
                  ?
                  <WarningIcon sx={{ fontSize: '1em', color: theme.palette.warning.main }} />
                  :
                  leftContent
              )}
          </Typography>
        </Box>
        <Box maxHeight='6.2em' maxWidth='90%' sx={{ overflowX: 'visible', overflowY: 'hidden' }}>
          {loading
            ?
            "Chargement..."
            : (
              errorText
                ?
                errorText
                :
                children
            )}
        </Box>
      </CardContent>
      {actions &&
        <CardActions sx={{ justifyContent: 'flex-end', pt: 0 }}>{actions}</CardActions>}
    </Card>
  );
}
