import * as Api from '../util/ApiCall';
import { AppServerResponse, User } from './ApiTypes';

export async function readServerResponse<T>(context: string, resp: Response, valueTransformer?: (rawValue: any) => T) : Promise<AppServerResponse<T>> {
    if (!resp.ok) {
        const respBody = await resp.text();
        return {
            ok: false,
            httpStatus: resp.status,
            errorMessage: respBody ? respBody : resp.statusText
        }
    }
    const data = await resp.json();
    if (data) {
        if ((data.warnings && data.warnings.length) || data.error) {
            return {
                ok: false,
                httpStatus: resp.status,
                errorMessage: data.error,
                warnings: data.warnings,
                value: valueTransformer ? valueTransformer(data.value) : data.value
            }
        }
        return {
            ok: true,
            httpStatus: resp.status,
            value: valueTransformer ? valueTransformer(data.value) : data.value
        }
    }
    return {
        ok: true,
        httpStatus: resp.status
    }
}

export async function checkLoggedOnUser() : Promise<AppServerResponse<User>> {
    const resp = await Api.get("/api/current-user");
    return readServerResponse<User>("/api/current-user", resp);
}

export async function logout() : Promise<AppServerResponse<boolean>> {
    const resp = await Api.post("/api/logout")
    return readServerResponse<boolean>("/api/logout", resp);
}

export async function login(userName : string, password : string) : Promise<AppServerResponse<{token: string, user: User}>> {
    const resp = await Api.post("/api/login", {
            userName: userName,
            password: password
        });
    return readServerResponse<{token: string, user: User}>("/api/login", resp);
}


