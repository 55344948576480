import { Outlet } from "react-router-dom";

type Props = { };

const AccessPageLayout = (props : Props) => {
  return (
    <Outlet />
  );
};

export default AccessPageLayout;