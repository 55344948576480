import * as Api from '../util/ApiCall';
import { AppServerResponse, DeleteManyResult, DetailedSimpleModel, DetailedSimpleModelWithMatCount, ModelInterior, ModelWithMatCount, SimpleModel, UploadModelResult } from './ApiTypes';
import { readServerResponse } from './ServerApi';


export async function getAllModels(): Promise<AppServerResponse<ModelWithMatCount[]>> {
  const resp = await Api.get("/api/model/all");
  return readServerResponse<ModelWithMatCount[]>("/api/model/all", resp);
}

export async function getClientModels(clientId? : number): Promise<AppServerResponse<SimpleModel[]>> {
  const resp = await Api.get("/api/model/client/" + clientId);
  return readServerResponse<SimpleModel[]>("/api/model/client/" + clientId, resp);
}

export async function getModel(modelId: number): Promise<AppServerResponse<DetailedSimpleModel>> {
  const resp = await Api.get("/api/model/id/" + modelId);
  return readServerResponse<DetailedSimpleModel>("/api/model/id/" + modelId, resp);
}

export async function getModelInterior(modelId: number): Promise<AppServerResponse<ModelInterior>> {
  const resp = await Api.get("/api/model/interior/" + modelId);
  return readServerResponse<ModelInterior>("/api/model/interior/" + modelId, resp);
}

export async function deleteModel(modelId: number): Promise<AppServerResponse<boolean>> {
    const resp = await Api.del("/api/model/id/" + modelId);
    return readServerResponse<boolean>("/api/model/id/" + modelId, resp);
}

export async function deleteModels(modelIds: number[]): Promise<AppServerResponse<DeleteManyResult>> {
    const resp = await Api.del("/api/model", modelIds);
    return readServerResponse<DeleteManyResult>("/api/model", resp);
}

export async function uploadModelFile(file: File, usePositionCompression: boolean, useTextureCoordCompression: boolean): Promise<AppServerResponse<UploadModelResult>> {
  const resp = await Api.putFile("/api/model/file", file, { compressPosition: usePositionCompression, compressTextureCoord: useTextureCoordCompression });
  return readServerResponse<UploadModelResult>("/api/model/file", resp);
}

export async function saveModel(model: DetailedSimpleModelWithMatCount): Promise<AppServerResponse<DetailedSimpleModelWithMatCount>> {
  const resp = await Api.post("/api/model/save", model);
  return readServerResponse<DetailedSimpleModelWithMatCount>("/api/model/save", resp);
}

export async function saveModelOptions(model: DetailedSimpleModel): Promise<AppServerResponse<DetailedSimpleModel>> {
  const resp = await Api.post("/api/model/options", { model });
  return readServerResponse<DetailedSimpleModel>("/api/model/options", resp);
}

export async function saveModelInterior(model: ModelInterior): Promise<AppServerResponse<ModelInterior>> {
  const resp = await Api.post("/api/model/interior", { model });
  return readServerResponse<ModelInterior>("/api/model/interior", resp);
}

export async function getModelMaterialRefs(modelId: number): Promise<AppServerResponse<Promise<{ name: string; found: boolean; }[]>>> {
  const resp = await Api.get("/api/model/materials/" + modelId);
  return readServerResponse<Promise<{ name: string; found: boolean; }[]>>("/api/model/materials/" + modelId, resp);
}


