import { createSlice } from "@reduxjs/toolkit";
import { User } from '../../link/ApiTypes';

export type UsersPageState = {
  loading: boolean,
  users: User[],
  loadError?: string
}

const initialState : UsersPageState = {
  loading: true,
  users: [],
  loadError: undefined
};

export const usersPageSlice = createSlice({
  name: "usersPageSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      console.log('setLoading', action)
      state.loading = action.payload;
    },
    setUsers: (state, action) => {
      console.log('setUsers', action)
      state.users = action.payload;
    },
    setLoadError: (state, action) => {
      console.log('setLoadError', action)
      state.loadError = action.payload;
    }
  }
});

export const {
    setLoading, setUsers, setLoadError
} = usersPageSlice.actions;

export default usersPageSlice.reducer;