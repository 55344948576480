import { createSlice } from "@reduxjs/toolkit";
import { User } from '../../link/ApiTypes';

export type LoginState = {
  connecting: boolean,
  user?: User
}

const initialState : LoginState = {
  connecting: true,
  user: undefined
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setConnecting: (state, action) => {
      console.log('setConnecting', action)
      state.connecting = action.payload;
    },
    setUser: (state, action) => {
      console.log('setUser', action)
      state.user = action.payload;
    }
  }
});

export const {
  setConnecting, setUser
} = loginSlice.actions;

export default loginSlice.reducer;