import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenuState } from "../../redux/features/menuPageSlice";
import { ReactNode } from "react";
import { RouteState } from "../../routes/appRoutes";

type Props = {
  state?: RouteState,
  children: ReactNode;
};

/**
 * A wrapper for a page that will update the menu state fir the loaded page
 * @param props 
 * @returns 
 */
const PageWrapper = (props: Props) => {
  //Imperative functions
  const dispatch = useDispatch();

  //Update menu state
  useEffect(() => {
    if (props.state) {
      dispatch(setMenuState(props.state));
    }
  }, [dispatch, props]);

  //Layout : wrap children
  return (
    <>{props.children}</>
  );
};

export default PageWrapper;