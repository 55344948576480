import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import { routes } from "../../routes";
import { User } from '../../link/ApiTypes';

type Props = { user: User }

function AppLayout({ user } : Props) {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="/" element={<MainLayout user={user} />}>
                    {routes}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppLayout;