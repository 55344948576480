import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "../../configs/themeOptions";
import * as ApiTypes from "../../link/ApiTypes";
import { InfoCard } from '../common/InfoCard';

type ModelCardProps = {
  model?: ApiTypes.DetailedSimpleModelWithMatCount,
  handleClick?: () => void,
}
export function ConfiguratorOptionsCard({model} : ModelCardProps) {

  return (
    <InfoCard
      background={theme.palette.card1.light}
      color={theme.palette.card1.contrastText}
      IconType={WidgetsIcon}
      title="Configurateur 3D"
      actions={<Button component={RouterLink} to={`../models/${model?.id}/configurator`} disabled={!model?.id}>Modifier</Button>}
      >
      <Typography variant="h6">Choix des options</Typography>
      <Typography variant="body1" fontStyle='italic'>Paramètrer le rendu et les options de configuration</Typography>
    </InfoCard>
  );
}

export function IndoorOptionsCard({model} : ModelCardProps) {

  return (
    <InfoCard
      background={theme.palette.card3.light}
      color={theme.palette.card3.contrastText}
      IconType={CameraIndoorIcon}
      title="Intérieur"
      actions={<Button component={RouterLink} to={`../models/${model?.id}/interior`} disabled={!model?.id}>Modifier</Button>}
      >
      <Typography variant="h6">Configuration intérieure</Typography>
      <Typography variant="body1" fontStyle='italic'>Définir les étages, les plans, les cloisons</Typography>
    </InfoCard>
  );
}

export function MaterialsInfoCard({model, handleClick} : ModelCardProps) {

  const unknownMatsCount = model?.unknownMatsCount ?? 0;
  
  return (
    <InfoCard
    background={theme.palette.card5.light}
    color={theme.palette.card5.contrastText}
    IconType={ViewInArIcon}
    title="Matériaux"
    leftContent={model?.matsCount}
    actions={<Button onClick={handleClick} disabled={!model?.id}>Liste</Button>}
    >
    <Typography variant="body1" fontStyle='italic'>
      Matériaux utilisés par le modèle dont {unknownMatsCount} inconnu{unknownMatsCount > 1 ? 's' : ''}
    </Typography>
  </InfoCard>
);

}

