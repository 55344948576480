import { Alert, Box, Button, Container, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { closeSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import ClientAutoComplete from '../../components/common/ClientAutoComplete';
import ClientModelAutoComplete from '../../components/common/ClientModelAutoComplete';
import { FormSnackbarProvider, enqueueSnacks } from '../../components/common/forms/FormComponents';
import * as ApiTypes from '../../link/ApiTypes';
import * as Api from '../../link/ConversionsDataServerApi';
import { LoginState } from '../../redux/features/loginSlice';
import ClientLogoAndName from '../../components/common/ClientLogoAndName';

type Props = { };
 
type FormState = {
  blocked: boolean,
  loadError?: string,
  client?: ApiTypes.Client,
  model?: ApiTypes.SimpleModel,
  startDate?: Date,
  endDate?: Date,
  conversionsData?: ApiTypes.ConversionsData[],
};

const ConversionsPage = (props : Props) => {

  //States
  const { user } = useSelector((state: {login: LoginState}) => state.login);
  const [ formState, setFormState ] = React.useState<FormState>({blocked: false});
  const [ dataLoaded, setDataLoaded ] = React.useState<boolean>(false);

  //Load
  React.useEffect(() => {
    if (!dataLoaded) {
      console.log("State changed")
      startOperation(false);
      Api.getConversionsData({clientId: user?.client ? user.client.id : formState.client?.id, modelName: formState.model?.name, startDate: formState.startDate, endDate: formState.endDate})
        .then(appResp => {
          if (!appResp.ok) {
            endOperation({ ...formState, loadError: appResp.errorMessage && "Erreur au chargement\u00a0: " + appResp.errorMessage });
            enqueueSnacks(undefined, appResp.warnings);
            return;
          }
          if (!appResp.value) {
            endOperation({ ...formState, loadError: "Réponse vide du serveur" });
            return;
          }
          endOperation({ ...formState, conversionsData: appResp.value });
        })
        .catch(err => endOperation({ ...formState, loadError: "Erreur au chargement\u00a0: " +  err }))
        .finally(() => { 
          setDataLoaded(true);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  //Grid columns
  const columns: GridColDef[] = [
    { 
      field: 'date',
      headerName: 'Date',
      width: 100,
      valueGetter: params => {
        if (!params.value) return null;
        return moment(params.value).format('L');
      }
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 200,
      renderCell: (params) => <ClientLogoAndName client={params.row.client} />
    },
    {
      field: 'modelName',
      headerName: 'Modèle',
      width: 200,
      valueGetter: params => {
        return params.row.modelName;
      }
    },
    { 
      field: 'reqCount',
      headerName: 'Visites',
      headerAlign: 'center',
      width: 80,
      align: 'center',
      valueGetter: params => {
        return params.row.reqCount;
      }
    },
    { 
      field: 'formCount',
      headerName: 'Leads',
      headerAlign: 'center',
      width: 60,
      align: 'center',
      valueGetter: params => {
        return params.row.formCount;
      }
    },
    { 
      field: 'conversionRate',
      headerName: 'Conversion %',
      headerAlign: 'center',
      width: 110,
      align: 'center',
      valueGetter: params => {
        return params.row.conversionRate;
      }
    },
  ];
  
  const clientUser = user?.client ? true : false;

  //Layout
  return (
    <>
      <Box width='fit-content' m='auto'>

        <Stack direction='row' useFlexGap spacing={2} flexWrap='nowrap' >

          <Typography variant='h6' width='fit-content' whiteSpace='nowrap' mt={2}>Recherche par&nbsp;: </Typography>

          <Stack direction='row' useFlexGap spacing={2} flexWrap='wrap' width='fit-content'>

            <Stack direction='row' useFlexGap spacing={2} alignItems='flex-end' >
              <ClientAutoComplete label="Client" name="client" onClientChoice={handleClientChoice} value={clientUser ? user?.client : formState.client}
                                  sx={{ width: '25ch', '.&disabled':{color: 'red'} }} variant='standard' disabled={formState.blocked || clientUser} singleClient={clientUser} />
              <ClientModelAutoComplete label="Modèle" name="model" onModelChoice={handleModelChoice} value={formState.model} client={clientUser ? user?.client : formState.client} 
                                      sx={{ width: '25ch' }} variant='standard' disabled={ formState.blocked || (!formState.client && !clientUser) }  />
            </Stack>

            <Stack direction='row' useFlexGap spacing={2} flexWrap='nowrap' alignItems='flex-end'>
              <Typography>Depuis</Typography>
              <DatePicker value={formState.startDate || null } onChange={(d) => handleDateChange(d, "startDate")} 
                          sx={{ width: 190 }}
                          slotProps={{
                            field:{clearable: true},
                            textField: formState.endDate ? { placeholder: "le début", size: 'small', variant: 'standard' } 
                                                        : { placeholder: "l'année dernière", size: 'small', variant: 'standard' },
                          }} />
              <Typography>jusqu'à</Typography>
              <DatePicker value={formState.endDate || null} onChange={(d) => handleDateChange(d, "endDate")}
                          sx={{ width: 190 }}
                          slotProps={{
                            field:{clearable: true},
                            textField: { placeholder: "aujourd'hui", size: 'small', variant: 'standard'} 
                          }} />
              <Button variant='contained' color='secondary' sx={{width:'fit-content', height:'fit-content'}} onClick={() => setDataLoaded(false)}>Filtrer</Button>
            </Stack>
          </Stack>

        </Stack>

      </Box>

      <Container maxWidth='md' sx={{mt: 1, p:0, width:'fit-content', '@media (min-width: 600px)': { padding: "0"}}}>
        {formState.loadError && <Alert severity='error'>{formState.loadError}</Alert>}
        <DataGrid
          rows={formState.conversionsData || []}
          getRowId={row => row.client.id + row.date + row.modelName}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 50, 100]} //No more than 100 in MIT version
          loading={formState.blocked}
          autoHeight={true}
          disableRowSelectionOnClick={true}
        />
      </Container>

      <FormSnackbarProvider />
    </>
  );

  //Action handlers

  function handleClientChoice(client?: ApiTypes.Client) {
    if (clientUser)
      return;
    setFormState({ ...formState, client, model: undefined});
  }

  function handleModelChoice(model?: ApiTypes.SimpleModel) {
    setFormState({ ...formState, model });
  }

  function handleDateChange(date: Date | null, fieldname: string) {
    setFormState({ ...formState, [fieldname]: date });
  }

  //private utility functions
  function startOperation(clearSnacks?: boolean) {
    if (clearSnacks) closeSnackbar();
    setFormState({ ...formState, blocked: true, loadError: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    setFormState({ ...newFormState, blocked: false });
  }

};

export default ConversionsPage;