import SaveIcon from '@mui/icons-material/Save';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Switch, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { AppAccess } from '../../../components/clientpage/AppAccess';
import { ContactFormCard, CustomizationsCard, UsersCard } from '../../../components/clientpage/ClientCards';
import { ClientInfo } from '../../../components/clientpage/ClientInfo';
import { FlexRow, FormSnackbarProvider, enqueueSnacks } from '../../../components/common/forms/FormComponents';
import * as ApiTypes from '../../../link/ApiTypes';
import { emptyClient } from '../../../link/ApiTypes';
import * as Api from '../../../link/ClientServerApi';
import { setGlobalProgress } from '../../../redux/features/progressSlice';

type Props = { };
type FormState = {
  client?: ApiTypes.Client;
  blocked: boolean;
  loadError?: string;
  errorMessage?: string;
  warnings?: string[];
};

const ClientPage = (props : Props) => {
  //URL Parameters
  let { clientId } = useParams();

  //Imperative functions
  const navigate = useNavigate();
  
  //States
  const initState: FormState = { blocked: false };
  const [ formState, setFormState ] = useState( initState );
  const [ deleteConfirmText, setDeleteConfirmText ] = React.useState<string>();
  const dispatch = useDispatch();
  
  //Load
  React.useEffect(() => {
    if (clientId === 'new') {
      setFormState({...formState, blocked: false, client: { ...emptyClient() }});
      return
    }
    startOperation();
    Api.getClient(clientId)
      .then((appResp) => {
        console.log(appResp);
        if (!appResp.ok) {
          endOperation({ loadError: "Erreur au chargement\u00a0:" + appResp.errorMessage });
          return;
        }
        if (!appResp.value) {
          endOperation({ loadError: "Erreur au chargement\u00a0: Réponse vide du serveur" });
          return;
        }
        endOperation({ client: appResp.value });
      })
      .catch(err => {
        console.error(err);
        endOperation({ ...initState, loadError: "Erreur au chargement\u00a0:" + err });
      });
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ clientId ]);
  
  
  
  //Layout
  
  return (
    <Box component="form" autoComplete="off" onSubmit={save} onKeyDown={kbHotkeys} >

      <FlexRow mt={0}>
        <Typography variant='h5'>{ formState.client?.id ? `Modifier le client ${formState.client?.name}` : "Nouveau client" }</Typography>
        <Box flexGrow={1} />
        <FormControlLabel
              control={ <Switch name="active" checked={formState.client?.active || false} onChange={handleCheck} /> }
              label="Actif" labelPlacement='start' />
      </FlexRow>

      { formState.loadError && <Alert severity='error'>{formState.loadError}</Alert> }
      { formState.errorMessage && <Alert severity='error'>{formState.errorMessage}</Alert> }
      { formState.warnings && formState.warnings.map((warnMessage, index) => <Alert severity='warning' key={`w-${index}`}>{warnMessage}</Alert>) }

      <Box className="fields">

            <ClientInfo client={formState.client} onClientChanged={client => setFormState({ ...formState, client: client })}/>

            <Divider variant='middle' sx={{mt: 4}}>Accès application 3D</Divider>

            <AppAccess client={formState.client} onClientChanged={client => setFormState({ ...formState, client: client })} />

      </Box>

      <FlexRow>
        <Button variant='contained' color='primary' onClick={confirmDelete}  hidden={!formState.client?.id}
                disabled={!formState.client?.id || formState.blocked || formState.loadError ? true : false}
                startIcon={<GridDeleteIcon />}>
          Supprimer
        </Button>
        <Box flexGrow={1} />
        <Button component={RouterLink} to="../clients" variant='outlined'>Retour</Button>
        <Button type="submit" variant='contained' color='secondary'
                disabled={formState.blocked || formState.loadError ? true : false}
                startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </FlexRow>

      <Divider sx={{my: 4}}>Paramètres avancés</Divider>

      <Container>
        <Grid2 container spacing={2} columns={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }} alignItems='stretch'>
          <Grid2 xs={2}>
            <UsersCard client={formState.client} />
          </Grid2>
          <Grid2 xs={2}>
            <CustomizationsCard client={formState.client} />
          </Grid2>
          <Grid2 xs={2}>
            <ContactFormCard client={formState.client} />
          </Grid2>
        </Grid2>
      </Container>

      <LocalDeleteConfirmDialog onConfirm={deleteCurrent} />
      
      <FormSnackbarProvider />
    </Box>
  );

  //Keyboard hotkeys
  function kbHotkeys(e: React.KeyboardEvent) {
    if (e.ctrlKey) {
      switch (e.key) {
        case 's':
          e.preventDefault();
          e.stopPropagation();
          if (!formState.blocked && !formState.loadError) {
            save(e);
          }
          return false;
        }
    }
    else {
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          e.stopPropagation();
          navigate('../clients');
          return false;
      }
    }
  }
  
  //Field change handlers

  function handleCheck(e: ChangeEvent) {
    const {name, checked} = e.target as HTMLInputElement;
    const c = { ...formState.client || emptyClient() };
    (c as any)[name] = !!checked;
    setFormState({ ...formState, client: c });
    console.log(c);
  }
  
  
  
  //Action event handlers

  function confirmDelete(event: React.MouseEvent) {
      setDeleteConfirmText(`Supprimer le client\u00a0?`);
  }

  function deleteCurrent(event: React.MouseEvent) {
    console.log("Delete", formState.client);
    if (!formState.client?.id) {
      endOperation();
      enqueueSnacks(undefined, ['Pas de client connu en base de données']);
      return;
    }

    startOperation();
    Api.deleteClient(formState.client.id)
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation();
          enqueueSnacks(undefined, appResp.warnings, appResp.errorMessage ? "Erreur à la suppression\u00a0:" + appResp.errorMessage : undefined);
          return;
        }
        if (!appResp.value && appResp.value !== false) {
          endOperation();
          enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0: Réponse vide du serveur");
          return;
        }
        if (appResp.value) {
          setGlobalProgress(0);
          enqueueSnacks('Client supprimé');
          setTimeout(() => navigate('../clients'), 1000);
        } else {
          endOperation();
          enqueueSnacks('Le client n\'a pas été supprimé');
        }
      })
      .catch(err => {
        console.error(err);
        endOperation();
        enqueueSnacks(undefined, undefined, "Erreur à la suppression\u00a0:" + err);
      });
  }
  
  function save(e: React.FormEvent) {
    e.preventDefault();

    if (formState.client?.apiKeyExpires && !formState.client.apiKeyExpires.isValid()) {
      enqueueSnacks(undefined, ['Date d\'expiration invalide']);
      return;
    }

    startOperation();
    Api.saveClient(
      {
        ...formState.client || emptyClient(),
      })
      .then((appResp) => {
        if (!appResp.ok) {
          endOperation({
            ...formState,
            errorMessage: appResp.errorMessage ? "Erreur à l'enregistrement\u00a0:" + appResp.errorMessage : undefined,
            warnings: appResp.warnings
           });
          return;
        }
        if (!appResp.value) {
          endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0: Réponse vide du serveur" });
          return;
        }
        const savedClient = appResp.value as ApiTypes.Client;
        endOperation({ client: savedClient, successMessage: 'Client enregistré' });
        enqueueSnackbar('Client enregistré', { variant: "success" });
        if (clientId === 'new') {
          navigate('../clients/' + savedClient.id);
        }
      })
      .catch(err => {
        console.error(err);
        endOperation({ ...formState, errorMessage: "Erreur à l'enregistrement\u00a0:" + err });
      });
  }


  //private utility functions
  function startOperation() {
    dispatch(setGlobalProgress(-1));
    setFormState({ ...formState, blocked: true, errorMessage: undefined, loadError: undefined, warnings: undefined });
  }

  function endOperation(newFormState?: any) {
    if (!newFormState)
      newFormState = formState;
    dispatch(setGlobalProgress(0));
    setFormState({ ...newFormState, blocked: false });
  }



  //Inner components

  function LocalDeleteConfirmDialog(props: {onConfirm?: MouseEventHandler}) : React.ReactElement | null {
    if (deleteConfirmText) {
      return (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Suppression de client"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              {deleteConfirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_e => setDeleteConfirmText(undefined)} autoFocus>Annuler</Button>
            <Button onClick={e => {
                setDeleteConfirmText(undefined);
                props.onConfirm && props.onConfirm(e);
              }}
              variant='contained' color='secondary'
              >
                OK
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }
  
  
};

export default ClientPage;