import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { Box, Button, useTheme } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { Moment } from "moment";
import { ChangeEvent } from "react";
import { Client, emptyClient } from '../../link/ApiTypes';
import { FlexRow, FormTextField } from "../common/forms/FormComponents";

type Props = {
  client?: Client,
  onClientChanged: (client: Client) => void
}

export function AppAccess({client, onClientChanged}: Props) {
  const theme = useTheme();

  return (
    <Box className="api-access">
      <FlexRow mt={0}>
        <FormTextField  name="domainName" label='Nom de domaine'
                        value={client?.domainName || ''} onChange={handleChange}
                        sx={{ flexGrow: 1, maxWidth: '40ch' }} required />
      </FlexRow>
      <FlexRow>
        <Box display='flex' alignItems='center'>
          <FormTextField  name="apiKey" label='Clé API'
                          inputProps={{ minLength: 64, maxLength: 64 }}
                          value={client?.apiKey || ''} onChange={handleChange}
                          sx={{ flexGrow: 1, width: '72ch' }} required />
          <Button variant='text'  title='Générer une clé API'
                  sx={{padding: 0, minHeight: '32px', minWidth: '32px', color: theme.palette.secondary.dark}}
                  onClick={generateApiKey}>
            <AutoFixNormalIcon fontSize='small' />
          </Button>
        </Box>
        <DatePicker label='Expiration' value={client?.apiKeyExpires || null} onChange={handleApiExpiryChange}
                    slotProps={{ textField: { name: 'apiKeyExpires', variant:'standard' }, field: { clearable: true }}} />
      </FlexRow>
    </Box>
  );



  //Field change handlers
  
  function handleChange(e: ChangeEvent) {
    if (!onClientChanged) return;
    const {name, value} = e.target as HTMLInputElement;
    const c = { ...client || emptyClient() };
    (c as any)[name] = value;
    onClientChanged(c);
  }
  
  function handleApiExpiryChange(d: Moment | null) {
    if (!onClientChanged) return;
    const c = { ...client || emptyClient() };
    if (!d)
      delete c.apiKeyExpires;
    else
      c.apiKeyExpires = d;
    onClientChanged(c);
  }
  
  
  
  //Action event handlers

  function generateApiKey(event: React.MouseEvent) {
    if (!onClientChanged) return;
    let nKey = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 64; i++) {
      let r = Math.floor(Math.random() * characters.length);
      nKey += characters.charAt(r);
    }
    const c = { ...client || emptyClient() };
    c.apiKey = nKey;
    onClientChanged(c);
  }

}