import { Avatar, Divider, Drawer, List, Stack, Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import logo from '../../../assets/images/logo-a3a.png';
import * as appInfo from '../../../configs/appInfo';
import sizeConfigs from "../../../configs/sizeConfigs";
import { LoginState } from "../../../redux/features/loginSlice";
import appRoutes from "../../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";

/**
 * The left side bar containing application menu
 * @returns 
 */
const Sidebar = () => {
  
  const { user } = useSelector((state: {login: LoginState}) => state.login);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sizeConfigs.sidebar.width,
          boxSizing: 'border-box',
          bgcolor: 'primary.dark',
          color: 'primary.contrastText'
        }
      }}
    >
      <Toolbar>
        <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={theme => theme.spacing(2)}
          >
          <Avatar variant="square" src={logo} sx={{width: '1.5rem', height: '1.5rem'}} />
          <Typography variant="subtitle1">{appInfo.shortName}</Typography>
        </Stack>
      </Toolbar>
      <Divider />
      <List disablePadding>
        {appRoutes.map((route, index) => {
          if (!route.state || !route.state.sidebarProps) {
            return null;
          }
          if (route.state.sidebarProps.roles) {
            if (!user)
              return null;
            if (!route.state.sidebarProps.roles.some(r => user.roles.includes(r)))
              return null;
          }
          return (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;